<script>
  import { onMount, onDestroy } from "svelte";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";
  import Icon from "@iconify/svelte";
  import DelLegends from "../DelLegends/DelLegends.svelte";
  import moment from "moment";

  export let nodes = [];
  export let itemsPerRowForLargeScreens = 4;
  export let itemsPerRowForSmallScreens = 3;
  export let onNodeClicked = null;
  export let modalMaxLimit = 5;
  export let modalMaxLimitForSmallScreens = 1;
  export let onModalClosed = null;
  export let legendsInfo = [];
  export let deployedURL = "";
  export let showNoStationsMessage = false;

  const formatTimeStamp = function (timestamp) {
    if (timestamp) {
      return `Last Updated Time : ${moment(
        new Date(parseInt(timestamp) * 1000)
      ).format("DD-MMM-YYYY HH:mm:ss")}`;
    }
    return "Last Updated Time : No Data";
  };

  export const updateThreadedTree = function (data) {
    for (const item of data) {
      let circle = document.querySelector(
        `.thread-circle[data-id='${item.id}']`
      );
      circle.style.background = item.healthStatus ? item.color : "grey";
      circle.title = item.hint || "";
      if (item.blink && item.healthStatus) {
        circle.classList.add("glow");
      } else {
        circle.classList.remove("glow");
      }
    }
  };

  export const updateStatusDetails = function (nodeid, details) {
    if (details) {
      let htmlCode = "";
      for (const item of details) {
        htmlCode +=
          '<div class="station-item-container">\
                            <div class="station-item station-item-name status-item">' +
          item.name +
          '</div>\
                            <div class="station-item-separator status-item">:</div>\
                            <div style="display:flex">\
                                <div class="status-circle status-item" style="background:' +
          `${item.healthStatus ? item.color : "grey"}` +
          '" title = "' +
          formatTimeStamp(item.lastUpdatedTime) +
          '"></div>\
                                <div style="padding-left:10px;text-align:left;" class="status-item">' +
          `${item.healthStatus ? item.hint : "No Communication"}` +
          "</div>\
                            </div>\
                        </div>";
      }
      const container = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-status-container'
      );
      const noDataContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-no-data-container'
      );
      const loaderContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-loader-container'
      );

      if (loaderContainer) {
        loaderContainer.style.display = "none";
      }
      if (container) {
        container.innerHTML = htmlCode;
        container.style.display = "block";
      }
      if (noDataContainer) {
        noDataContainer.style.display = "none";
      }
    }
  };

  export const setLastUpdatedTime = function (nodeid, timestamp) {
    if (nodeid && timestamp) {
      const lastUpdatedTime = moment(
        new Date(parseInt(timestamp) * 1000)
      ).format("DD-MMM-YYYY HH:mm:ss");
      const htmlCode = `<div class="del-rail-iot-modal-last-updated-time">Last Updated Time : ${lastUpdatedTime}</div>`;
      const container = document.querySelector(
        'div[data-id ="' +
          nodeid +
          '"].del-rail-iot-modal-updated-time-container'
      );
      const noDataContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-no-data-container'
      );
      const loaderContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-loader-container'
      );

      if (loaderContainer) {
        loaderContainer.style.display = "none";
      }
      if (container) {
        container.innerHTML = htmlCode;
        container.style.display = "block";
      }
      if (noDataContainer) {
        noDataContainer.style.display = "none";
      }
    }
  };

  export const updateImageReadings = function (nodeid, details) {
    if (details) {
      let htmlCode = `<div class="del-rail-iot-modal-image">\
                        <img src="${deployedURL}/images/DCVoltage.svg" alt="DC Voltage" draggable="false"></img>\
                        <div class="del-rail-iot-modal-image-label">DC Voltage</div>\
                        <div class="del-rail-iot-modal-image-label ${
                          details.Voltage &&
                          (details.Voltage.reading === "No Data" ||
                            details.Voltage.reading == null ||
                            !details.Voltage.healthStatus)
                            ? ""
                            : "del-rail-iot-modal-image-reading"
                        }" title="${formatTimeStamp(
                          details.Voltage
                            ? details.Voltage.lastUpdatedTime
                            : null
                        )}">${
                          details.Voltage.healthStatus
                            ? details.Voltage.reading
                            : "No Communication"
                        }</div>\
                        </div>\
                        <div class="del-rail-iot-modal-image">\
                        <img src="${deployedURL}/images/Temperature.svg" alt="Humidity" draggable="false"></img>\
                        <div class="del-rail-iot-modal-image-label">Temperature</div>\
                        <div class="del-rail-iot-modal-image-label ${
                          details.Temperature &&
                          (details.Temperature.reading === "No Data" ||
                            details.Temperature.reading == null ||
                            !details.Temperature.healthStatus)
                            ? ""
                            : "del-rail-iot-modal-image-reading"
                        }" title="${formatTimeStamp(
                          details.Temperature
                            ? details.Temperature.lastUpdatedTime
                            : null
                        )}">${
                          details.Temperature.healthStatus
                            ? details.Temperature.reading
                            : "No Communication"
                        }</div>\
                        </div>\
                        <div class="del-rail-iot-modal-image">\
                        <img src="${deployedURL}/images/Humidity.svg" alt="Battery" draggable="false"></img>\
                        <div class="del-rail-iot-modal-image-label">Humidity</div>\
                        <div class="del-rail-iot-modal-image-label ${
                          details.Humidity &&
                          (details.Humidity.reading === "No Data" ||
                            details.Humidity.reading == null ||
                            !details.Humidity.healthStatus)
                            ? ""
                            : "del-rail-iot-modal-image-reading"
                        }" title="${formatTimeStamp(
                          details.Humidity
                            ? details.Humidity.lastUpdatedTime
                            : null
                        )}">${
                          details.Humidity.healthStatus
                            ? details.Humidity.reading
                            : "No Communication"
                        }</div>\
                        </div>\
                        <div class="del-rail-iot-modal-image">\
                        <img src="${deployedURL}/images/Battery.svg" alt="DC Drain rate" draggable="false"></img>\
                        <div class="del-rail-iot-modal-image-label">DC Drain rate</div>\
                        <div class="del-rail-iot-modal-image-label ${
                          details.Battery &&
                          (details.Battery.reading === "No Data" ||
                            details.Battery.reading == null ||
                            !details.Battery.healthStatus)
                            ? ""
                            : "del-rail-iot-modal-image-reading"
                        }" title="${formatTimeStamp(
                          details.Battery
                            ? details.Battery.lastUpdatedTime
                            : null
                        )}">${
                          details.Battery.healthStatus
                            ? details.Battery.reading
                            : "No Communication"
                        }</div>\
                        </div>`;

      const container = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-image-container'
      );
      const noDataContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-no-data-container'
      );
      const loaderContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-loader-container'
      );

      if (loaderContainer) {
        loaderContainer.style.display = "none";
      }
      if (container) {
        container.innerHTML = htmlCode;
        container.style.display = "flex";
      }
      if (noDataContainer) {
        noDataContainer.style.display = "none";
      }
    }
  };

  export const setNoDataMessage = function (nodeid, message) {
    if (nodeid) {
      const imageContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-image-container'
      );
      const statusContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-status-container'
      );
      const timeContainer = document.querySelector(
        'div[data-id ="' +
          nodeid +
          '"].del-rail-iot-modal-updated-time-container'
      );
      const noDataContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-no-data-container'
      );
      const loaderContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-loader-container'
      );

      if (loaderContainer) {
        loaderContainer.style.display = "none";
      }
      if (imageContainer) {
        imageContainer.style.display = "none";
      }
      if (statusContainer) {
        statusContainer.style.display = "none";
      }
      if (timeContainer) {
        timeContainer.style.display = "none";
      }
      if (noDataContainer && message) {
        noDataContainer.style.display = "block";
        noDataContainer.innerHTML = `<div class="del-rail-iot-modal-no-data-message">${message}</div>`;
      }
    }
  };

  export const setLoader = function (nodeid) {
    if (nodeid) {
      const imageContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-image-container'
      );
      const statusContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-status-container'
      );
      const timeContainer = document.querySelector(
        'div[data-id ="' +
          nodeid +
          '"].del-rail-iot-modal-updated-time-container'
      );
      const noDataContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-no-data-container'
      );
      const loaderContainer = document.querySelector(
        'div[data-id ="' + nodeid + '"].del-rail-iot-modal-loader-container'
      );

      if (loaderContainer) {
        loaderContainer.style.display = "flex";
      }
      if (imageContainer) {
        imageContainer.style.display = "none";
      }
      if (statusContainer) {
        statusContainer.style.display = "none";
      }
      if (timeContainer) {
        timeContainer.style.display = "none";
      }
      if (noDataContainer) {
        noDataContainer.style.display = "none";
      }
    }
  };

  let modalCount = 0;
  let activeModal = null;
  let itemsPerRow = 4;
  let threadedTreeRows = [];

  $: updateItemsPerRow(itemsPerRowForLargeScreens);
  $: updateItemsPerRow(itemsPerRowForSmallScreens);
  $: updatedThreadedTreeRows(nodes);

  onMount(() => {
    window.addEventListener("resize", onWindowResize);
  });

  onDestroy(() => {
    window.removeEventListener("resize", onWindowResize);
  });

  const splitNodesIntoRows = function (nodes, nodesPerRow) {
    const sublists = [];
    for (let i = 0; i < nodes.length; i += nodesPerRow) {
      sublists.push(nodes.slice(i, i + nodesPerRow));
    }
    return sublists;
  };

  const closeAllModals = function () {
    const closeBtns = document.querySelectorAll(".del-rail-iot-close-btn");
    closeBtns.forEach((btn) => {
      const id = btn.getAttribute("data-id");
      onModalCloseButtonClick(btn, id, true);
    });
    modalCount = 0;
  };

  const updatedThreadedTreeRows = function () {
    closeAllModals();
    threadedTreeRows = splitNodesIntoRows(nodes, itemsPerRow);
  };

  const updateItemsPerRow = function () {
    const currentWidth = window.innerWidth;
    const _itemsPerRow = itemsPerRow;
    if (currentWidth < 500) {
      itemsPerRow = itemsPerRowForSmallScreens;
    } else if (currentWidth > 500) {
      itemsPerRow = itemsPerRowForLargeScreens;
    }
    if (_itemsPerRow !== itemsPerRow) {
      threadedTreeRows = splitNodesIntoRows(nodes, itemsPerRow);
    }
  };

  const onWindowResize = function () {
    updateItemsPerRow();
    const modals = document.querySelectorAll(".del-rail-iot-modal");
    if (modals) {
      // Loop through all modals and update their positions within the new bounds
      modals.forEach((modal) => {
        const maxLeft =
          window.innerWidth -
          modal.offsetWidth -
          (window.innerWidth - document.documentElement.clientWidth);
        const maxTop = window.innerHeight - modal.offsetHeight + window.scrollY;

        const currentLeft = parseInt(modal.style.left, 10);
        const currentTop = parseInt(modal.style.top, 10);

        // Check if modal is beyond the new bounds and update its position
        if (currentLeft > maxLeft) {
          modal.style.left = maxLeft + "px";
        }
        if (currentTop > maxTop) {
          modal.style.top = maxTop + "px";
        }
      });
    }
  };

  const showToast = function (message) {
    Toastify({
      text: message,
      backgroundColor: "orange",
      duration: 3000,
      close: true,
    }).showToast();
  };

  const updateModalZIndex = function (modal) {
    const maxZIndex = Array.from(
      document.querySelectorAll(".del-rail-iot-modal")
    )
      .map((m) => parseFloat(m.style.zIndex))
      .filter((z) => !isNaN(z))
      .sort((a, b) => a - b)
      .pop();

    if (!isNaN(maxZIndex)) {
      modal.style.zIndex = maxZIndex + 1;
    } else {
      modal.style.zIndex = 100;
    }
  };

  const onCircleClicked = function (element, node) {
    const modal = document.querySelector(element.dataset.modal);
    const currentWidth = window.innerWidth;

    if (modal.style.display === "block") {
      // if modal is already open
      modal.classList.add("active");
      activeModal = modal;
      updateModalZIndex(modal);
    } else {
      // trying to open a new modal
      if (currentWidth > 900) {
        // in large screens
        if (modalCount < modalMaxLimit) {
          modal.style.display = "block";
          modal.classList.add("active");
          activeModal = modal;
          modalCount++;
          updateModalZIndex(modal);
        } else {
          showToast(
            `Only a maximum of ${modalMaxLimit} pop-up${
              modalMaxLimit > 1 ? "s" : ""
            } can be opened at a time`
          );
        }
      } else {
        // in small screens like tablet and phones
        if (modalCount < modalMaxLimitForSmallScreens) {
          modal.style.display = "block";
          modal.classList.add("active");
          activeModal = modal;
          modalCount++;
          updateModalZIndex(modal);
        } else {
          showToast(
            `Only a maximum of ${modalMaxLimitForSmallScreens} pop-up${
              modalMaxLimitForSmallScreens > 1 ? "s" : ""
            } can be opened at a time`
          );
        }
      }
    }
    onNodeClicked && onNodeClicked(node);
  };

  const onModalMouseDown = function (e) {
    const modal = e.currentTarget;
    if (!e.target.matches(".modal-resize-handle")) {
      activeModal = modal;
      updateModalZIndex(modal);

      const initialX = e.clientX - modal.offsetLeft;
      const initialY = e.clientY - modal.offsetTop;

      document.addEventListener("mousemove", moveModal);
      document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", moveModal);
      });

      function moveModal(e) {
        const left = e.clientX - initialX;
        const top = e.clientY - initialY;

        // Limit the modal's position to the bounds of the screen
        const maxLeft =
          window.innerWidth -
          modal.offsetWidth -
          (window.innerWidth - document.documentElement.clientWidth);
        const maxTop = window.innerHeight - modal.offsetHeight;

        modal.style.left = Math.min(Math.max(0, left), maxLeft) + "px";
        modal.style.top = Math.min(Math.max(0, top), maxTop) + "px";
      }
    }
  };

  const onModalTouchStart = function (e) {
    const modal = e.currentTarget;
    if (!e.target.matches(".modal-resize-handle")) {
      activeModal = modal;
      updateModalZIndex(modal);

      const initialX = e.touches[0].clientX - modal.offsetLeft;
      const initialY = e.touches[0].clientY - modal.offsetTop;

      document.addEventListener("touchmove", moveModal, {
        passive: false,
      });
      document.addEventListener(
        "touchend",
        () => {
          document.removeEventListener("touchmove", moveModal);
        },
        {
          passive: false,
        }
      );

      function moveModal(e) {
        e.preventDefault();

        const left = e.touches[0].clientX - initialX;
        const top = e.touches[0].clientY - initialY;

        // Limit the modal's position to the bounds of the screen
        const maxLeft =
          window.innerWidth -
          modal.offsetWidth -
          (window.innerWidth - document.documentElement.clientWidth);
        // const maxTop = window.innerHeight - modal.offsetHeight + window.scrollY;
        const maxTop = window.innerHeight - modal.offsetHeight;

        modal.style.left = Math.min(Math.max(0, left), maxLeft) + "px";
        modal.style.top = Math.min(Math.max(0, top), maxTop) + "px";
      }
    }
  };

  const onModalCloseButtonClick = function (btn, id, closeAll = false) {
    const modal = btn.closest(".del-rail-iot-modal");
    const modalContent = modal.querySelector(
      `.del-rail-iot-modal-content[data-id='${id}']`
    );
    const minimizeBtn = modal.querySelector(
      `.del-rail-iot-minimize-btn[data-id='${id}']`
    );
    const maximizeBtn = modal.querySelector(
      `.del-rail-iot-maximize-btn[data-id='${id}']`
    );
    modal.classList.remove("del-rail-iot-modal-minimize");
    modal.style.display = "none";
    modal.classList.remove("active");
    modal.style.removeProperty("top");
    modal.style.removeProperty("left");
    modalContent.style.display = "block";
    minimizeBtn.disabled = false;
    maximizeBtn.disabled = true;
    !closeAll && modalCount--;
    onModalClosed && onModalClosed(id);
  };

  const onModalMaximizeButtonClick = function (btn, id) {
    const modal = btn.closest(".del-rail-iot-modal");
    const modalContent = modal.querySelector(
      `.del-rail-iot-modal-content[data-id='${id}']`
    );
    const minimizeBtn = modal.querySelector(
      `.del-rail-iot-minimize-btn[data-id='${id}']`
    );
    modal.classList.remove("del-rail-iot-modal-minimize");
    modalContent.style.display = "block";
    btn.disabled = true;
    minimizeBtn.disabled = false;

    // Check if the modal would be off-screen when maximized
    const maxLeft = window.innerWidth - modal.offsetWidth;
    const maxTop = window.innerHeight - modal.offsetHeight;
    const currentLeft = modal.offsetLeft;
    const currentTop = modal.offsetTop;

    if (currentLeft > maxLeft) {
      modal.style.left = maxLeft + "px";
    }

    if (currentTop > maxTop) {
      modal.style.top = maxTop + "px";
    }
  };

  const onModalMinimizeButtonClick = function (btn, id) {
    const modal = btn.closest(".del-rail-iot-modal");
    const modalContent = modal.querySelector(
      `.del-rail-iot-modal-content[data-id='${id}']`
    );
    const maximizeBtn = modal.querySelector(
      `.del-rail-iot-maximize-btn[data-id='${id}']`
    );
    modal.classList.add("del-rail-iot-modal-minimize");
    modalContent.style.display = "none";
    btn.disabled = true;
    maximizeBtn.disabled = false;
  };
</script>

<svelte:head>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-threaded-tree-container">
  <div id="thread-view">
    {#if showNoStationsMessage}
      <div class="del-no-data-message">No Assigned Stations</div>
    {/if}
    {#each threadedTreeRows as row}
      <div class="thread-row">
        {#each row as node}
          <div class="thread-node">
            <div class="thread-node-content" data-modal={`#modal-${node.id}`}>
              <div class="thread-title">{node.name ?? ""}</div>
              <div
                class={`thread-circle ${
                  node.healthStatus && node.blink ? "glow" : ""
                }`}
                data-modal={`#modal-${node.id}`}
                data-id={node.id}
                style={`background:${node.healthStatus ? node.color : "grey"}`}
                title={node.hint || ""}
                on:click={(e) => onCircleClicked(e.currentTarget, node)}
                on:keypress={(e) => onCircleClicked(e.currentTarget, node)}
              ></div>
            </div>
          </div>
          <!-- Corresponding Modal -->
          <div
            class="del-rail-iot-modal"
            id={`modal-${node.id}`}
            on:mousedown={onModalMouseDown}
            on:touchstart={onModalTouchStart}
          >
            <div class="del-rail-iot-modal-loader-container" data-id={node.id}>
              <div class="del-rail-iot-modal-spinner"></div>
            </div>

            <div
              class="del-rail-iot-modal-inner-container disable-text-selection"
            >
              <div class="del-rail-iot-modal-header">
                <div class="del-rail-iot-modal-header-title">
                  {node.name}
                </div>
                <div class="del-rail-iot-modal-buttons-container">
                  <button
                    class="del-rail-iot-minimize-btn"
                    data-id={node.id}
                    on:click={(e) =>
                      onModalMinimizeButtonClick(e.currentTarget, node.id)}
                  >
                    <Icon icon="solar:minimize-square-bold" />
                  </button>
                  <button
                    class="del-rail-iot-maximize-btn"
                    data-id={node.id}
                    on:click={(e) =>
                      onModalMaximizeButtonClick(e.currentTarget, node.id)}
                    disabled
                  >
                    <Icon icon="solar:maximize-square-bold" />
                  </button>
                  <button
                    class="del-rail-iot-close-btn"
                    data-id={node.id}
                    on:click={(e) =>
                      onModalCloseButtonClick(e.currentTarget, node.id)}
                  >
                    <Icon icon="mi:close" />
                  </button>
                </div>
              </div>
              <div class="del-rail-iot-modal-content" data-id={node.id}>
                <div
                  class="del-rail-iot-modal-no-data-container"
                  data-id={node.id}
                >
                  <div></div>
                </div>
                <div
                  class="del-rail-iot-modal-status-container"
                  data-id={node.id}
                ></div>
                <div
                  class="del-rail-iot-modal-image-container"
                  data-id={node.id}
                ></div>
                <div
                  class="del-rail-iot-modal-updated-time-container"
                  data-id={node.id}
                ></div>
              </div>
            </div>
          </div>
        {/each}
      </div>
    {/each}
  </div>
  <DelLegends legends={legendsInfo}></DelLegends>
</div>

<style>
  div#thread-view {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }

  .thread-node {
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    z-index: 7;
  }

  .thread-circle {
    width: 20px;
    height: 20px;
    background: grey;
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
    border: 2px solid #333;
    position: relative;
    cursor: pointer;
    transition: all 0.3s;
  }

  .thread-node-content:hover .thread-circle {
    transform: scale(1.05);
  }

  .thread-circle:hover:active {
    transform: scale(0.98);
  }

  .thread-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
  }

  .thread-row:nth-child(even) {
    flex-direction: row-reverse;
  }

  .thread-node-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;
  }

  .thread-row:before {
    content: "";
    position: absolute;
    left: 0;
    width: 94%;
    height: 2px;
    background: #333;
    top: 57%;
    transform: translate(-50%);
    margin-left: 50%;
  }

  .thread-row:after {
    content: "";
    position: absolute;
    right: 2.99%;
    width: 2px;
    background: #333;
    height: 100%;
    top: 57%;
  }

  .thread-row:nth-child(even):after {
    left: 2.99%;
    right: auto;
  }

  .thread-row:nth-last-child(1):nth-child(odd)
    .thread-node:last-child
    .thread-circle:before {
    left: 112%;
    right: auto;
  }

  .thread-row:last-child::after {
    display: none;
  }

  .thread-title {
    font-size: 14px;
    margin: 0 3px;
  }

  .del-no-data-message {
    width: 100%;
    text-align: center;
    padding: 4% 0;
    font: italic normal normal 14px Roboto;
    color: #61656c;
  }

  .blink {
    animation: blink 1s infinite;
  }

  .glow {
    animation: glow 1s infinite;
    border-color: red;
  }
  @keyframes blink {
    50% {
      background-color: white;
    }
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px red;
    }
    50% {
      box-shadow: 0 0 20px red;
    }
    100% {
      box-shadow: 0 0 10px red;
    }
  }

  @media screen and (max-width: 400px) {
    .thread-title {
      font-size: 12px;
    }
  }

  /* For Modal */

  .del-rail-iot-modal {
    position: fixed;
    display: none;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
    max-width: calc(100% - 40px);
    min-width: 100px;
    max-height: 90vh;
    resize: both;
    box-sizing: border-box;
    font-family: sans-serif;
    min-height: 385px;
    top: 25%;
    border-radius: 11px;
    opacity: 1;
    cursor: grab;
  }

  .del-rail-iot-modal.active {
    z-index: 200;
  }

  .del-rail-iot-modal-inner-container {
    padding: 10px;
  }

  .del-rail-iot-modal-header {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: baseline;
    text-align: left;
    font: normal normal normal 20px/26px Roboto;
    color: #000000;
    margin-bottom: 10px;
  }

  .del-rail-iot-modal-buttons-container {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .del-rail-iot-close-btn,
  .del-rail-iot-minimize-btn,
  .del-rail-iot-maximize-btn {
    cursor: pointer;
    border: none;
    padding: 0 5px;
    vertical-align: middle;
    font-weight: bold;
    color: blue !important;
    background: transparent;
    align-self: center;
    opacity: 0.7;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .del-rail-iot-close-btn:enabled:hover,
  .del-rail-iot-minimize-btn:enabled:hover,
  .del-rail-iot-maximize-btn:enabled:hover {
    opacity: 1;
  }

  .del-rail-iot-minimize-btn:disabled,
  .del-rail-iot-maximize-btn:disabled {
    cursor: not-allowed !important;
    color: grey !important;
  }

  .del-rail-iot-close-btn {
    color: red !important;
  }

  .del-rail-iot-modal-content {
    overflow: auto;
  }

  .del-rail-iot-modal-header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .del-rail-iot-modal-minimize {
    width: max-content;
    height: max-content;
    width: 210px;
  }

  .del-rail-iot-modal-loader-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-radius: 11px;
    position: absolute;
  }

  .del-rail-iot-modal-image-container,
  .del-rail-iot-modal-no-data-container,
  .del-rail-iot-modal-status-container,
  .del-rail-iot-modal-updated-time-container,
  .del-rail-iot-modal-no-data-container {
    margin: 10px auto;
  }

  .del-rail-iot-modal-no-data-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    align-content: center;
  }

  .del-rail-iot-modal-updated-time-container {
    font: normal normal normal 12px Roboto;
    color: #000000;
    margin-top: 15px;
  }

  .del-rail-iot-modal-last-updated-time {
    text-align: center;
  }

  .del-rail-iot-modal-image-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .station-item-container {
    display: grid;
    grid-template-columns: 40% 10% 50%;
    padding: 5px;
    text-align: center;
    font: normal normal normal 12px Roboto;
    color: #000000;
  }

  .status-item {
    align-self: center;
  }

  .station-item-separator {
    text-align: center;
  }

  .status-circle {
    width: 3vmin;
    height: 3vmin;
    background: grey;
    border-radius: 50%;
    min-width: 15px;
    min-height: 15px;
    border: 2px solid #333;
    position: relative;
    transition: all 0.3s;
  }

  .station-item-name {
    text-align: right;
    font-weight: bold;
  }

  .del-rail-iot-modal-image-reading,
  .del-rail-iot-modal-image-label {
    font: normal normal bold 14px/19px Roboto;
    color: #73878d;
    opacity: 1;
  }

  .del-rail-iot-modal-image-label {
    margin: 5px auto;
  }

  .del-rail-iot-modal-image-reading {
    color: #f27a26;
  }

  .del-rail-iot-modal-image {
    text-align: center;
    min-width: 20%;
  }

  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .del-rail-iot-modal-spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top-color: #61656c;
    animation: spin 1s ease-in-out infinite;
    margin: 0 auto;
    opacity: 1 !important;
  }

  .del-rail-iot-modal-no-data-message {
    margin: 25% auto;
    font-size: 16px;
    font-style: italic;
    text-align: center;
  }

  .disable-text-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media screen and (min-width: 700px) {
    .del-rail-iot-modal {
      width: 550px;
      left: calc(50% - 275px);
      top: 10%;
    }
    .del-rail-iot-modal-minimize {
      width: max-content;
      height: max-content;
      min-height: 0;
      width: 210px;
    }
  }

  @media screen and (min-width: 900px) {
    .del-rail-iot-modal {
      width: 600px;
      left: calc(50% - 300px);
      top: 10%;
    }
    .del-rail-iot-modal-minimize {
      width: max-content;
      height: max-content;
      min-height: 0;
      width: 210px;
    }
  }

  @media screen and (max-width: 699px) {
    .del-rail-iot-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 10%;
    }
    .del-rail-iot-modal-minimize {
      width: max-content;
      height: max-content;
      min-height: 0;
      width: 210px;
    }
  }

  @media screen and (max-width: 500px) {
    .del-rail-iot-modal-image-reading,
    .del-rail-iot-modal-image-label {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 380px) {
    .station-item-container {
      font-size: 10px;
    }
    .del-rail-iot-modal-image-reading,
    .del-rail-iot-modal-image-label {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 300px) {
    .del-rail-iot-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
    .del-rail-iot-modal-minimize {
      width: max-content;
      height: max-content;
      min-height: 0;
      width: 210px;
    }
    .del-rail-iot-modal-no-data-message {
      margin: 25% auto;
    }
  }

  /* End of styles for Modal */
</style>
