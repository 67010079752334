<script>
  import DelThreadedTree from "../components/DelThreadedTree/DelThreadedTree.svelte";
  import DelTrendChart from "./../components/DelTrendChart/DelTrendChart.svelte";
  import DelMultiAxisChart from "./../components/DelMultiAxisChart/DelMultiAxisChart.svelte";
  import Loader from "../components/Loader/Loader.svelte";
  import {
    selectedNode,
    parentStationNodeType,
    childNodeType,
    startTimeOffSet,
    endTimeOffSet,
  } from "../stores";
  import {
    legendsInfo,
    uniqueId,
    trendChartMobileScreensConfig,
    trendChartConfig,
  } from "./../../config";
  import moment from "moment";
  import { invokeAPI, getDeployedURL, getDomainURL } from "./../invokeAPI";
  import * as signalR from "@microsoft/signalr";
  import { onDestroy } from "svelte";

  let stationNodeList = [];
  let wsConnectedObj = null;
  let wsConnectionObjForPopUp = null;
  let nodesData = [];
  let webSocketPayload = [];
  let offlineParametersObj = {};
  let nodesMap = {};
  let lastUpdatedTime = "";
  let noNodeSelected = true;
  let healthStatusWS = null;
  let offlineParametersList = [];
  let isHeartBeatAPIAwaiting = false;
  let isStatusAPIAwaiting = false;
  let showNoStationsMessage = false;

  let threadedTreeInstance = null;

  const accessOrigin = "Dashboard/R";

  const realTimeDataEndPoint = "realtimedata/api/Parameters/all";
  const realTimeDataWSEndpoint = "realtimedata/api/parameters/all/live";
  const applicationId = sessionStorage.getItem("appId");
  const companyId = localStorage.getItem("companyId");

  $: {
    if ($selectedNode?.type && $parentStationNodeType && $childNodeType)
      onNodeChange();
  }

  onDestroy(() => {
    //WS Disconnection Call
    if (wsConnectedObj) {
      wsConnectedObj.stop();
    }
    if (wsConnectionObjForPopUp) {
      wsConnectionObjForPopUp.stop();
    }
    if (healthStatusWS) {
      healthStatusWS.stop();
    }
  });

  const getNodeChildrenBasedOnLevel = function (hierarchy, level) {
    for (const node of hierarchy) {
      if (node.type === level) {
        stationNodeList = stationNodeList.concat(
          node.children.map((item) => ({ ...item }))
        );
      } else if (node.children.length) {
        getNodeChildrenBasedOnLevel(node.children, level);
      }
    }
  };

  const formatTimeStamp = function (timestamp) {
    if (timestamp) {
      return `Last Updated Time : ${moment(
        new Date(parseInt(timestamp) * 1000)
      ).format("DD-MMM-YYYY HH:mm:ss")}`;
    }
    return "Last Updated Time : No Data";
  };

  //Function to get hex value of color from it's decimal equivalent
  const decimalToHex = function (value) {
    let bbggrr = ("000000" + value.toString(16)).slice(-6);
    let rrggbb =
      bbggrr.substring(4, 6) + bbggrr.substring(2, 4) + bbggrr.substring(0, 2);
    return "#" + rrggbb;
  };

  const findColorOfNode = function (reading) {
    let status = {
      blink: false,
    };
    if (isNaN(reading)) {
      status.color = "grey";
    } else {
      if (reading === 0) {
        status.color = "green";
      } else if (reading === 1) {
        status.color = "orange";
      } else if (reading === 2) {
        status.color = "red";
        status.blink = true;
      } else {
        status.color = decimalToHex(reading);
      }
    }
    return status;
  };

  const findColor = function (item) {
    let color = "red";
    switch (item.name) {
      case "ACstatus":
        color = item.value === "1" || item.value === 1 ? "red" : "green";
        break;
      case "ChargerSMR1status":
      case "ChargerSMR2status":
      case "ChargerDoorStatus":
      case "DC_status":
        color = item.value === "1" || item.value === 1 ? "green" : "red";
        break;
      default:
        color = "grey";
        break;
    }
    if (isNaN(item.value)) {
      color = "grey";
    }
    return color;
  };

  const formatName = function (item) {
    switch (item.name) {
      case "ACstatus":
        item.displayName = "AC Status";
        item.hint =
          item.value === "1" || item.value === 1
            ? "AC Supply Not Available"
            : "AC Supply Available";
        break;
      case "ChargerSMR1status":
        item.displayName = "Charger SMR1 Status";
        item.hint =
          item.value === "1" || item.value === 1
            ? "Charger SMR1 healthy"
            : "Charger SMR1 unhealthy";
        break;
      case "ChargerSMR2status":
        item.displayName = "Charger SMR2 Status";
        item.hint =
          item.value === "1" || item.value === 1
            ? "Charger SMR2 healthy"
            : "Charger SMR2 unhealthy";
        break;
      case "ChargerDoorStatus":
        item.displayName = "Door Status";
        item.hint =
          item.value === "0" || item.value === 0 ? "Door Open" : "Door Close";
        break;
      case "DC_status":
        item.displayName = "DC Voltage";
        item.hint =
          item.value === "1" || item.value === 1
            ? "DC Voltage within prescribed limits"
            : "DC Voltage not within prescribed limits";
        break;
      default:
        item.displayName = item.name;
        item.hint = "No Data";
        break;
    }
    if (isNaN(item.value)) {
      item.hint = "No Data";
    }
    return item;
  };

  const validateWebSocketData = function (data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed || data.InvokeAPI) {
      return false;
    } else if (data.InvokeSubscription) {
      return false;
    } else {
      return true;
    }
  };

  const getHealthStatus = function () {
    const endpoint = "device/api/device/heartbeat";
    isHeartBeatAPIAwaiting = true;
    invokeAPI(
      "GET",
      endpoint,
      getHealthStatusonSuccess,
      getHealthStatusonError,
      null,
      accessOrigin
    );
  };

  const getHealthStatusonError = function () {
    isHeartBeatAPIAwaiting = false;
  };

  const getHealthStatusWebSocketUpdates = function () {
    const endpoint = "realtimedata/api/Device/HeartBeat/live";
    const payload = {
      uniqueId: uniqueId + "_HealthStatus",
    };

    if (healthStatusWS) {
      healthStatusWS.stop();
    }

    invokeAPI(
      "POST",
      endpoint,
      getHealthStatusWebSocketUpdatesonSuccess,
      null,
      payload,
      accessOrigin
    );
  };

  const splitIntoNParts = function (list, size = 4, sizeLimit = 10) {
    const chunkSize = Math.floor(list.length / size);
    let parts = [];
    const listLength = list.length;
    if (listLength <= sizeLimit || listLength < size) {
      return [list];
    }
    for (let i = 0; i < size; i++) {
      const start = i * chunkSize;
      const end = start + chunkSize;
      const remaining = listLength - i * chunkSize;

      const actualEnd = i === size - 1 && remaining ? listLength : end;

      const chunck = list.slice(start, actualEnd);
      parts.push(chunck);
      if (actualEnd >= listLength) break;
    }
    return parts;
  };

  const getRealTimeData = function (payload) {
    if (payload?.length) {
      isStatusAPIAwaiting = true;
      const payloadList = splitIntoNParts(payload);

      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        companyid: companyId,
        applicationid: applicationId,
        "access-origin": accessOrigin,
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };

      Promise.all(
        payloadList.map((item) => {
          return fetch(`${getDomainURL()}/${realTimeDataEndPoint}`, {
            method: "POST",
            headers,
            body: JSON.stringify(item),
          }).then((response) => {
            return response;
          });
        })
      )
        .then((responses) =>
          Promise.all(
            responses.map((response) => {
              if (response.ok) {
                return response.json();
              } else {
                console.warn("API call failed", response);
                return [];
              }
            })
          )
        )
        .then((dataList) => {
          const combinedData = dataList.flat();
          getRealTimeDataonSuccess(combinedData);
        })
        .catch((error) => {
          getRealTimeDataonError();
        });
    }
  };

  const getRealTimeDataonError = function () {
    isStatusAPIAwaiting = false;
  };

  const getRealTimeDataonSuccess = function (data, timestamp) {
    isStatusAPIAwaiting = false;
    // Based on the reading received, set the color of the node
    nodesData.forEach((node) => {
      if (node.statusParameter) {
        const status = data?.find(
          (e) => e.parameterId === node.statusParameter.DerivedParameterId
        );
        if (status) {
          const reading = parseFloat(status.reading);
          const nodeColor = findColorOfNode(reading);
          node.color = nodeColor.color;
          node.blink = nodeColor.blink;
          node.hint = status.timestamp
            ? formatTimeStamp(status.timestamp)
            : formatTimeStamp(timestamp);
        }
      }
    });
    threadedTreeInstance?.updateThreadedTree(nodesData);
  };

  const customWebSocketConnection = function (webSocketMethod, webSocketUrl) {
    if (!webSocketMethod || !webSocketUrl) return false;
    wsConnectedObj = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    wsConnectedObj.on("DerivedParameterSync", function (_data) {
      //for derived parameters update
      let data = JSON.parse(_data);

      derivedParameterWSUpdater(data);
    });

    wsConnectedObj
      .start()
      .then(function () {
        wsConnectedObj
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              uniqueId +
              "_DerivedParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    wsConnectedObj.onreconnected(function () {
      wsConnectedObj
        .invoke(
          "JoinGroup",
          companyId + "_" + applicationId + "_" + uniqueId + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  };

  const getWSConnectionLinkonSuccess = function (data) {
    if (!data || data.length <= 0) return false;
    //Invoke the WS connection
    customWebSocketConnection(data[0].webSocketMethod, data[0].webSocketUrl);
  };

  const getWSConnectionLink = function (payload) {
    if (payload?.length) {
      invokeAPI(
        "POST",
        realTimeDataWSEndpoint,
        getWSConnectionLinkonSuccess,
        null,
        payload,
        accessOrigin
      );
    }
  };

  const getRealTimeReadingsForPopUp = function (payload, id) {
    if (payload?.length) {
      invokeAPI(
        "POST",
        realTimeDataEndPoint,
        (data) => {
          getRealTimeReadingsForPopUponSuccess(data, null, id);
        },
        getRealTimeReadingsForPopUponError,
        payload,
        accessOrigin
      );
    } else if (id && nodesMap[id]) {
      let _nodesMap = { ...nodesMap };
      _nodesMap[id].isAPIAwaiting = false;
      nodesMap = { ..._nodesMap };
    }
  };

  const getRealTimeReadingsForPopUponSuccess = function (data, timestamp, id) {
    let isNodeStatusFound = false;
    let isNodeImageFound = false;
    let _nodesMap = { ...nodesMap };
    const updateSingleNodePopUp = function (nodeId) {
      let { dcStatus, dpDetails, popUpNodeData, imageDetails } =
        _nodesMap[nodeId];

      lastUpdatedTime = null;

      if (timestamp && typeof timestamp === "string") {
        lastUpdatedTime = timestamp;
      }

      if (!imageDetails) {
        imageDetails = {
          Voltage: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Temperature: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Humidity: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Battery: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
        };
      }

      if (data.length && data[0].timestamp) {
        const timestamps = data.map((item) =>
          item.timestamp || item.timestamp === 0 ? parseInt(item.timestamp) : 0
        );
        lastUpdatedTime = Math.max(...timestamps);
      }

      if (popUpNodeData.length) {
        popUpNodeData = popUpNodeData.map((node) => {
          const match = data.find(
            (item) => item.parameterId === node.parameterId
          );
          if (match) {
            isNodeStatusFound = true;
            node.value = match.reading;
            node.lastUpdatedTime = match.timestamp || lastUpdatedTime;
          }
          return node;
        });

        data.map((item) => {
          let property = Object.keys(dpDetails).find(
            (key) => dpDetails[key] === item.parameterId
          );
          if (property) {
            isNodeImageFound = true;
          }
          let propertyValue = item.reading;
          switch (property) {
            case "DCVoltage":
              if (isNaN(item.reading)) {
                propertyValue = "No Data";
              } else {
                propertyValue = parseFloat(item.reading).toFixed(2).toString();
                propertyValue += " V";
              }
              if (imageDetails.Voltage) {
                imageDetails.Voltage.reading = propertyValue;
                imageDetails.Voltage.lastUpdatedTime =
                  item.timestamp || "No Data";
                imageDetails.Voltage.healthStatus = findParameterHealthStatus(
                  item.parameterId
                );
                imageDetails.Voltage.parameterId = item.parameterId;
              } else {
                imageDetails.Voltage = {
                  reading: propertyValue,
                  lastUpdatedTime: item.timestamp || "No Data",
                  healthStatus: findParameterHealthStatus(item.parameterId),
                  parameterId: item.parameterId,
                };
              }
              break;
            case "Temp":
              if (isNaN(item.reading)) {
                propertyValue = "No Data";
              } else {
                propertyValue = parseFloat(item.reading).toFixed(2).toString();
                propertyValue += " °C";
              }
              if (imageDetails.Temperature) {
                imageDetails.Temperature.reading = propertyValue;
                imageDetails.Temperature.lastUpdatedTime =
                  item.timestamp || "No Data";
                imageDetails.Temperature.healthStatus =
                  findParameterHealthStatus(item.parameterId);
                imageDetails.Temperature.parameterId = item.parameterId;
              } else {
                imageDetails.Temperature = {
                  reading: propertyValue,
                  lastUpdatedTime: item.timestamp || "No Data",
                  healthStatus: findParameterHealthStatus(item.parameterId),
                  parameterId: item.parameterId,
                };
              }
              break;
            case "RH":
              if (isNaN(item.reading)) {
                propertyValue = "No Data";
              } else {
                propertyValue = parseFloat(item.reading).toFixed(2).toString();
                propertyValue += " %";
              }
              if (imageDetails.Humidity) {
                imageDetails.Humidity.reading = propertyValue;
                imageDetails.Humidity.lastUpdatedTime =
                  item.timestamp || "No Data";
                imageDetails.Humidity.healthStatus = findParameterHealthStatus(
                  item.parameterId
                );
                imageDetails.Humidity.parameterId = item.parameterId;
              } else {
                imageDetails.Humidity = {
                  reading: propertyValue,
                  lastUpdatedTime: item.timestamp || "No Data",
                  healthStatus: findParameterHealthStatus(item.parameterId),
                  parameterId: item.parameterId,
                };
              }
              break;
            case "Battery_DrainRate": //Battery
              if (isNaN(item.reading)) {
                propertyValue = "No Data";
              } else {
                propertyValue = parseFloat(item.reading).toFixed(2).toString();
                propertyValue += " V/M";
              }
              if (imageDetails.Battery) {
                imageDetails.Battery.reading = propertyValue;
                imageDetails.Battery.lastUpdatedTime =
                  item.timestamp || "No Data";
                imageDetails.Battery.healthStatus = findParameterHealthStatus(
                  item.parameterId
                );
                imageDetails.Battery.parameterId = item.parameterId;
              } else {
                imageDetails.Battery = {
                  reading: propertyValue,
                  lastUpdatedTime: item.timestamp || "No Data",
                  healthStatus: findParameterHealthStatus(item.parameterId),
                  parameterId: item.parameterId,
                };
              }
              break;
            case "Error":
              break;
            default:
              break;
          }
        });
      } else {
        if (dpDetails) {
          popUpNodeData = data
            .map((item) => {
              let property = Object.keys(dpDetails).find(
                (key) => dpDetails[key] === item.parameterId
              );
              if (
                !property &&
                item.parameterId === dcStatus.DerivedParameterId
              ) {
                property = dcStatus.ParameterCategory;
              }
              if (property) {
                isNodeImageFound = true;
                isNodeStatusFound = true;
              }
              let propertyName = property;
              let propertyValue = item.reading;
              let order = 0;

              propertyValue += "";
              switch (property) {
                case "DCVoltage": //"Voltage":
                  if (isNaN(item.reading)) {
                    propertyValue = "No Data";
                  } else {
                    propertyValue = parseFloat(item.reading)
                      .toFixed(2)
                      .toString();
                    propertyValue += " V";
                  }
                  if (imageDetails.Voltage) {
                    imageDetails.Voltage.reading = propertyValue;
                    imageDetails.Voltage.lastUpdatedTime =
                      item.timestamp || "No Data";
                    imageDetails.Voltage.healthStatus =
                      findParameterHealthStatus(item.parameterId);
                    imageDetails.Voltage.parameterId = item.parameterId;
                  } else {
                    imageDetails.Voltage = {
                      reading: propertyValue,
                      lastUpdatedTime: item.timestamp || "No Data",
                      healthStatus: findParameterHealthStatus(item.parameterId),
                      parameterId: item.parameterId,
                    };
                  }
                  break;
                case "Temp": //"Temperature":
                  if (isNaN(item.reading)) {
                    propertyValue = "No Data";
                  } else {
                    propertyValue = parseFloat(item.reading)
                      .toFixed(2)
                      .toString();
                    propertyValue += " °C";
                  }
                  if (imageDetails.Temperature) {
                    imageDetails.Temperature.reading = propertyValue;
                    imageDetails.Temperature.lastUpdatedTime =
                      item.timestamp || "No Data";
                    imageDetails.Temperature.healthStatus =
                      findParameterHealthStatus(item.parameterId);
                    imageDetails.Temperature.parameterId = item.parameterId;
                  } else {
                    imageDetails.Temperature = {
                      reading: propertyValue,
                      lastUpdatedTime: item.timestamp || "No Data",
                      healthStatus: findParameterHealthStatus(item.parameterId),
                      parameterId: item.parameterId,
                    };
                  }
                  break;
                case "RH": //"Humidity":
                  if (isNaN(item.reading)) {
                    propertyValue = "No Data";
                  } else {
                    propertyValue = parseFloat(item.reading)
                      .toFixed(2)
                      .toString();
                    propertyValue += " %";
                  }
                  if (imageDetails.Humidity) {
                    imageDetails.Humidity.reading = propertyValue;
                    imageDetails.Humidity.lastUpdatedTime =
                      item.timestamp || "No Data";
                    imageDetails.Humidity.healthStatus =
                      findParameterHealthStatus(item.parameterId);
                    imageDetails.Humidity.parameterId = item.parameterId;
                  } else {
                    imageDetails.Humidity = {
                      reading: propertyValue,
                      lastUpdatedTime: item.timestamp || "No Data",
                      healthStatus: findParameterHealthStatus(item.parameterId),
                      parameterId: item.parameterId,
                    };
                  }
                  break;
                case "Battery_DrainRate": //Battery
                  if (isNaN(item.reading)) {
                    propertyValue = "No Data";
                  } else {
                    propertyValue = parseFloat(item.reading)
                      .toFixed(2)
                      .toString();
                    propertyValue += " V/M";
                  }
                  if (imageDetails.Battery) {
                    imageDetails.Battery.reading = propertyValue;
                    imageDetails.Battery.lastUpdatedTime =
                      item.timestamp || "No Data";
                    imageDetails.Battery.healthStatus =
                      findParameterHealthStatus(item.parameterId);
                    imageDetails.Battery.parameterId = item.parameterId;
                  } else {
                    imageDetails.Battery = {
                      reading: propertyValue,
                      lastUpdatedTime: item.timestamp || "No Data",
                      healthStatus: findParameterHealthStatus(item.parameterId),
                      parameterId: item.parameterId,
                    };
                  }
                  break;
                case "Error":
                  break;
                default:
                  return {
                    name: propertyName,
                    value: propertyValue,
                    parameterId: item.parameterId,
                    order: order,
                    lastUpdatedTime: item.timestamp || "No Data",
                  };
                  break;
              }
            })
            .filter((node) => node !== undefined);
        }
      }

      popUpNodeData.sort(function (a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });

      if (_nodesMap[nodeId]["gateWayHealthStatus"]) {
        if (isNodeStatusFound) {
          let statusDetails = [];
          popUpNodeData.forEach((item) => {
            formatName(item);
            const color = findColor(item);
            item.healthStatus = findParameterHealthStatus(item.parameterId);
            statusDetails.push({
              name: item.displayName,
              color: color,
              hint: item.hint,
              healthStatus: item.healthStatus,
              lastUpdatedTime: item.lastUpdatedTime,
            });
          });
          threadedTreeInstance?.updateStatusDetails(nodeId, statusDetails);
        }

        if (isNodeImageFound) {
          threadedTreeInstance?.updateImageReadings(nodeId, imageDetails);
        }

        if (isNodeImageFound || isNodeStatusFound) {
          threadedTreeInstance?.setLastUpdatedTime(nodeId, lastUpdatedTime);
        } else if (!popUpNodeData.length) {
          threadedTreeInstance?.setNoDataMessage(nodeId, "No Data");
        }
      } else {
        threadedTreeInstance?.setNoDataMessage(nodeId, "No Communication");
      }

      _nodesMap[nodeId].popUpNodeData = popUpNodeData;
      _nodesMap[nodeId].imageDetails = imageDetails;
      _nodesMap[nodeId].lastUpdatedTime = lastUpdatedTime;
      _nodesMap[nodeId].isAPIAwaiting = false;
    };
    if (id) {
      updateSingleNodePopUp(id);
    } else {
      for (const nodeId of Object.keys(_nodesMap)) {
        updateSingleNodePopUp(nodeId);
      }
    }
    nodesMap = { ..._nodesMap };
  };

  const getRealTimeReadingsForPopUponError = function () {
    let _nodesMap = { ...nodesMap };

    for (const nodeId of Object.keys(_nodesMap)) {
      let { imageDetails } = _nodesMap[nodeId];

      if (!imageDetails) {
        imageDetails = {
          Voltage: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Temperature: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Humidity: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
          Battery: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime,
          },
        };
        _nodesMap[nodeId].imageDetails = imageDetails;
        if (_nodesMap[nodeId]["gateWayHealthStatus"]) {
          threadedTreeInstance?.updateImageReadings(nodeId, imageDetails);
        }
      }
    }

    nodesMap = { ..._nodesMap };
  };

  const getWSConnectionLinkForPopUp = function (payload) {
    if (payload?.length) {
      invokeAPI(
        "POST",
        realTimeDataWSEndpoint,
        getWSConnectionLinkForPopUponSuccess,
        null,
        payload,
        accessOrigin
      );
    }
  };

  const customWebSocketConnectionForPopUp = function (
    webSocketMethod,
    webSocketUrl
  ) {
    if (!webSocketMethod || !webSocketUrl) return false;

    wsConnectionObjForPopUp = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    wsConnectionObjForPopUp.on("BasicParameterSync", function (_data) {
      //for basic parameters update
      let data = JSON.parse(_data);

      basicParameterWSUpdater(data);
    });

    wsConnectionObjForPopUp.on("DerivedParameterSync", function (_data) {
      //for derived parameters update
      let data = JSON.parse(_data);

      derivedParameterWSUpdaterForPopUp(data);
    });

    wsConnectionObjForPopUp
      .start()
      .then(function () {
        wsConnectionObjForPopUp
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              uniqueId +
              "PopUp" +
              "_BasicParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });

        wsConnectionObjForPopUp
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              uniqueId +
              "PopUp" +
              "_DerivedParameter"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    wsConnectionObjForPopUp.onreconnected(function () {
      wsConnectionObjForPopUp
        .invoke(
          "JoinGroup",
          companyId +
            "_" +
            applicationId +
            "_" +
            uniqueId +
            "PopUp" +
            "_BasicParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });

      wsConnectionObjForPopUp
        .invoke(
          "JoinGroup",
          companyId +
            "_" +
            applicationId +
            "_" +
            uniqueId +
            "PopUp" +
            "_DerivedParameter"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  };

  const getWSConnectionLinkForPopUponSuccess = function (data) {
    if (!data || data.length <= 0) return false;
    //Invoke the WS connection
    customWebSocketConnectionForPopUp(
      data[0].webSocketMethod,
      data[0].webSocketUrl
    );
  };

  const invokeWebSocketForPopUp = function () {
    let webSocketPayload = [];
    for (const node of stationNodeList) {
      let dpDetails = {};
      let dcStatus = null;
      let batteryDrainRate = null;

      if (node.parameters) {
        node.parameters.map((dp) => {
          dpDetails[dp.ParameterCategory] = dp.ParameterID;
        });
      }
      if (node.derivedparameters) {
        batteryDrainRate = node.derivedparameters.find(
          (dp) => dp.ParameterCategory === "Battery_DrainRate"
        );

        dcStatus = node.derivedparameters.find(
          (dp) => dp.ParameterCategory === "DC_status"
        );
      }

      webSocketPayload = webSocketPayload.concat(
        Object.keys(dpDetails).map((prop) => ({
          parameterId: dpDetails[prop],
          type: "BasicParameter",
          uniqueId: uniqueId + "PopUp",
        }))
      );

      if (dcStatus) {
        webSocketPayload = webSocketPayload.concat({
          parameterId: dcStatus.DerivedParameterId,
          type: "DerivedParameter",
          uniqueId: uniqueId + "PopUp",
        });
      }

      if (batteryDrainRate) {
        webSocketPayload = webSocketPayload.concat({
          parameterId: batteryDrainRate.DerivedParameterId,
          type: "DerivedParameter",
          uniqueId: uniqueId + "PopUp",
        });
      }
    }

    //WS API invoke
    getWSConnectionLinkForPopUp(webSocketPayload);
  };

  const updateBasicParameterReadingsForPopUp = function (
    data,
    nodeId,
    timestamp
  ) {
    let isNodeStatusFound = false;
    let isNodeImageFound = false;
    let _nodesMap = { ...nodesMap };

    if (nodeId) {
      let { dpDetails, popUpNodeData, imageDetails } = _nodesMap[nodeId];

      let _lastUpdatedTime = null;

      if (timestamp) {
        _lastUpdatedTime = timestamp;
      }

      if (!imageDetails) {
        imageDetails = {
          Voltage: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime: _lastUpdatedTime,
          },
          Temperature: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime: _lastUpdatedTime,
          },
          Humidity: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime: _lastUpdatedTime,
          },
          Battery: {
            reading: "No Data",
            healthStatus: true,
            lastUpdatedTime: _lastUpdatedTime,
          },
        };
      }

      if (popUpNodeData.length) {
        popUpNodeData = popUpNodeData.map((node) => {
          const match = data.find(
            (item) => item.parameterId === node.parameterId
          );
          if (match) {
            isNodeStatusFound = true;
            node.value = match.reading;
            node.lastUpdatedTime = _lastUpdatedTime;
          }
          return node;
        });
      }

      data.map((item) => {
        let property = Object.keys(dpDetails).find(
          (key) => dpDetails[key] === item.parameterId
        );
        if (property) {
          isNodeImageFound = true;
        }
        let propertyValue = item.reading;
        switch (property) {
          case "DCVoltage":
            if (isNaN(item.reading)) {
              propertyValue = "No Data";
            } else {
              propertyValue = parseFloat(item.reading).toFixed(2).toString();
              propertyValue += " V";
            }
            if (imageDetails.Voltage) {
              imageDetails.Voltage.reading = propertyValue;
              imageDetails.Voltage.lastUpdatedTime = _lastUpdatedTime;
              imageDetails.Voltage.healthStatus = findParameterHealthStatus(
                item.parameterId
              );
              imageDetails.Voltage.parameterId = item.parameterId;
            } else {
              imageDetails.Voltage = {
                reading: propertyValue,
                lastUpdatedTime: _lastUpdatedTime,
                healthStatus: findParameterHealthStatus(item.parameterId),
                parameterId: item.parameterId,
              };
            }
            break;
          case "Temp":
            if (isNaN(item.reading)) {
              propertyValue = "No Data";
            } else {
              propertyValue = parseFloat(item.reading).toFixed(2).toString();
              propertyValue += " °C";
            }
            if (imageDetails.Temperature) {
              imageDetails.Temperature.reading = propertyValue;
              imageDetails.Temperature.lastUpdatedTime = _lastUpdatedTime;
              imageDetails.Temperature.healthStatus = findParameterHealthStatus(
                item.parameterId
              );
              imageDetails.Temperature.parameterId = item.parameterId;
            } else {
              imageDetails.Temperature = {
                reading: propertyValue,
                lastUpdatedTime: _lastUpdatedTime,
                healthStatus: findParameterHealthStatus(item.parameterId),
                parameterId: item.parameterId,
              };
            }
            break;
          case "RH":
            if (isNaN(item.reading)) {
              propertyValue = "No Data";
            } else {
              propertyValue = parseFloat(item.reading).toFixed(2).toString();
              propertyValue += " %";
            }
            if (imageDetails.Humidity) {
              imageDetails.Humidity.reading = propertyValue;
              imageDetails.Humidity.lastUpdatedTime = _lastUpdatedTime;
              imageDetails.Humidity.healthStatus = findParameterHealthStatus(
                item.parameterId
              );
              imageDetails.Humidity.parameterId = item.parameterId;
            } else {
              imageDetails.Humidity = {
                reading: propertyValue,
                lastUpdatedTime: _lastUpdatedTime,
                healthStatus: findParameterHealthStatus(item.parameterId),
                parameterId: item.parameterId,
              };
            }
            break;
          case "Battery_DrainRate": //Battery
            if (isNaN(item.reading)) {
              propertyValue = "No Data";
            } else {
              propertyValue = parseFloat(item.reading).toFixed(2).toString();
              propertyValue += " V/M";
            }
            if (imageDetails.Battery) {
              imageDetails.Battery.reading = propertyValue;
              imageDetails.Battery.lastUpdatedTime = _lastUpdatedTime;
              imageDetails.Battery.healthStatus = findParameterHealthStatus(
                item.parameterId
              );
              imageDetails.Battery.parameterId = item.parameterId;
            } else {
              imageDetails.Battery = {
                reading: propertyValue,
                lastUpdatedTime: _lastUpdatedTime,
                healthStatus: findParameterHealthStatus(item.parameterId),
                parameterId: item.parameterId,
              };
            }
            break;
          case "Error":
            break;
          default:
            break;
        }
      });

      popUpNodeData.sort(function (a, b) {
        if (a.order < b.order) {
          return -1;
        }
        if (a.order > b.order) {
          return 1;
        }
        return 0;
      });
      if (_nodesMap[nodeId]["gateWayHealthStatus"]) {
        if (isNodeStatusFound) {
          let statusDetails = [];
          popUpNodeData.forEach((item) => {
            formatName(item);
            const color = findColor(item);
            item.healthStatus = findParameterHealthStatus(item.parameterId);
            statusDetails.push({
              name: item.displayName,
              color: color,
              hint: item.hint,
              healthStatus: item.healthStatus,
              lastUpdatedTime: item.lastUpdatedTime,
            });
          });
          threadedTreeInstance?.updateStatusDetails(nodeId, statusDetails);
        }

        if (isNodeImageFound && threadedTreeInstance) {
          threadedTreeInstance?.updateImageReadings(nodeId, imageDetails);
        }

        if (isNodeImageFound || isNodeStatusFound) {
          threadedTreeInstance?.setLastUpdatedTime(nodeId, _lastUpdatedTime);
        }
      } else {
        threadedTreeInstance?.setNoDataMessage(nodeId, "No Communication");
      }
      _nodesMap[nodeId].popUpNodeData = popUpNodeData;
      _nodesMap[nodeId].imageDetails = imageDetails;
    }
    nodesMap = { ..._nodesMap };
    console.log(
      "Nodes Map after basic parameter websocket update for pop-up",
      nodesMap
    );
  };

  const derivedParameterWSUpdater = function (data) {
    if (!validateWebSocketData(data)) {
      console.error(
        "WebSocket update failed in delRailwayIoT Prefab, calling RealTime API!!!"
      );
      getRealTimeData();
      return;
    }

    console.log("Derived Parameter Websocket update received", data);

    if (nodesData?.length <= 0) return false;

    getRealTimeDataonSuccess(data.parameters, data.unixtime);
  };

  const basicParameterWSUpdater = function (data) {
    if (!validateWebSocketData(data)) {
      console.error(
        "WebSocket update failed in delRailwayIoT Prefab, calling RealTime API!!!"
      );
      getRealTimeReadingsForPopUp();
      return;
    }

    console.log("Basic Parameter Websocket update received", data);

    for (const nodeId of Object.keys(nodesMap)) {
      const nodeDetails = nodesMap[nodeId].nodeDetails;
      if (!nodeDetails || !nodeDetails.parameters) {
        continue;
      }
      const _lastUpdatedTime = data.unixtime;
      const matches = data.parameters.filter((el) => {
        return nodeDetails.parameters.find(
          (innerEl) => innerEl.ParameterID === el.parameterId
        );
      });
      if (nodesMap[nodeId].popUpNodeData) {
        updateBasicParameterReadingsForPopUp(matches, nodeId, _lastUpdatedTime);
      } else {
        getRealTimeReadingsForPopUponSuccess(matches, _lastUpdatedTime);
      }
    }
  };

  const derivedParameterWSUpdaterForPopUp = function (data) {
    if (!validateWebSocketData(data)) {
      console.error(
        "WebSocket update for Pop Up failed in delRailwayIoT Prefab, calling RealTime API!!!"
      );
      getRealTimeData();
      return;
    }

    console.log("Derived Parameter Websocket update for Pop Up received", data);

    if (nodesData.length <= 0) return false;

    let _nodesMap = { ...nodesMap };

    for (const nodeId of Object.keys(_nodesMap)) {
      let { dcStatus, dpDetails, popUpNodeData, imageDetails } =
        _nodesMap[nodeId];

      let _lastUpdatedTime = data.unixtime;
      let isNodeStatusFound = false;
      let isNodeImageFound = false;

      if (!imageDetails) {
        imageDetails = {};
      }
      if (data.parameters) {
        for (let element of data.parameters) {
          if (element.parameterId === dcStatus.DerivedParameterId) {
            popUpNodeData = popUpNodeData.map((item) => {
              if (dcStatus.DerivedParameterId === item.parameterId) {
                isNodeStatusFound = true;
                return {
                  name: dcStatus.ParameterCategory,
                  value: element.reading,
                  lastUpdatedTime: _lastUpdatedTime,
                  parameterId: item.parameterId,
                  order: 0,
                };
              }
              return item;
            });
          } else if (
            dpDetails &&
            dpDetails["Battery_DrainRate"] === element.parameterId
          ) {
            let propertyValue = "No Data";
            isNodeImageFound = true;
            if (!isNaN(element.reading)) {
              propertyValue = parseFloat(element.reading).toFixed(2).toString();
              propertyValue += " V/M";
            }
            if (imageDetails.Battery) {
              imageDetails.Battery.reading = propertyValue;
              imageDetails.Battery.lastUpdatedTime = _lastUpdatedTime;
              imageDetails.Battery.healthStatus = findParameterHealthStatus(
                element.parameterId
              );
              imageDetails.Battery.parameterId = element.parameterId;
            } else {
              imageDetails.Battery = {
                reading: propertyValue,
                lastUpdatedTime: _lastUpdatedTime,
                healthStatus: findParameterHealthStatus(element.parameterId),
                parameterId: element.parameterId,
              };
            }
          }
        }
      }
      if (_nodesMap[nodeId]["gateWayHealthStatus"]) {
        if (isNodeStatusFound) {
          let statusDetails = [];
          popUpNodeData.forEach((item) => {
            formatName(item);
            const color = findColor(item);
            item.healthStatus = findParameterHealthStatus(item.parameterId);
            statusDetails.push({
              name: item.displayName,
              color: color,
              hint: item.hint,
              healthStatus: item.healthStatus,
              lastUpdatedTime: item.lastUpdatedTime,
            });
          });
          threadedTreeInstance?.updateStatusDetails(nodeId, statusDetails);
        }

        if (isNodeImageFound) {
          threadedTreeInstance?.updateImageReadings(nodeId, imageDetails);
        }

        if (isNodeImageFound || isNodeStatusFound) {
          threadedTreeInstance?.setLastUpdatedTime(nodeId, _lastUpdatedTime);
        }
      } else {
        threadedTreeInstance?.setNoDataMessage(nodeId, "No Communication");
      }

      _nodesMap[nodeId].popUpNodeData = popUpNodeData;
      _nodesMap[nodeId].imageDetails = imageDetails;
      lastUpdatedTime = _lastUpdatedTime;
    }
    nodesMap = { ..._nodesMap };
    console.log("Nodes Map after derived parameter websocket update", nodesMap);
  };

  // API related code ends here

  const getTrainStationDetails = function () {
    let parameterBody = [];

    webSocketPayload = [];

    if (wsConnectedObj) {
      wsConnectedObj.stop();
    }

    if (wsConnectionObjForPopUp) {
      wsConnectionObjForPopUp.stop();
    }

    stationNodeList.forEach((node) => {
      if (node.parameters && node.parameters.length) {
        //Save the device category of each node
        node.DeviceCategory = node.parameters[0].DeviceCategory;
      } else {
        node.hint = "No Data";
      }

      // For the health status
      node.healthStatus = false;
      node.gateWayHealthStatus = false;
      node.deviceIdList = [];
      if (node.devices && node.devices.length) {
        node.deviceIdList = node.devices.map((device) => device.DeviceID);
      }

      node.statusParameter = !node.derivedparameters
        ? null
        : node.derivedparameters.find(
            (dp) => dp.ParameterCategory === "status"
          );

      if (node.statusParameter) {
        parameterBody.push({
          parameterId: node.statusParameter.DerivedParameterId,
          type: "Derived",
        });

        webSocketPayload.push({
          parameterId: node.statusParameter.DerivedParameterId,
          type: "DerivedParameter",
          uniqueId: uniqueId,
        });
      } else {
        node.hint = "Last Updated Time : No Data";
      }
    });

    invokeWebSocketForPopUp();

    // Store the updated data into a variable
    nodesData = [...stationNodeList];

    offlineParametersObj = {};
    getHealthStatus();
    getHealthStatusWebSocketUpdates();

    getRealTimeData(parameterBody);
    getWSConnectionLink(webSocketPayload);
  };

  const onNodeChange = function () {
    nodesData = [];
    stationNodeList = [];

    const nodeItem = JSON.parse(JSON.stringify($selectedNode));

    if (nodeItem.type === $parentStationNodeType) {
      stationNodeList = nodeItem.children;
    } else if (nodeItem.type === $childNodeType) {
      stationNodeList.push(nodeItem);
    } else {
      getNodeChildrenBasedOnLevel([nodeItem], $parentStationNodeType);
    }
    if (stationNodeList.length) {
      showNoStationsMessage = false;
      getTrainStationDetails();
    } else {
      showNoStationsMessage = true;
    }
  };

  // ---------------------------------------------------- HeartBeat related code ---------------------------------------------- //

  const findParameterHealthStatus = function (parameterId) {
    if (offlineParametersList.includes(parameterId)) {
      return false;
    }
    return true;
  };

  const updateParameterHealthStatus = function () {
    let _nodesMap = { ...nodesMap };
    for (const nodeId of Object.keys(_nodesMap)) {
      let { popUpNodeData, imageDetails, lastUpdatedTime } = _nodesMap[nodeId];

      // Updating image readings health status
      for (const imageItem of Object.keys(imageDetails)) {
        imageDetails[imageItem].healthStatus = findParameterHealthStatus(
          imageDetails[imageItem].parameterId
        );
      }

      //  Updating status parameters health status
      let statusDetails = [];
      popUpNodeData.forEach((item) => {
        formatName(item);
        const color = findColor(item);
        item.healthStatus = findParameterHealthStatus(item.parameterId);
        statusDetails.push({
          name: item.displayName,
          color: color,
          hint: item.hint,
          healthStatus: item.healthStatus,
          lastUpdatedTime: item.lastUpdatedTime,
        });
      });

      console.log(
        "statusDetails and imageDetails after parameter status update",
        statusDetails,
        imageDetails
      );

      if (!_nodesMap[nodeId].gateWayHealthStatus) {
        threadedTreeInstance?.setNoDataMessage(nodeId, "No Communication");
      } else {
        threadedTreeInstance?.updateStatusDetails(nodeId, statusDetails);
        threadedTreeInstance?.updateImageReadings(nodeId, imageDetails);
        threadedTreeInstance?.setLastUpdatedTime(nodeId, lastUpdatedTime);
      }

      _nodesMap[nodeId].popUpNodeData = popUpNodeData;
      _nodesMap[nodeId].imageDetails = imageDetails;
    }
    nodesMap = { ..._nodesMap };
  };

  const getHealthStatusonSuccess = function (data) {
    console.log("Health Status Data", data);
    isHeartBeatAPIAwaiting = false;
    if (data && data.Gateways) {
      nodesData.forEach((node) => {
        let nodeHealthStatus = [];
        let deviceFound = false;
        for (const deviceId of node.deviceIdList) {
          let found = false;
          for (const gateway of data.Gateways) {
            for (const device of gateway.Devices) {
              if (device.DeviceId === deviceId) {
                const statusParameterId = node.statusParameter
                  ? node.statusParameter.DerivedParameterId
                  : null;
                const parameterStatus =
                  !device.OfflineDerivedParameters.includes(statusParameterId);
                nodeHealthStatus.push({
                  deviceStatus: device.DeviceHealth,
                  gateWayStatus: gateway.GatewayHealth,
                  parameterStatus,
                });
                offlineParametersObj[deviceId] =
                  device.OfflineParameters.concat(
                    device.OfflineDerivedParameters
                  );
                found = true;
                deviceFound = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        }
        if (deviceFound) {
          node.healthStatus = nodeHealthStatus.some(
            (item) =>
              item.deviceStatus && item.gateWayStatus && item.parameterStatus
          );
          node.gateWayHealthStatus = nodeHealthStatus.some(
            (item) => item.deviceStatus && item.gateWayStatus
          );
          if (nodesMap[node.id]) {
            nodesMap[node.id]["gateWayHealthStatus"] = node.gateWayHealthStatus;
          }
        }
      });
      offlineParametersList = Object.values(offlineParametersObj).flat();
      console.log(
        "offlineParametersList after health status update",
        offlineParametersObj,
        offlineParametersList
      );

      console.log("Nodes Data after health status update", nodesData);
    }
    updateParameterHealthStatus();
    console.log("Nodes Map after health status update", nodesMap);
    threadedTreeInstance?.updateThreadedTree(nodesData);
  };

  const getHealthStatusWebSocketUpdatesonSuccess = function (data) {
    if (healthStatusWS) healthStatusWS.stop();
    if (data) {
      customWebSocketConnectionHealthStatus(
        data.webSocketMethod,
        data.webSocketUrl
      );
    }
  };

  const customWebSocketConnectionHealthStatus = function (
    webSocketMethod,
    webSocketUrl
  ) {
    if (!webSocketMethod || !webSocketUrl) return false;

    healthStatusWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    healthStatusWS.on(webSocketMethod, function (_data) {
      let data = JSON.parse(_data);
      getHealthStatusonSuccess(data);
    });

    healthStatusWS
      .start()
      .then(function () {
        healthStatusWS
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              uniqueId +
              "_HealthStatus" +
              "_HeartBeat"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    healthStatusWS.onreconnected(function () {
      healthStatusWS
        .invoke(
          "JoinGroup",
          companyId +
            "_" +
            applicationId +
            "_" +
            uniqueId +
            "_HealthStatus" +
            "_HeartBeat"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  };

  // ------------------------- End of HeartBeat related code --------------- //

  const onNodeClicked = function (data) {
    lastUpdatedTime = null;

    let _nodesMap = { ...nodesMap };
    let dcStatus = {};
    let realTimeAPIRequestBody = [];
    let dpDetails = {};
    noNodeSelected = data && !data.gateWayHealthStatus;
    if (noNodeSelected) {
      threadedTreeInstance?.setNoDataMessage(data.id, "No Communication");
    }

    if (!data?.parameters?.length) {
      threadedTreeInstance?.setNoDataMessage(data.id, "No Data");
      return false;
    }

    data.parameters.map((dp) => {
      dpDetails[dp.ParameterCategory] = dp.ParameterID;
    });
    const batteryDrainRate = data.derivedparameters.find(
      (dp) => dp.ParameterCategory === "Battery_DrainRate"
    );

    dcStatus = data.derivedparameters.find(
      (dp) => dp.ParameterCategory === "DC_status"
    );

    realTimeAPIRequestBody = realTimeAPIRequestBody.concat(
      Object.keys(dpDetails).map((prop) => ({
        parameterId: dpDetails[prop],
        type: "Basic",
      }))
    );

    if (dcStatus) {
      realTimeAPIRequestBody = realTimeAPIRequestBody.concat({
        parameterId: dcStatus.DerivedParameterId,
        type: "Derived",
      });
    }

    if (batteryDrainRate) {
      dpDetails.Battery_DrainRate = batteryDrainRate.DerivedParameterId;
      realTimeAPIRequestBody = realTimeAPIRequestBody.concat({
        parameterId: batteryDrainRate.DerivedParameterId,
        type: "Derived",
      });
    }

    _nodesMap[data.id] = {
      name: data.name,
      dcStatus: dcStatus,
      dpDetails: dpDetails,
      popUpNodeData: [],
      nodeDetails: data,
      lastUpdatedTime: null,
      isAPIAwaiting: true,
      gateWayHealthStatus: data.gateWayHealthStatus,
    };

    nodesMap = { ..._nodesMap };

    getRealTimeReadingsForPopUp(realTimeAPIRequestBody, data.id);
  };

  const onPopUpClosed = function (id) {
    threadedTreeInstance?.setLoader(id);
    let _nodesMap = { ...nodesMap };
    delete _nodesMap[id];
    nodesMap = { ..._nodesMap };
  };
</script>

<div class="del-dashboard-container del-page-container">
  <div class="del-container del-threaded-tree-container">
    {#if isHeartBeatAPIAwaiting || isStatusAPIAwaiting}
      <Loader></Loader>
    {/if}
    <DelThreadedTree
      bind:this={threadedTreeInstance}
      nodes={nodesData}
      {legendsInfo}
      {onNodeClicked}
      onModalClosed={onPopUpClosed}
      deployedURL={getDeployedURL()}
      {showNoStationsMessage}
    ></DelThreadedTree>
  </div>
  <div class="del-rail-iot-chart-container">
    {#if $selectedNode?.type === $childNodeType}
      <div class="del-railiot-trend-chart-small-screen-container">
        <DelTrendChart
          baseURL={getDomainURL()}
          nodeDetails={$selectedNode}
          currentScreen={"Dashboard"}
          chartTitle={trendChartMobileScreensConfig?.chartTitle}
          chartHeight={trendChartMobileScreensConfig?.chartHeight}
          parameterDetails={trendChartMobileScreensConfig?.parameterDetails}
          yAxisDetails={trendChartMobileScreensConfig?.yAxisDetails}
          uniqueId={trendChartMobileScreensConfig?.uniqueId}
        ></DelTrendChart>
      </div>
      <div class="del-railiot-trend-chart-large-screen-container del-container">
        <DelMultiAxisChart
          baseURL={getDomainURL()}
          nodeDetails={$selectedNode}
          currentScreen={"Dashboard"}
          title={trendChartConfig?.chartTitle}
          chartHeight={trendChartConfig?.chartHeight}
          chartColors={trendChartConfig?.chartColors}
          downloadFileName={trendChartConfig?.chartTitle}
          parameterDetails={trendChartConfig?.parameterDetails}
          startTimeOffset={$startTimeOffSet}
          endTimeOffset={$endTimeOffSet}
          uniqueID={trendChartConfig?.uniqueId}
          legendOffSetX={trendChartConfig?.legendOffSetX}
          dateTimeFormat={trendChartConfig?.dateTimeFormat}
          xAxisTitle={trendChartConfig?.xAxisTitle}
          xAxisTitleForSingleDay={trendChartConfig?.xAxisTitleForSingleDay}
          decimalPointsToShow={trendChartConfig.decimalPointsToShow}
        ></DelMultiAxisChart>
      </div>
    {/if}
  </div>
</div>

<style>
  .del-dashboard-container {
    padding: 16px 15px;
  }
  .del-threaded-tree-container {
    position: relative;
    min-height: 50px;
  }
  .del-rail-iot-chart-container {
    padding: 16px 0;
  }
  .del-railiot-trend-chart-small-screen-container {
    display: none;
  }

  /* For responsiveness */

  @media screen and (max-width: 900px) {
    .del-railiot-trend-chart-small-screen-container {
      display: block;
    }
    .del-railiot-trend-chart-large-screen-container {
      display: none;
    }
  }
</style>
