<script>
  import DelEventViewerResponsive from "../components/DelEventViewerResponsive/DelEventViewerResponsive.svelte";
  import {
    selectedNode,
    defaultNodeId,
    currentPage,
    availableScreens,
  } from "./../stores";
  import { screensToShow, eventsPageConfigurations } from "./../../config.js";
  import { getDomainURL, getDeployedURL } from "./../invokeAPI";

  $: selectedNodeId = $selectedNode?.id;

  const urlParams = new URLSearchParams(window.location.search);
  const nodeIdFromURL = urlParams.get("nodeId");

  if (nodeIdFromURL) {
    defaultNodeId.set(nodeIdFromURL);
  }

  const getPermission = function (currentScreen, allocatedScreens) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = allocatedScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };
  $: screenPermission = getPermission($currentPage, $availableScreens);
</script>

<div class="del-event-viewer-container">
  <DelEventViewerResponsive
    nodeDetails={$selectedNode}
    baseURL={getDomainURL()}
    deployedURL={getDeployedURL()}
    currentScreen={"Events"}
    columnDetails={eventsPageConfigurations?.eventTableColumns}
    columnsToShow={eventsPageConfigurations?.columnsToShow}
    uniqueId={eventsPageConfigurations?.uniqueId}
    isWritePermission={screenPermission === "RW"}
    categoriesList={eventsPageConfigurations?.eventCategories}
    noDataMessage={eventsPageConfigurations?.noDataMessage}
  />
</div>

<style>
  .del-event-viewer-container {
    padding: 32px 28px;
  }
</style>
