export default function getLocalStorageValues() {
  const applicationId = sessionStorage.getItem("appId");
  const companyId = localStorage.getItem("companyId");
  const companyName = localStorage.getItem("companyName");
  const applicationName = sessionStorage.getItem("appName");
  const userId = localStorage.getItem("sub");
  const userName = localStorage.getItem("uname");
  const realmName = localStorage.getItem("realmName");
  const isPageTitleDelpheon = localStorage.getItem("IncludeDelpheonLogo");
  const userType = localStorage.getItem("userType");
  const applicationsList = localStorage.getItem("applicationList")
    ? JSON.parse(localStorage.getItem("applicationList"))
    : [];

  return {
    applicationId,
    companyId,
    companyName,
    applicationName,
    userId,
    userName,
    realmName,
    isPageTitleDelpheon,
    applicationsList,
    userType,
  };
}
