<script>
  import Loader from "./../Loader/Loader.svelte";
  import moment from "moment";
  import Chart from "chart.js/auto";
  import * as signalR from "@microsoft/signalr";
  import { onMount, onDestroy } from "svelte";

  export let baseURL = null;
  export let currentScreen = "Dashboard";
  export let nodeDetails = null;
  export let parameterDetails = null;
  export let yAxisDetails = null;
  export let chartTitle = "";
  export let chartType = "line";
  export let chartHeight = "";
  export let xAxisTitle = "";
  export let showLegends = true;
  export let legendPosition = "top";
  export let legendAlignment = "end";
  export let dateTimeFormat = "DD MMM YYYY H:mm:ss";
  export let dateTimeFormatXAxis = "H:mm";
  export let dateTimeFormatOnHover = "DD MMM YYYY H:mm:ss";
  export let groupBy = "none";
  export let uniqueId = "del-trend-chart-for-small-screens";

  let fromDate = "";
  let toDate = "";
  let toTimeEpoch = null;
  let fromTimeEpoch = null;
  let isLastPage = false;
  let showNoDataMessage = false;
  let isAPIAwaiting = false;
  let chartElement = null;
  let chart = null;
  let filteredParameters = {};
  let xAxisData = [];
  let isNextButtonDisabled = false;
  let operationsList = {};
  let realTimeReadingsWS = null;

  onMount(() => {
    drawChart();
  });

  onDestroy(() => {
    if (realTimeReadingsWS) {
      realTimeReadingsWS.stop();
    }
  });

  $: {
    if (nodeDetails && parameterDetails) {
      setPayload();
    }
  }

  $: {
    if (yAxisDetails) {
      updateChartScales();
    }
  }

  $: if (dateTimeFormat) {
    setChartDate(fromTimeEpoch, toTimeEpoch);
  }

  $: {
    updateChartOptions("type", chartType);
  }
  $: {
    updateChartOptions("xaxistitle", xAxisTitle);
  }
  $: {
    updateChartOptions("showlegends", showLegends);
  }
  $: {
    updateChartOptions("legendposition", legendPosition);
  }
  $: {
    updateChartOptions("legendalignment", legendAlignment);
  }

  const updateChartOptions = function (key, newVal) {
    if (chart && key) {
      switch (key) {
        case "type":
          chart.config.type = newVal || "line";
          chart.update();
          break;
        case "xaxistitle":
          chart.options.scales.x.title.text = newVal || "";
          chart.update();
          break;
        case "showlegends":
          chart.options.plugins.legend.display = newVal;
          chart.update();
          break;
        case "legendposition":
          chart.options.plugins.legend.position = newVal || "top";
          chart.update();
          break;
        case "legendalignment":
          chart.options.plugins.legend.align = newVal || "end";
          chart.update();
          break;
        default:
          break;
      }
    }
  };

  const formatTimeStamp = function (timestamp, format) {
    if (timestamp && format) {
      return moment(new Date(timestamp * 1000)).format(format);
    }
    return null;
  };

  const convertToTwoDigits = function (value) {
    if (isNaN(value)) return null;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : value;
  };

  const epoch13to10 = function (timestamp) {
    if (!timestamp) return 0;
    return parseInt(timestamp / 1000);
  };

  const drawChart = function () {
    if (chartElement) {
      const dataset = Object.values(filteredParameters);

      const format = dateTimeFormatXAxis || "H:mm";
      let xAxisObject = {
        x: {
          title: {
            display: true,
            text: xAxisTitle || "",
            color: "#3C3C3C",
          },
          grid: {
            display: false,
          },
          border: {
            color: "#656d76",
            width: 2,
          },
          ticks: {
            callback: function (value, index, ticks) {
              return formatTimeStamp(this.getLabelForValue(value), format);
            },
          },
        },
      };

      let _yAxisDetails = {};

      if (yAxisDetails) {
        for (const yItem of yAxisDetails) {
          _yAxisDetails[yItem.AxisId] = {
            type: "linear",
            display: "auto",
            grid: {
              display: false,
              color: yItem.Color, // Set the color of the y-axis line
              lineWidth: 1,
            },
            border: {
              color: yItem.Color,
              width: 2,
            },
            title: {
              display: true,
              text: yItem.Title || "",
              color: yItem.Color,
            },
            position: yItem.Position,
          };
        }
      }

      const scalesObject = { ...xAxisObject, ..._yAxisDetails };

      chart = new Chart(chartElement, {
        // The type of chart we want to create
        type: chartType || "line",
        // The data for our dataset
        data: {
          labels: [...xAxisData],
          datasets: [...dataset],
        },
        // configuration options
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: showLegends,
              position: legendPosition || "top",
              align: legendAlignment || "end",
              labels: {
                boxHeight: 15,
                boxWidth: 15,
              },
            },
            tooltip: {
              mode: "index",
              intersect: false,
              callbacks: {
                title: function (context) {
                  const unixtime = context[0].dataset.xAxisData
                    ? context[0].dataset.xAxisData[context[0].dataIndex]
                    : null;
                  const format = dateTimeFormatOnHover || "DD MMM YYYY H:mm:ss";
                  if (unixtime && format) {
                    return formatTimeStamp(unixtime, format);
                  }
                },
              },
            },
          },
          scales: scalesObject,
        },
      });
    }
  };

  const updateChart = function () {
    const dataset = Object.values(filteredParameters);
    chart.data.labels = [...xAxisData];
    chart.data.datasets = [...dataset];
    chart.update();
  };

  const clearChartData = function () {
    xAxisData = [];
    for (const parameterId of Object.keys(filteredParameters)) {
      filteredParameters[parameterId].data = [];
      filteredParameters[parameterId].xAxisData = [];
    }
    if (chart) {
      updateChart();
    }
  };

  const updateChartScales = function () {
    if (!chart) return;
    const format = dateTimeFormatXAxis || "H:mm";
    let xAxisObject = {
      x: {
        title: {
          display: true,
          text: xAxisTitle || "",
          color: "#3C3C3C",
        },
        grid: {
          display: false,
        },
        border: {
          color: "#656d76",
          width: 2,
        },
        ticks: {
          callback: function (value, index, ticks) {
            return formatTimeStamp(this.getLabelForValue(value), format);
          },
        },
      },
    };

    let yAxisDetails = {};

    if (yAxisDetails) {
      for (const yItem of yAxisDetails) {
        yAxisDetails[yItem.AxisId] = {
          type: "linear",
          display: "auto",
          grid: {
            display: false,
          },
          border: {
            color: yItem.Color,
            width: 2,
          },
          title: {
            display: true,
            text: yItem.Title || "",
            color: yItem.Color,
          },
          position: yItem.Position,
        };
      }
    }

    const scalesObject = { ...xAxisObject, ...yAxisDetails };
    chart.options.scales = scalesObject;
    chart.update();
  };

  const editChartTime = function (type) {
    const currentTime = epoch13to10(moment().valueOf());
    const oldStartTime = fromTimeEpoch * 1000;
    const oldEndTime = toTimeEpoch * 1000;
    let newStartTime = 0;
    let newEndTime = 0;
    switch (type) {
      case "prev":
        isLastPage = false;
        newEndTime = epoch13to10(oldStartTime);
        newStartTime = epoch13to10(
          moment(oldStartTime).subtract(1, "hours").valueOf()
        );
        break;
      case "next":
        newStartTime = epoch13to10(oldEndTime);
        newEndTime = epoch13to10(moment(oldEndTime).add(1, "hours").valueOf());

        const newEndTimeObj = moment(new Date(newEndTime * 1000));
        const currentTimeObj = moment(new Date(currentTime * 1000));
        isLastPage = false;

        if (newEndTimeObj.isSameOrAfter(currentTimeObj, "minute")) {
          newEndTime = currentTime;
          isLastPage = true;
          isNextButtonDisabled = true;
        }
        break;
      default:
        break;
    }
    fromTimeEpoch = newStartTime;
    toTimeEpoch = newEndTime;
    callGetChartDataAPI();
  };

  const callGetChartDataAPI = function () {
    let payloads = [];
    for (const operation in operationsList) {
      const item = operationsList[operation];
      payloads.push({
        operation: operation,
        basicParameters: item.basicParameterIdList.filter(
          (parameterId) => parameterId
        ),
        derivedParameters: item.derivedParameterIdList.filter(
          (parameterId) => parameterId
        ),
        starttime: fromTimeEpoch,
        endtime: toTimeEpoch,
        groupby: groupBy,
      });
    }

    const companyId = localStorage.getItem("companyId");
    const applicationId = sessionStorage.getItem("appId");
    const accessToken = localStorage.getItem("access_token");

    const endpoint = "parameters/telemetry/rawdata/all";

    let headers = {
      companyid: companyId,
      applicationid: applicationId,
      Authorization: `Bearer ${accessToken}`,
      "access-origin": `${currentScreen}/R`,
      "Content-Type": "application/json",
    };

    isAPIAwaiting = true;
    clearChartData();

    Promise.all(
      payloads
        .filter(
          (item) =>
            item.starttime &&
            item.endtime &&
            item.operation &&
            (item.basicParameters?.length || item.derivedParameters?.length)
        )
        .map((item) => {
          return fetch(`${baseURL}/${endpoint}`, {
            method: "POST",
            headers,
            body: JSON.stringify(item),
          });
        })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return [];
            }
          })
        )
      )
      .then((dataList) => {
        isAPIAwaiting = false;
        onGetDataSuccess(dataList);
      })
      .catch((error) => {
        isAPIAwaiting = false;
        showNoDataMessage = true;
        console.error("API call failed due to Error ", error);
      });
  };

  const onGetDataSuccess = function (responsesList) {
    xAxisData = [];
    let chartData = {};

    if (responsesList && responsesList.length) {
      for (const data of responsesList) {
        if (data && data.length) {
          for (const item of data) {
            let newObj = chartData[item.unixtime]
              ? chartData[item.unixtime]
              : {};
            for (const parameter of item.parameters) {
              newObj[parameter.parameterId] = convertToTwoDigits(
                parameter.reading
              );
            }
            chartData[item.unixtime] = newObj;
          }
        }
      }

      const xData = Object.keys(chartData).sort();
      xAxisData = [...xData];

      if (xData.length) {
        showNoDataMessage = false;
        setChartDate(xData.at(0), xData.at(-1));
      } else {
        showNoDataMessage = true;
        setChartDate(fromTimeEpoch, toTimeEpoch);
      }

      for (const timestamp of xData) {
        for (const parameterId of Object.keys(filteredParameters)) {
          filteredParameters[parameterId].data.push(
            chartData[timestamp][parameterId] !== undefined
              ? chartData[timestamp][parameterId]
              : null
          );
          filteredParameters[parameterId].xAxisData.push(timestamp);
        }
      }

      if (chart) {
        updateChart();
      } else {
        drawChart();
      }
    } else {
      showNoDataMessage = true;
      setChartDate(fromTimeEpoch, toTimeEpoch);
    }
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const invokeRealTimeAPI = function (payload) {
    const endpoint = "realtimedata/api/parameters/all/live";
    if (payload?.length) {
      invokeAPI(
        "POST",
        endpoint,
        onGetRealTimeDataSuccess,
        null,
        payload,
        `${currentScreen}/R`
      );
    }
  };

  const onGetRealTimeDataSuccess = function (data) {
    if (data.length) {
      customWebSocketConnectionRealTime(
        data.map((item) => item.webSocketMethod),
        data[0].webSocketUrl
      );
    }
  };

  const setPayload = function () {
    if (nodeDetails && parameterDetails?.length) {
      isLastPage = true;
      isNextButtonDisabled = true;
      const { _filteredParameters, payloadForWebSocket } = filterParameterIds();
      filteredParameters = _filteredParameters;
      fromTimeEpoch = epoch13to10(moment().subtract(1, "hours").valueOf());
      toTimeEpoch = epoch13to10(moment().valueOf());
      callGetChartDataAPI();
      if (realTimeReadingsWS) {
        realTimeReadingsWS.stop();
      }
      invokeRealTimeAPI(payloadForWebSocket);
    }
  };

  const filterParameterIds = function () {
    let payloadForWebSocket = [];
    let _filteredParameters = {};
    operationsList = {};

    const uniqueOperations = [
      ...new Set(parameterDetails.map((obj) => obj.Operation)),
    ];
    if (uniqueOperations) {
      uniqueOperations.forEach((item) => {
        operationsList[item] = {
          basicParameterIdList: [],
          derivedParameterIdList: [],
        };
      });
    }

    for (let parameterObj of parameterDetails) {
      const typeOfParameter = parameterObj.Type;
      const operation = parameterObj.Operation;
      switch (typeOfParameter) {
        case "Basic":
          if (nodeDetails?.parameters) {
            for (let basicParameter of nodeDetails?.parameters) {
              if (
                parameterObj.DeviceCategory.includes(
                  basicParameter.DeviceCategory
                ) &&
                parameterObj.ParameterCategory.includes(
                  basicParameter.ParameterCategory
                )
              ) {
                operationsList[operation].basicParameterIdList.push(
                  basicParameter.ParameterID
                );
                payloadForWebSocket.push({
                  parameterId: basicParameter.ParameterID,
                  type: "BasicParameter",
                  uniqueId: uniqueId,
                });
                _filteredParameters[basicParameter.ParameterID] = {
                  label: parameterObj.Label,
                  data: [],
                  xAxisData: [],
                  borderColor: parameterObj.Color,
                  backgroundColor: parameterObj.Color,
                  pointRadius: 1,
                  yAxisID: parameterObj.yAxisId,
                  borderWidth: parameterObj.lineWidth || 3,
                };
              }
            }
          }
          break;
        case "Derived":
          if (nodeDetails?.derivedparameters) {
            for (let derivedParameter of nodeDetails?.derivedparameters) {
              if (
                parameterObj.DeviceCategory.includes(
                  derivedParameter.DeviceCategory
                ) &&
                parameterObj.ParameterCategory.includes(
                  derivedParameter.ParameterCategory
                )
              ) {
                operationsList[operation].derivedParameterIdList.push(
                  derivedParameter.DerivedParameterId
                );
                payloadForWebSocket.push({
                  parameterId: derivedParameter.DerivedParameterId,
                  type: "DerivedParameter",
                  uniqueId: uniqueId,
                });
                _filteredParameters[derivedParameter.DerivedParameterId] = {
                  label: parameterObj.Label,
                  data: [],
                  xAxisData: [],
                  borderColor: parameterObj.Color,
                  backgroundColor: parameterObj.Color,
                  pointRadius: 1,
                  yAxisID: parameterObj.yAxisId,
                  borderWidth: parameterObj.lineWidth || 3,
                };
              }
            }
          }
          break;
        default:
          console.log("Type should either be Basic or Derived ", parameterObj);
          break;
      }
    }

    return { _filteredParameters, payloadForWebSocket };
  };

  const setChartDate = function (starttime, endtime) {
    const format = dateTimeFormat || "DD MMM YYYY H:mm:ss";
    if (!starttime) {
      fromDate = `From : ${formatTimeStamp(fromTimeEpoch, format)}`;
      toDate = `To : ${formatTimeStamp(fromTimeEpoch, format)}`;
      return;
    }
    fromDate = `From : ${formatTimeStamp(starttime, format)}`;
    toDate = `To : ${formatTimeStamp(endtime, format)}`;
  };

  // WebSocket Related Code

  const customWebSocketConnectionRealTime = function (
    webSocketMethods,
    webSocketUrl
  ) {
    if (!webSocketMethods.length || !webSocketUrl) return false;

    const applicationId = sessionStorage.getItem("appId");
    const companyId = localStorage.getItem("companyId");

    realTimeReadingsWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    webSocketMethods.forEach((method) => {
      realTimeReadingsWS.on(method, function (_data) {
        let data = JSON.parse(_data);
        formatWebSocketData(data);
      });
    });
    realTimeReadingsWS
      .start()
      .then(function () {
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + applicationId + "_" + uniqueId + "_BasicParameter"
          )
          .catch(function (err) {
            return console.warn({
              signalrerr: err.toString(),
            });
          });
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              uniqueId +
              "_DerivedParameter"
          )
          .catch(function (err) {
            return console.warn({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.warn("ws start error", e);
      });

    realTimeReadingsWS.onreconnected(function () {
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + applicationId + "_" + uniqueId + "_BasicParameter"
        )
        .catch(function (err) {
          return console.warn({
            signalrerr: err.toString(),
          });
        });
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + applicationId + "_" + uniqueId + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.warn({
            signalrerr: err.toString(),
          });
        });
    });
  };

  const validateWebSocketData = function (data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed) {
      return false;
    } else {
      return true;
    }
  };

  const checkDifference = function (start, end) {
    if (!(start && end)) return null;
    const starttime = moment(new Date(start * 1000));
    const endtime = moment(new Date(end * 1000));
    return endtime.diff(starttime, "hours", true);
  };

  // Append the websocket data
  const formatWebSocketData = function (data) {
    if (!validateWebSocketData(data)) {
      console.warn(
        "WebSocket update failed, calling GetData API from delTrendChartResponsive Prefab!!!",
        data
      );
      // Prefab.Variables.todayDate.dataSet.dataValue = moment().valueOf();
      callGetChartDataAPI();
      return;
    }

    console.log(
      "Websocket updates received in delTrendChartResponsive Prefab",
      data
    );

    if (isLastPage) {
      let sameAsPreviousTime = false;
      if (data.unixtime === xAxisData.at(-1)) {
        sameAsPreviousTime = true;
      } else {
        if (checkDifference(xAxisData.at(0), xAxisData.at(-1)) >= 1) {
          xAxisData.shift();
        }
        xAxisData.push(data.unixtime);
      }
      if (data && data.parameters) {
        for (let element of Object.keys(filteredParameters)) {
          const match = data.parameters.find(
            (parameter) => parameter.parameterId === element
          );
          if (!sameAsPreviousTime) {
            const xAxisData = filteredParameters[element].xAxisData;
            if (checkDifference(xAxisData.at(0), xAxisData.at(-1)) >= 1) {
              filteredParameters[element].xAxisData.shift();
            }
            filteredParameters[element].xAxisData.push(data.unixtime);
          }

          if (sameAsPreviousTime && match) {
            filteredParameters[match.parameterId].data.pop();
          }

          if (match) {
            filteredParameters[match.parameterId].data.push(
              convertToTwoDigits(match.reading)
            );
          } else if (!sameAsPreviousTime) {
            filteredParameters[element].data.push(null);
          }
        }
      }

      if (xAxisData?.length) {
        showNoDataMessage = false;
      } else {
        showNoDataMessage = true;
      }

      fromTimeEpoch = xAxisData.at(0);
      toTimeEpoch = xAxisData.at(-1);
      setChartDate(fromTimeEpoch, toTimeEpoch);

      if (chart) {
        updateChart();
      } else {
        drawChart();
      }
    }
  };

  // End of WebSocket related code

  const onPrevButtonClick = function () {
    isNextButtonDisabled = false;
    editChartTime("prev");
  };

  const onNextButtonClick = function () {
    editChartTime("next");
  };
</script>

<div class="del-responsive-trend-chart-prefab-container">
  {#if isAPIAwaiting}
    <Loader></Loader>
  {/if}
  <div class="del-responsive-trend-chart-title">{chartTitle ?? ""}</div>
  <div class="del-responsive-trend-chart-buttons-container">
    <div class="del-previous-button-container">
      <button
        name="trend-chart-prev-button"
        id="del-trend-chart-prev-button"
        class="del-responsive-trend-chart-buttons"
        on:click={onPrevButtonClick}>Prev</button
      >
    </div>
    <div class="del-responsive-trend-chart-date-labels-container">
      <div class="del-responsive-trend-chart-date-labels">{fromDate ?? ""}</div>
      <div class="del-responsive-trend-chart-date-labels">{toDate ?? ""}</div>
    </div>
    <div class="del-next-button-container">
      <button
        name="trend-chart-next-button"
        id="del-trend-chart-next-button"
        class="del-responsive-trend-chart-buttons"
        disabled={isNextButtonDisabled}
        on:click={onNextButtonClick}>Next</button
      >
    </div>
  </div>
  <div class="del-responsive-trend-chart-html-container">
    <div>
      <canvas
        id="responsive-trend-chart-container"
        style={`${chartHeight ? `height:${chartHeight}` : ""}`}
        bind:this={chartElement}
      ></canvas>
    </div>
  </div>
  {#if showNoDataMessage}
    <div class="del-responsive-trend-chart-no-data-message">
      No Data Available
    </div>
  {/if}
</div>

<style>
  .del-responsive-trend-chart-prefab-container {
    position: relative;
    background: #fff;
    border-radius: 20px;
    box-shadow: 5px 1px 7px #00000014;
    margin-bottom: 10px;
  }

  .del-responsive-trend-chart-title {
    text-align: left;
    font: normal normal bold 14px Roboto;
    color: #000000;
    padding: 10px 10px 0;
    margin-bottom: 5px;
  }

  .del-responsive-trend-chart-date-labels-container {
    padding-top: 5px;
  }

  .del-responsive-trend-chart-date-labels {
    width: 100%;
    font: normal normal bold 12px Roboto;
    color: #848c9f;
    text-align: center;
  }

  .del-responsive-trend-chart-buttons-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 5px 10px 0;
  }

  .del-responsive-trend-chart-buttons {
    font: normal normal normal 12px Roboto;
    padding: 5px;
    background-color: #ffffff;
    border: 1px solid #e3e2e2;
    border-radius: 5px;
    color: #4b72b9;
  }

  .del-responsive-trend-chart-buttons:enabled:hover {
    background-color: #4b72b9;
    color: #ffffff;
    border-color: transparent;
    cursor: pointer;
  }

  .del-responsive-trend-chart-buttons:disabled {
    border-color: transparent;
    background-color: #e3e2e2;
    color: #ffffff;
    cursor: not-allowed;
  }

  .del-responsive-trend-chart-no-data-message {
    position: absolute;
    top: 55%;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
  }

  .del-responsive-trend-chart-html-container {
    padding: 5px 10px;
  }
</style>
