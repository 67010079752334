<script>
  import {
    selectedNode,
    selectedDate,
    currentPage,
    availableScreens,
    isReportGenerated,
  } from "./../stores";
  import { getDomainURL, getDeployedURL } from "./../invokeAPI";
  import { eventReportConfigurations, screensToShow } from "./../../config";
  import DelEventReportWithFilter from "./../components/DelEventReportWithFilter/DelEventReportWithFilter.svelte";
  import DelTimePickerPopUp from "./../components/DelTimePickerPopUp/DelTimePickerPopUp.svelte";

  let selectedReport = null;

  let reportToBeShown = sessionStorage.getItem("report-to-be-shown");
  let reportStartTime = sessionStorage.getItem("report-selected-start-time");
  let reportEndTime = sessionStorage.getItem("report-selected-end-time");
  let reportSelectNodeId = sessionStorage.getItem("report-selected-node-id");
  let reportSelectNode = sessionStorage.getItem("report-selected-node")
    ? JSON.parse(sessionStorage.getItem("report-selected-node"))
    : null;
  let reportSelectedNodeName = sessionStorage.getItem(
    "report-selected-node-name"
  );

  let popUpStartTime = null;
  let popUpEndTime = null;
  let showPopUp = false;

  isReportGenerated.set(reportToBeShown);

  $: {
    setReportGeneratedFlag(reportToBeShown);
  }

  const setReportGeneratedFlag = function () {
    isReportGenerated.set(reportToBeShown);
  };

  const onEventReportClicked = function () {
    if (selectedReport === "Events" || reportToBeShown === "Events") {
      selectedReport = null;
      hideReport();
    } else {
      selectedReport = "Events";
      hideReport();
    }
  };

  const hideReport = function () {
    reportToBeShown = null;
    sessionStorage.removeItem("report-to-be-shown");
  };

  const onPopUpSubmitClick = function (start, end) {
    reportSelectNodeId = $selectedNode?.id || "";
    reportSelectedNodeName = $selectedNode?.name || "";
    reportSelectNode = { ...$selectedNode };

    sessionStorage.setItem("report-selected-node-id", reportSelectNodeId);
    sessionStorage.setItem("report-selected-node-name", reportSelectedNodeName);
    sessionStorage.setItem(
      "report-selected-node",
      JSON.stringify(reportSelectNode)
    );

    reportToBeShown = selectedReport;
    reportStartTime = start || 0;
    reportEndTime = end || 0;

    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;

    sessionStorage.setItem("report-to-be-shown", reportToBeShown);
    sessionStorage.setItem("report-selected-start-time", reportStartTime);
    sessionStorage.setItem("report-selected-end-time", reportEndTime);
  };

  const onPopUpCancelClick = function () {
    showPopUp = false;
    popUpEndTime = null;
    popUpStartTime = null;
  };

  const onSubmitButtonClick = function () {
    switch (selectedReport) {
      case "Events":
        popUpStartTime = $selectedDate?.dayDefinedStartDate || 0;
        popUpEndTime =
          $selectedDate?.dayDefinedEndDateInEpochNotGreaterThanNow || 0;
        showPopUp = true;
        break;
      default:
        break;
    }
  };

  const getPermission = function (currentScreen) {
    if (
      currentScreen &&
      screensToShow &&
      Object.keys(screensToShow).includes(currentScreen)
    ) {
      const screen = screensToShow[currentScreen].screen;
      const screenObject = $availableScreens.find(
        (item) => item.Name === screen
      );
      return screenObject.Permission;
    }
    return null;
  };

  const onBackButtonClick = function () {
    hideReport();
    selectedReport = null;
  };

  $: disableSubmitButton =
    !$selectedNode || $isReportGenerated || !selectedReport;

  $: screenPermission = getPermission($currentPage);
</script>

<div class="del-page-container del-report-page-container">
  {#if !reportToBeShown}
    <div class="del-container del-reports-selection-container">
      <div class="del-report-title">Select Report</div>
      <div class="del-reports-main-container">
        <div class="del-report-items-container">
          <div class="del-report-item">
            <button
              class={`del-reports-button ${
                selectedReport === "Events" || reportToBeShown === "Events"
                  ? "selected"
                  : ""
              }`}
              on:click={onEventReportClicked}
              disabled={!selectedNode || $isReportGenerated}
              >{eventReportConfigurations.buttonLabel}</button
            >
          </div>
          <div class="del-reports-submit-button-container">
            <button
              class="del-reports-submit-button"
              on:click={onSubmitButtonClick}
              disabled={disableSubmitButton}>Generate Report</button
            >
          </div>
        </div>
      </div>
    </div>
  {/if}

  {#if showPopUp}
    <div class="overlay-container"></div>
    <DelTimePickerPopUp
      defaultStartTime={popUpStartTime}
      defaultEndTime={popUpEndTime}
      onSubmit={onPopUpSubmitClick}
      onCancel={onPopUpCancelClick}
      submitButtonLabel={"Generate Report"}
      showLatestTimeAsEndTimeForToday={true}
    />
  {/if}
  {#if reportToBeShown === "Events"}
    <div class="del-report-container">
      <DelEventReportWithFilter
        baseURL={getDomainURL()}
        nodeId={reportSelectNodeId || null}
        nodeName={reportSelectedNodeName || ""}
        startTime={reportStartTime}
        endTime={reportEndTime}
        currentScreen={"Reports"}
        writePermission={screenPermission === "RW"}
        possibleColumns={eventReportConfigurations.columnsToShow}
        columnDetails={eventReportConfigurations.columnDetails}
        showBackButton={true}
        filterableColumns={eventReportConfigurations.columnsToFilter}
        {onBackButtonClick}
        possibleAlertCategories={eventReportConfigurations.options}
        deployedURL={getDeployedURL()}
      />
    </div>
  {/if}
</div>

<style>
  .del-report-page-container {
    padding: 24px 28px;
  }

  .del-reports-selection-container {
    padding: 20px;
  }
  .del-report-title {
    padding-bottom: 18px;
    text-align: left;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }
  .del-reports-main-container,
  .del-report-items-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    gap: 22px;
    flex-wrap: wrap;
  }
  .del-report-items-container {
    width: 100%;
  }
  .del-report-items-container .del-report-item {
    flex: 0 0 auto;
  }

  .del-reports-submit-button-container {
    margin-left: auto;
  }

  .del-reports-submit-button,
  .del-reports-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
  }

  .del-reports-submit-button:enabled,
  .del-reports-button.selected {
    border: none;
    background-color: #2c3049;
    color: white;
  }

  .del-reports-submit-button:enabled:active,
  .del-reports-button:enabled:active {
    border: 1px solid #2c3049;
  }

  .del-reports-submit-button:enabled:hover,
  .del-reports-button:enabled:hover {
    border-color: #2c3049;
  }

  .del-reports-submit-button:disabled,
  .del-reports-submit-button:disabled:hover,
  .del-reports-button:disabled,
  .del-reports-button:disabled:hover {
    cursor: not-allowed;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #eee;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    letter-spacing: 0px;
    color: #eee;
  }

  .overlay-container {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }
</style>
