<script>
  import { APP_VERSION } from "./../../../config";
  import { applicationName } from "./../../stores";

  export let insideModal = false;
</script>

<div
  class="del-about-dropdown-inner-container"
  class:del-modal-items-container={insideModal}
>
  <div class:del-about-item={!insideModal} class:del-modal-item={insideModal}>
    <div class="del-about-item-name">Application Name</div>
    <div class="del-about-item-value">{$applicationName}</div>
  </div>
  <div class:del-about-item={!insideModal} class:del-modal-item={insideModal}>
    <div class="del-about-item-name">Application Version</div>
    <div class="del-about-item-value">{APP_VERSION}</div>
  </div>
</div>
{#if !insideModal}
  <footer class="del-about-footer">
    Licensed by Gadgeon Systems Inc. All rights reserved
  </footer>
{/if}

<style>
  .del-about-dropdown-inner-container {
    padding: 15px;
  }

  .del-about-item {
    display: grid;
    column-gap: 15px;
    grid-template-columns: 40% auto;
  }

  .del-modal-items-container {
    padding: 0;
  }

  .del-modal-items-container,
  .del-modal-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: 12px;
  }

  .del-about-item:not(:last-child) {
    margin-bottom: 10px;
  }

  .del-about-item-name,
  .del-about-item-value {
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 0;
    color: #778388;
    opacity: 1;
  }

  .del-about-item-value {
    color: #000;
  }

  .del-modal-item .del-about-item-value,
  .del-modal-item .del-about-item-name {
    text-align: center;
  }

  .del-about-item .del-about-item-value::before {
    content: " : ";
    padding-right: 15px;
  }
  .del-about-footer {
    text-align: center;
    background: #a4aeb2 0 0 no-repeat padding-box;
    height: 15px;
    color: #fff;
    width: 100%;
    font: normal normal normal 12px Roboto;
    border-radius: 0 0 5px 5px;
    margin: 0;
    padding: 5px 0;
  }
</style>
