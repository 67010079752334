// Common Configurations
export const PREVIEW_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const DEV_DOMAIN_URL = "https://devplatformapi.delpheon.io";
export const QA_DOMAIN_URL = "https://qanewplatformapi.delpheon.io";
export const PRODUCTION_DOMAIN_URL = "";

export const PREVIEW_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const DEV_ANALYTICS_DOMAIN_URL = "https://analytics-dev.delpheon.io";
export const QA_ANALYTICS_DOMAIN_URL = "https://analytics-ae-qanew.delpheon.io";
export const PRODUCTION_ANALYTICS_DOMAIN_URL = "";

export const PREVIEW_DEPLOYED_URL = "http://localhost:3000";
export const DEV_DEPLOYED_URL = "https://railiot-devnew.delpheon.io";
export const QA_DEPLOYED_URL = "https://railiot-qanew.delpheon.io";
export const PRODUCTION_DEPLOYED_URL = "";

export const APP_VERSION = "2.0.5";

// Configurations for Header

export const pathNameSSPA = "/railwayassetsmonitoring";
export const pagesWithDatePicker = ["Reports"]; //should be the corresponding displayName
export const screensToShow = {
  "/": { screen: "Dashboard", displayName: "Dashboard" },
  "/Events": { screen: "Events", displayName: "Events" },
  "/Reports": { screen: "Reports", displayName: "Reports" },
  "/Error": { screen: "Dashboard", displayName: "Error" },
};

// Configurations for Dashboard
export const uniqueId = "del-rail-iot-dashboard";
export const legendsInfo = [
  {
    title: "All OK",
    color: "green",
    tooltip: "",
  },
  {
    title:
      "DC volt between 48V-56V and AC supply available but some other parameter not OK",
    color: "orange",
    tooltip: "",
  },
  {
    title:
      "DC volt outside range (below 48V or above 56V) or AC supply unavailable",
    color: "red",
    tooltip: "",
  },
  {
    title: "No Communication",
    color: "grey",
    tooltip: "",
  },
];

export const trendChartConfig = {
  chartTitle: "Drain Rate, DC Voltage Trend",
  chartHeight: "400px",
  dateTimeFormat: "MMM D, YY, HH:mm",
  parameterDetails: [
    {
      name: "Drain Rate (Volts / minute)",
      unit: "",
      DeviceCategory: "Del_IO",
      ParameterCategory: "Battery_DrainRate",
      type: "Derived",
      parameterId: null,
      aggregation: "avg",
      oppositeAxis: false,
    },
    {
      name: "DC Voltage (Volts)",
      unit: "",
      DeviceCategory: "Del_IO",
      ParameterCategory: "DCVoltage",
      type: "Basic",
      parameterId: null,
      aggregation: "avg",
      oppositeAxis: true,
    },
  ],
  chartColors: ["#FF0000", "#007700"],
  uniqueId: "del-trend-chart-for-large-screens",
  legendOffSetX: 0,
  xAxisTitle: "Date",
  xAxisTitleForSingleDay: "Timestamp",
  decimalPointsToShow: 3,
};

export const trendChartMobileScreensConfig = {
  chartTitle: "Drain Rate, DC Voltage Trend",
  chartHeight: "250px",
  parameterDetails: [
    {
      Label: "Drain Rate",
      yAxisId: "DrainRate",
      DeviceCategory: ["Del_IO"],
      ParameterCategory: ["Battery_DrainRate"],
      Type: "Derived",
      Color: "#FF0000",
      Operation: "avg",
      lineWidth: 1,
    },
    {
      Label: "DC Voltage",
      yAxisId: "DCVoltage",
      DeviceCategory: ["Del_IO"],
      ParameterCategory: ["DCVoltage"],
      Type: "Basic",
      Color: "#007700",
      Operation: "avg",
      lineWidth: 1,
    },
  ],
  yAxisDetails: [
    {
      AxisId: "DrainRate",
      Title: "Drain Rate (Volts / minute)",
      Color: "#FF0000",
      Position: "left",
    },
    {
      AxisId: "DCVoltage",
      Title: "DC Voltage (Volts)",
      Color: "#007700",
      Position: "right",
    },
  ],
  uniqueId: "del-trend-chart-for-small-screens",
};

// Configurations for Events Page

export const eventsPageConfigurations = {
  eventTableColumns: {
    alertName: "Alarm",
    nodeName: "Station",
    description: "Description",
    category: "Category",
    alertValue: "Alarm Value",
    createdDate: "In Time",
    acknowledgedDate: "Acked On",
    acknowledgedBy: "Acked By",
    action: "Action",
    disable: "Disable",
  },
  columnsToShow: [
    "alertName",
    "nodeName",
    "description",
    "category",
    "alertValue",
    "createdDate",
    "acknowledgedDate",
    "acknowledgedBy",
    "action",
    "disable",
  ],
  eventCategories: [
    { name: "Critical", value: "Critical" },
    { name: "Warning", value: "Warning" },
    { name: "Informational", value: "Informational" },
    { name: "Select Category", placeholder: true },
  ],
  uniqueId: "del-event-viewer-railiot",
  noDataMessage: "No active events available",
};

// Reports Configurations

// For Event Report
export const eventReportConfigurations = {
  buttonLabel: "Event Report",
  showSubmitButton: false,
  options: ["Critical", "Informational", "Warning"],
  columnsToShow: [
    "EventName",
    "EventCategory",
    "Source",
    "Description",
    "AlertValue",
    "OccuredOn",
    "CurrentStatus",
    "RemovedOn",
    "IsAcknowledged",
    "AcknowledgedBy",
    "AcknowledgedOn",
    "Comment",
  ],
  columnDetails: {
    EventName: {
      show: true,
      displayName: "Alarm Name",
    },
    EventCategory: {
      show: true,
      displayName: "Alarm Category",
    },
    Source: {
      show: true,
      displayName: "Station",
    },
    Description: {
      show: true,
      displayName: "Description",
    },
    AlertValue: {
      show: true,
      displayName: "Alert Value",
    },
    OccuredOn: {
      show: true,
      displayName: "In Time",
      isDateTime: true,
    },
    CurrentStatus: {
      show: true,
      displayName: "Status",
    },
    RemovedOn: {
      show: true,
      displayName: "Right Time",
      isDateTime: true,
    },
    IsAcknowledged: {
      show: true,
      displayName: "Ack Status",
      isBoolean: true,
    },
    AcknowledgedBy: {
      show: true,
      displayName: "Acked By",
    },
    AcknowledgedOn: {
      show: true,
      displayName: "Acked On",
      isDateTime: true,
    },
    Comment: {
      show: true,
      displayName: "Comment",
    },
  },
  columnsToFilter: [
    "EventName",
    "EventCategory",
    "Source",
    "CurrentStatus",
    "IsAcknowledged",
  ],
};
