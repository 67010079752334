<script>
  import moment from "moment";
  import DelDatePicker from "./DelDatePicker.svelte";
  import ApexCharts from "apexcharts";
  import * as signalR from "@microsoft/signalr";
  import Loader from "./../Loader/Loader.svelte";

  import { onDestroy } from "svelte";

  export let baseURL;
  export let nodeDetails;
  export let currentScreen = "Dashboard";
  export let parameterDetails;
  export let chartHeight = "350px";
  export let chartColors;
  export let title;
  export let downloadFileName;
  export let startTimeOffset;
  export let endTimeOffset;
  export let uniqueID;
  export let legendOffSetX = 140;
  export let dateTimeFormat = "DD MMM YYYY hh:mm:ss A";
  export let dateTimeFormatForSingleDay = "DD MMM YYYY";
  export let xAxisTitle = "";
  export let xAxisTitleForSingleDay = "";
  export let decimalPointsToShow = 2;

  const companyId = localStorage.getItem("companyId");
  const appId = sessionStorage.getItem("appId");

  let startDate;
  let endDate;
  let startDateInSeconds;
  let endDateInSeconds;
  let customStartDate;
  let customEndDate;
  let selectedPeriod =
    sessionStorage.getItem("multiaxischart-selected-timeperiod") ?? "Today";
  let isButtonClicked = true;
  let payloadList = [];
  let groupBy = "";
  let wsPayload = [];
  let wsData;
  let dataSeries = [];
  let xData = [];
  let yData = [];
  let isNoData = false;
  let isDataAwaiting = false;
  let chartDetails = [];
  let chartContainer = null;
  let chart = null;
  let realTimeReadingsWS = null;
  let dateFromDatePicker = null;
  let firstLoad = true;
  handlePeriod(selectedPeriod, false);

  $: {
    if (nodeDetails && baseURL && currentScreen && parameterDetails) {
      clearChart();
      fetchParameters();
    }
  }

  $: {
    if (startDate && endDate) {
      clearChart();
      formatPayload();
    }
  }

  onDestroy(() => {
    if (realTimeReadingsWS) {
      realTimeReadingsWS.stop();
    }
    if (chart) {
      chart.destroy();
    }
  });

  function clearChart() {
    xData = [];
    yData = [];
    dataSeries = [];
    updateSeries();
  }

  function fetchParameters() {
    let parameterId;
    chartDetails = parameterDetails.map((item) => ({ ...item }));
    for (let i = 0; i < chartDetails.length; i++) {
      parameterId = parameterIdResolver(
        chartDetails[i].DeviceCategory,
        chartDetails[i].ParameterCategory
      );

      chartDetails[i].parameterId = parameterId;
      if (i === 0) chartDetails[i].oppositeAxis = false;
      else chartDetails[i].oppositeAxis = true;
    }
    getWS();
    if (checkIfToday()) {
      handlePeriod("Today");
      formatPayload();
    } else if (!firstLoad) {
      handlePeriod("Today");
    } else {
      handlePeriod(selectedPeriod);
    }
    firstLoad = false;
  }

  function parameterIdResolver(dc, pc) {
    let foundflag = false; //flag for found

    if (nodeDetails && nodeDetails.parameters) {
      //checking for parameter in basic parameters
      for (let i = 0; i < nodeDetails.parameters.length; i++) {
        if (nodeDetails.parameters[i].DeviceCategory === dc) {
          if (nodeDetails.parameters[i].ParameterCategory === pc) {
            foundflag = true;
            return nodeDetails.parameters[i].ParameterID;
          }
        }
      }
    }

    //checking for parameters in derived paramters
    if (!foundflag) {
      if (nodeDetails && nodeDetails.derivedparameters) {
        for (let i = 0; i < nodeDetails.derivedparameters.length; i++) {
          if (nodeDetails.derivedparameters[i].DeviceCategory === dc) {
            if (nodeDetails.derivedparameters[i].ParameterCategory === pc) {
              foundflag = true;
              return nodeDetails.derivedparameters[i].DerivedParameterId;
            }
          }
        }
      }
    }
  }

  function formatPayload() {
    let operationsList = {};
    if (!(startDate && endDate)) {
      return;
    }

    payloadList = [];
    const uniqueOperations = [
      ...new Set(chartDetails.map((obj) => obj.aggregation)),
    ];
    if (uniqueOperations) {
      uniqueOperations.forEach((item) => {
        operationsList[item] = {
          basicParameterIdList: [],
          derivedParameterIdList: [],
        };
      });
    }

    for (let parameterObj of chartDetails) {
      const typeOfParameter = parameterObj.type;
      const operation = parameterObj.aggregation;
      if (parameterObj.parameterId) {
        dataSeries.push({
          name: parameterObj.name,
          data: [],
          parameterId: parameterObj.parameterId,
        });
        switch (typeOfParameter) {
          case "Basic":
            operationsList[operation].basicParameterIdList.push(
              parameterObj.parameterId
            );

            break;
          case "Derived":
            operationsList[operation].derivedParameterIdList.push(
              parameterObj.parameterId
            );

            break;
          default:
            console.log(
              "Type should either be Basic or Derived ",
              parameterObj
            );
            break;
        }
      }
    }

    for (const operation in operationsList) {
      payloadList.push({
        operation: operation,
        basicParameters: operationsList[operation].basicParameterIdList,
        derivedParameters: operationsList[operation].derivedParameterIdList,
        starttime: startDate,
        endtime: endDate,
        groupby: groupBy,
      });
    }

    console.log(
      "Operations list in DelMultiAxisChart and it's payloads",
      operationsList,
      payloadList
    );

    fetchRawData();
  }

  function fetchRawData() {
    isDataAwaiting = true;

    const access_token = "Bearer " + localStorage.getItem("access_token");
    const headers = {
      "Content-Type": "application/json",
      companyid: companyId,
      applicationid: appId,
      Authorization: access_token,
      "access-origin": `${currentScreen}/R`,
    };

    Promise.all(
      payloadList.map((item) => {
        return fetch(`${baseURL}/parameters/telemetry/rawdata/all`, {
          method: "POST",
          headers,
          body: JSON.stringify(item),
        }).then((response) => {
          return response;
        });
      })
    )
      .then((responses) =>
        Promise.all(
          responses.map((response) => {
            if (response.ok) {
              return response.json();
            } else {
              console.warn("API call failed", response);
              return { label, data: [] };
            }
          })
        )
      )
      .then((dataList) => {
        fetchRawDataOnSuccess(dataList);
        isDataAwaiting = false;
      })
      .catch((error) => {
        isDataAwaiting = false;
        console.error("API call failed due to Error ", error);
      });
  }

  function fetchRawDataOnSuccess(responsesList) {
    xData = [];
    let chartData = {};
    for (const parameterItem of dataSeries) {
      parameterItem.data = [];
    }
    if (responsesList && responsesList.length) {
      for (const data of responsesList) {
        if (data && data.length) {
          for (const item of data) {
            let newObj = chartData[item.unixtime]
              ? chartData[item.unixtime]
              : {};
            for (const parameter of item.parameters) {
              newObj[parameter.parameterId] = convertToTwoDigits(
                parameter.reading
              );
            }
            chartData[item.unixtime] = newObj;
          }
        }
      }

      xData = Object.keys(chartData).sort();
      if (xData.length) {
        isNoData = false;
      } else {
        isNoData = true;
      }
      for (const timestamp of xData) {
        for (const parameterItem of dataSeries) {
          parameterItem.data.push(
            chartData[timestamp][parameterItem.parameterId] !== undefined
              ? chartData[timestamp][parameterItem.parameterId]
              : null
          );
        }
      }
    }
    console.log("Chart data in DelMultiAxisChart", dataSeries, xData);
    formatYAxis();

    if (chart) updateSeries();
    else renderChart();
  }

  function formatYAxis() {
    yData = [];
    let yAxisFormat = {
      opposite: true,
      axisTicks: {
        show: true,
      },
      decimalsInFloat: decimalPointsToShow || 2,
      axisBorder: {
        show: true,
        color: "",
      },
      labels: {
        style: {
          colors: "",
        },
      },
      title: {
        text: "",
        style: {
          color: "",
        },
      },
    };
    for (let i = 0; i < chartDetails.length; i++) {
      yAxisFormat = {
        opposite: chartDetails[i].oppositeAxis,
        axisTicks: {
          show: true,
        },
        decimalsInFloat: decimalPointsToShow || 2,
        axisBorder: {
          show: true,
          color: chartColors[i],
        },
        labels: {
          style: {
            colors: chartColors[i],
          },
        },
        title: {
          text: chartDetails[i].name,
          style: {
            color: chartColors[i],
          },
        },
      };
      yData.push(yAxisFormat);
    }
  }

  async function getWS() {
    try {
      if (realTimeReadingsWS) {
        realTimeReadingsWS.stop();
      }
      const url = `${baseURL}/realtimedata/api/parameters/all/live`;

      const access_token = "Bearer " + localStorage.getItem("access_token");
      const headers = {
        "Content-Type": "application/json",
        companyid: companyId,
        applicationid: appId,
        Authorization: access_token,
        "access-origin": `${currentScreen}/R`,
      };

      wsPayload = [];
      chartDetails.forEach((param) => {
        wsPayload.push({
          parameterId: param.parameterId,
          type: param.type + "Parameter",
          uniqueId: uniqueID,
        });
      });

      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(wsPayload),
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        let error = await response.json();
        throw new Error(error);
      }
      wsData = await response.json();
      getWSonSuccess(wsData);
    } catch (error) {
      console.error(error);
    }
  }

  function getWSonSuccess(data) {
    if (data.length) {
      customWebSocketConnectionRealTime(
        data.map((item) => item.webSocketMethod),
        data[0].webSocketUrl
      );
    }
  }

  function customWebSocketConnectionRealTime(webSocketMethods, webSocketUrl) {
    if (!webSocketMethods.length || !webSocketUrl) return false;

    realTimeReadingsWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    webSocketMethods.forEach((method) => {
      realTimeReadingsWS.on(method, function (_data) {
        let data = JSON.parse(_data);
        formatWebSocketData(data);
      });
    });
    realTimeReadingsWS
      .start()
      .then(function () {
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueID + "_BasicParameter"
          )
          .catch(function (err) {
            return console.warn({
              signalrerr: err.toString(),
            });
          });
        realTimeReadingsWS
          .invoke(
            "JoinGroup",
            companyId + "_" + appId + "_" + uniqueID + "_DerivedParameter"
          )
          .catch(function (err) {
            return console.warn({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.warn("ws start error", e);
      });

    realTimeReadingsWS.onreconnected(function () {
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueID + "_BasicParameter"
        )
        .catch(function (err) {
          return console.warn({
            signalrerr: err.toString(),
          });
        });
      realTimeReadingsWS
        .invoke(
          "JoinGroup",
          companyId + "_" + appId + "_" + uniqueID + "_DerivedParameter"
        )
        .catch(function (err) {
          return console.warn({
            signalrerr: err.toString(),
          });
        });
    });
  }

  function validateWebSocketData(data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed) {
      return false;
    } else {
      return true;
    }
  }

  const checkIfToday = function () {
    return (
      selectedPeriod === "Today" ||
      (startDateInSeconds === moment().startOf("day").unix() &&
        endDateInSeconds === moment().endOf("day").unix())
    );
  };

  // Append the websocket data
  function formatWebSocketData(data) {
    if (!validateWebSocketData(data)) {
      console.warn(
        "WebSocket update failed, calling GetData API from DelMultiAxisChart!!!",
        data
      );
      fetchParameters();
      return;
    }

    console.log(
      "Web Socket update received in DelMultiAxisChart",
      data,
      chartDetails
    );

    if (checkIfToday()) {
      let sameAsPreviousTime = false;
      if (data?.parameters) {
        if (data.unixtime === xData.at(-1)) {
          sameAsPreviousTime = true;
        } else {
          xData.push(data.unixtime);
        }
        for (const element of dataSeries) {
          const match = data.parameters.find(
            (item) => item.parameterId === element.parameterId
          );
          if (sameAsPreviousTime && match) {
            element.data.pop();
          }
          if (match) {
            element.data.push(convertToTwoDigits(match.reading));
          } else if (!sameAsPreviousTime) {
            element.data.push(null);
          }
        }
        if (xData?.length) {
          isNoData = false;
        }
        updateSeries();
      }
    }
  }

  function formatTime(time, format) {
    let formattedTime;
    if (format) {
      formattedTime = moment.unix(time).format(format);
    } else {
      if (groupBy === "") {
        formattedTime = moment.unix(time).format(dateTimeFormat);
      } else {
        formattedTime = moment.unix(time).format(dateTimeFormatForSingleDay);
      }
    }
    return formattedTime;
  }

  function getXAxisTitle() {
    let title;
    if (groupBy === "") {
      title = xAxisTitleForSingleDay || xAxisTitle;
    } else {
      title = xAxisTitle;
    }
    return title;
  }

  function handlePeriod(period, clicked = true) {
    isButtonClicked = clicked;
    selectedPeriod = period;
    sessionStorage.setItem("multiaxischart-selected-timeperiod", period);
    switch (period) {
      case "Today":
        customStartDate = moment().startOf("day").valueOf();
        customEndDate = moment().endOf("day").valueOf();
        break;
      case "Yesterday":
        customStartDate = moment().subtract(1, "days").startOf("day").valueOf();
        customEndDate = moment().subtract(1, "days").endOf("day").valueOf();
        break;
      case "Current Week":
        customStartDate = moment().startOf("isoWeek").valueOf();
        customEndDate = moment().endOf("isoWeek").valueOf();
        break;
      case "Last Week":
        customStartDate = moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .valueOf();
        customEndDate = moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .valueOf();
        break;
      case "Current Month":
        customStartDate = moment().startOf("month").valueOf();
        customEndDate = moment().endOf("month").valueOf();
        break;
      case "Last Month":
        customStartDate = moment()
          .subtract(1, "months")
          .startOf("month")
          .valueOf();
        customEndDate = moment().subtract(1, "months").endOf("month").valueOf();
        break;
      default:
        selectedPeriod = "";
        customStartDate =
          sessionStorage.getItem("multiaxischart-saved-start-date") ?? null;
        customEndDate =
          sessionStorage.getItem("multiaxischart-saved-end-date") ?? null;
        break;
    }
  }

  function convertToTwoDigits(value) {
    if (isNaN(value)) return null;
    const decimalPoints = decimalPointsToShow || 2;
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(decimalPoints)
      : value;
  }

  function onDateChange(date) {
    if (date) {
      startDate = date.dayDefinedStartDateInSeconds;
      endDate = date.dayDefinedEndDateInSeconds;

      startDateInSeconds = date.startDateInSeconds;
      endDateInSeconds = date.endDateInSeconds;

      customStartDate = null;
      customEndDate = null;

      dateFromDatePicker = date;

      if (!isButtonClicked) {
        selectedPeriod = "";
        sessionStorage.setItem(
          "multiaxischart-selected-timeperiod",
          selectedPeriod
        );
      }
      isButtonClicked = false;
      sessionStorage.setItem("multiaxischart-saved-start-date", date.start);
      sessionStorage.setItem("multiaxischart-saved-end-date", date.end);
      if (endDate - startDate > 86400) {
        groupBy = "day";
      } else groupBy = "";
    }
  }

  function findChartType() {
    let flag = "scatter";
    for (let i = 0; i < dataSeries.length; i++) {
      if (dataSeries[i].data.length > 1) {
        flag = "line";
        break;
      }
    }
    return flag;
  }

  const getFileName = function () {
    const format = "DD-MM-YYYY";
    const start = dateFromDatePicker?.startDateInSeconds;
    const end = dateFromDatePicker?.endDateInSeconds;
    const formattedStartTime = formatTime(start, format);
    const formattedEndTime = formatTime(end, format);
    const fileName = downloadFileName
      ? downloadFileName
      : title
        ? title
        : "Chart";
    const nodeName = nodeDetails?.name ?? "";
    if (groupBy === "") {
      return `${fileName} of ${nodeName} on ${formattedStartTime}`;
    }
    return `${fileName} of ${nodeName} from ${formattedStartTime} to ${formattedEndTime}`;
  };

  function updateSeries() {
    if (chart) {
      const options = {
        series: dataSeries,
        chart: {
          type: findChartType(),
          toolbar: {
            export: {
              csv: {
                filename: getFileName(),
              },
              svg: {
                filename: getFileName(),
              },
              png: {
                filename: getFileName(),
              },
            },
          },
        },
        markers: {
          size: findChartType() === "scatter" ? 6 : 0,
          hover: {
            size: 6,
          },
        },
        yaxis: yData,
        labels: xData,
        xaxis: {
          title: {
            text: getXAxisTitle(),
          },
          labels: {
            formatter: function (value) {
              return value ? formatTime(value) : "";
            },
          },
        },
      };
      chart.updateOptions(options);
    }
  }

  function renderChart() {
    if (chartContainer) {
      const options = {
        chart: {
          height: chartHeight,
          type: findChartType(),
          stacked: false,
          toolbar: {
            export: {
              csv: {
                filename: getFileName(),
              },
              svg: {
                filename: getFileName(),
              },
              png: {
                filename: getFileName(),
              },
            },
          },
        },
        markers: {
          size: findChartType() === "scatter" ? 6 : 0,
          hover: {
            size: 6,
          },
        },
        colors: chartColors,
        series: dataSeries,
        stroke: {
          width: 1.5,
          curve: "straight",
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
          },
        },
        labels: xData,
        xaxis: {
          type: "category",
          tooltip: {
            enabled: false,
          },
          tickPlacement: "on",
          title: {
            text: getXAxisTitle(),
            style: {
              fontSize: "14px",
              fontFamily: "Roboto, Arial, sans-serif",
              fontWeight: 600,
            },
          },
          labels: {
            formatter: function (value) {
              return value ? formatTime(value) : "";
            },
          },
        },
        yaxis: yData,
        tooltip: {
          show: true,
          intersect: false,
          shared: true,
        },
        legend: {
          horizontalAlign: "center",
          offsetX: legendOffSetX,
          itemMargin: {
            horizontal: 5,
            vertical: 16,
          },
        },
      };

      chart = new ApexCharts(chartContainer, options);
      chart.render();
    }
  }
</script>

<div class="del-multi-axis-container">
  {#if isDataAwaiting}
    <Loader />
  {/if}
  <div class="header-div">
    <div class="title-div">
      {title}
    </div>
    <div class="date-div">
      <div class="date-picker-div">
        <DelDatePicker
          startTimeOffSet={startTimeOffset}
          endTimeOffSet={endTimeOffset}
          onDateChanged={onDateChange}
          defaultStartDate={customStartDate}
          defaultEndDate={customEndDate}
          autoApply={true}
          format={"DD-MM-YYYY"}
          firstDay={1}
        />
      </div>

      <div class="period-selector-div">
        <button
          class={`period-selector-button ${
            selectedPeriod === "Today" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Today");
          }}>Today</button
        >
        <button
          class={`period-selector-button ${
            selectedPeriod === "Yesterday" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Yesterday");
          }}>Yesterday</button
        >
        <button
          class={`period-selector-button ${
            selectedPeriod === "Current Week" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Current Week");
          }}>Current Week</button
        >
        <button
          class={`period-selector-button ${
            selectedPeriod === "Last Week" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Last Week");
          }}>Last Week</button
        >
        <button
          class={`period-selector-button ${
            selectedPeriod === "Current Month" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Current Month");
          }}>Current Month</button
        >
        <button
          class={`period-selector-button ${
            selectedPeriod === "Last Month" ? "selected" : ""
          }`}
          on:click={() => {
            handlePeriod("Last Month");
          }}>Last Month</button
        >
      </div>
    </div>
  </div>

  <div class="body-div">
    {#if isNoData && !isDataAwaiting}
      <div class="no-data">No Data Available</div>
    {/if}
    <div bind:this={chartContainer} />
  </div>
</div>

<style>
  .del-multi-axis-container {
    position: relative;
    padding: 0 16px;
  }
  .body-div {
    position: relative;
  }

  .title-div {
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding: 10px 0;
  }

  .date-div {
    display: flex;
    justify-content: space-between;
    min-height: 60px;
    flex-wrap: wrap;
  }

  .date-picker-div {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }

  .period-selector-div {
    display: flex;
    gap: 5px;
  }

  .period-selector-button {
    cursor: pointer;
    border: 1px solid #ccd3e7;
    border-radius: 6px;
    font: normal normal 600 14px/19px Roboto;
    color: #222222;
    background-color: white;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    gap: 10px;
    transition: all 0.5s;
    height: 30px;
  }

  .period-selector-button.selected {
    background-color: #16558f;
    border: 1px solid #16558f;
    color: white;
  }

  .no-data {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    font: italic normal normal 16px/21px Roboto;
  }
</style>
