<script>
  import { onMount, onDestroy } from "svelte";
  import DelAbout from "./../components/DelAbout/DelAbout.svelte";
  import Icon from "@iconify/svelte";
  export let showModal = false;

  let showAboutInfo = false;
  let aboutButtonElement = null;

  const closeDropDown = function (event) {
    if (
      showAboutInfo &&
      !(
        event.target === aboutButtonElement ||
        aboutButtonElement?.contains(event.target)
      )
    ) {
      showAboutInfo = false;
    }
  };

  onMount(() => {
    window.addEventListener("click", closeDropDown);
  });

  onDestroy(() => {
    window.removeEventListener("click", closeDropDown);
  });

  const onClickAboutButton = function () {
    showAboutInfo = !showAboutInfo;
  };
</script>

<div>
  <button
    class="del-nav-item del-button {showAboutInfo ? 'active' : ''}"
    id="about-button"
    on:click={onClickAboutButton}
    bind:this={aboutButtonElement}
    ><div>
      <div class="del-avt-nav-item-bottom-icon">
        <Icon icon="bi:info-circle-fill" />
      </div>
      <div>About</div>
    </div></button
  >
  {#if showAboutInfo && !showModal}
    <div class="del-about-container">
      <div class="del-about-dropdown-container">
        <DelAbout></DelAbout>
      </div>
    </div>
  {/if}
  {#if showAboutInfo && showModal}
    <div class="overlay-container"></div>
    <div class="del-rail-iot-about-modal">
      <div class="del-rail-iot-about-modal-header">
        <div class="del-rail-iot-about-modal-header-title">About</div>
        <div class="del-rail-iot-modal-buttons-container">
          <button
            class="del-rail-iot-close-btn"
            on:click={(e) => {
              showAboutInfo = false;
            }}
          >
            <Icon icon="mi:close" />
          </button>
        </div>
      </div>
      <div class="del-rail-iot-about-modal-content-container">
        <DelAbout insideModal={true}></DelAbout>
      </div>
    </div>
  {/if}
</div>

<style>
  .del-about-container {
    position: relative;
  }
  #about-button {
    vertical-align: top;
  }
  #about-button.active {
    color: #1f419b;
    font-weight: 600;
  }
  #about-button:hover {
    color: #1f419b;
  }
  .del-about-dropdown-container {
    position: absolute;
    /* z-index: 10; */
    top: 2px;
    right: 0;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    width: 370px;
    min-height: 50px;
    z-index: 2147483648;
  }
  .del-avt-nav-item-bottom-icon {
    text-align: center;
  }
  @media screen and (min-width: 600px) {
    .del-avt-nav-item-bottom-icon {
      display: none;
    }
  }

  /* For Modal */

  .overlay-container {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  .del-rail-iot-about-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 60px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 100px;
    top: 15%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-rail-iot-about-modal-header {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: baseline;
    text-align: left;
    font: normal normal bold 16px Roboto;
    color: #778388;
  }

  .del-rail-iot-about-modal-content-container {
    font: normal normal bold 15px Roboto;
    color: #000000;
    padding: 15px 0;
  }

  @media screen and (min-width: 700px) {
    .del-rail-iot-about-modal {
      width: 400px;
      left: calc(50% - 200px);
      top: 15%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-rail-iot-about-modal {
      width: 58%;
      left: calc(50% - 29%);
      top: 15%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-rail-iot-about-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-rail-iot-about-modal {
      width: 60%;
      left: calc(50% - 30%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-rail-iot-about-modal {
      top: 15%;
    }
  }

  /* End of modal styles */
</style>
