<script>
  import { Router, Route, Link } from "svelte-routing";
  import Dashboard from "./routes/Dashboard.svelte";
  import Reports from "./routes/Reports.svelte";
  import Events from "./routes/Events.svelte";
  import About from "./routes/About.svelte";
  import Error from "./routes/Error.svelte";

  import { onMount, onDestroy } from "svelte";
  import {
    selectedNode,
    defaultNodeId,
    allocatedScreenNames,
    isPageTitleDelpheon,
    companyName,
    applicationId,
    companyId,
    applicationName,
    applicationsList,
    userType,
    roleName,
    userId,
    userName,
    realmName,
    selectedDate,
    currentPage,
    companyDetails,
    startTimeOffSet,
    endTimeOffSet,
    parentNodeType,
    childNodeType,
    applicationError,
    parentStationNodeType,
    isReportGenerated,
    rootNode,
  } from "./stores.js";
  import { getDomainURL, invokeAPI, getDeployedURL } from "./invokeAPI.js";
  import getLocalStorageValues from "./getLocalStorageValues";
  import DelNavigationTree from "./components/DelNavigationTree/DelNavigationTree.svelte";
  import DelDatePicker from "./components/DelDatePicker/DelDatePicker.svelte";
  import {
    pagesWithDatePicker,
    screensToShow,
    pathNameSSPA,
  } from "./../config";
  import { ENVIRONMENT } from "./../environment";
  import moment from "moment";
  import Icon from "@iconify/svelte";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";
  import * as signalR from "@microsoft/signalr";

  const IS_STANDALONE = window.singleSpaNavigate ? false : true;

  let defaultStartDate = sessionStorage.getItem("saved-start-date")
    ? sessionStorage.getItem("saved-start-date")
    : new Date(moment().subtract(6, "day").startOf("day"));
  let defaultEndDate = sessionStorage.getItem("saved-end-date")
    ? sessionStorage.getItem("saved-end-date")
    : new Date(moment().endOf("day"));
  defaultNodeId.set(sessionStorage.getItem("saved-node-id") ?? null);

  let domainURL = getDomainURL();
  let initialScreenPath = "";
  const pathPrefix = ENVIRONMENT === "preview" ? "" : pathNameSSPA;

  // For Alerts notification
  let beat = new Audio(`${getDeployedURL()}/audios/ding-ding-sound-effect.mp3`);
  let showAlertDialog = false;
  let nodeChildren = [];
  let isTriggeredByWSU = false;
  let alertsList = [];
  let alertWSMain = null;

  const checkUserInteraction = function () {
    const navigationType =
      window.performance.getEntriesByType("navigation")[0].type;
    console.log("Navigation type : ", navigationType);
    const isPageReload = navigationType === "reload";
    const isNavigation = navigationType === "navigate";
    if (isPageReload) {
      showAlertDialog = true;
    } else if (isNavigation) {
      const currentUrl = new URL(window.location.origin);
      if (document.referrer) {
        const fromUrl = new URL(document.referrer);
        console.log("URLs", currentUrl, fromUrl);
        if (currentUrl && fromUrl && currentUrl.hostname !== fromUrl.hostname) {
          showAlertDialog = true;
        }
      } else {
        showAlertDialog = true;
      }
    }
  };

  const getChildNodes = function (hierarchy) {
    hierarchy.forEach((node) => {
      nodeChildren.push({
        id: node.id,
        name: node.name,
      });
      if (node.children.length) {
        getChildNodes(node.children);
      }
    });
  };

  const invokeAPIWithoutAncestors = function () {
    const onAPIWithoutAncestorsSuccess = function (data) {
      if (data && data.length) {
        getChildNodes(data);
      }
    };
    const userId = localStorage.getItem("sub");
    const endpoint = `twin/api/user/${userId}/nodes`;
    invokeAPI(
      "GET",
      endpoint,
      onAPIWithoutAncestorsSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null
    );
  };

  const validateWebSocketData = function (data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed || data.InvokeAPI) {
      return false;
    } else if (data.InvokeSubscription) {
      return false;
    } else {
      return true;
    }
  };

  const invokeGetAlertsAPI = function (nodeId) {
    const endpoint = `alert/api/Configuration/${
      nodeId ?? $rootNode?.id
    }/events/details?includeAllAlerts=true&includeDisabledAlerts=true&includeChildNodes=true`;
    invokeAPI(
      "GET",
      endpoint,
      getAlertsonSuccess,
      () => {
        sessionStorage.removeItem("application-initial-load");
      },
      null,
      screensToShow[$currentPage]
        ? `${screensToShow[$currentPage].screen}/R`
        : null
    );
  };

  const findNewItems = function (oldData, newData) {
    console.log("In FindNewItems", oldData, newData);
    let newItems = [];
    // Find new items that were not present in old list
    for (let item of newData) {
      const match = oldData.find((event) => event.id === item.id);
      if (!match) {
        newItems.push(item);
      }
    }
    console.log("New Events filtered", newItems);
    return newItems;
  };

  const formatTimeStamp = function (timestamp) {
    if (timestamp) {
      return `at ${moment(new Date(parseInt(timestamp) * 1000)).format(
        "DD-MM-YY HH:mm:ss"
      )}`;
    }
    return "";
  };

  const notifyUserAboutAlert = function (alertsList, nodesMap) {
    if (alertsList && alertsList.length) {
      for (const item of alertsList) {
        if (nodesMap[item.nodeId]) {
          Toastify({
            text: `Alert : ${item.alertname} triggered in ${
              nodesMap[item.nodeId]
            } ${formatTimeStamp(item.createdDate)}`,
            style: {
              background:
                item.category === "Critical"
                  ? "red"
                  : item.category === "Warning"
                    ? "orange"
                    : "green",
            },
            duration: -1,
            close: true,
            offset: {
              y: 100,
            },
          }).showToast();
          if (item.enableAudio && beat) {
            const resp = beat.play();
            if (resp !== undefined) {
              resp
                .then((_) => {
                  console.log("Sound played");
                })
                .catch((error) => {
                  showAlertDialog = true;
                  console.error("Error playing sound:", error);
                });
            }
          } else {
            console.warn(
              "Audio playing disabled for item or no audio file configured",
              item
            );
          }
        } else {
          console.warn(item.nodeName, " is not assigned to this user");
        }
      }
    }
  };

  const getAlertsonSuccess = function (data) {
    if (data) {
      let newData = data.filter((item) => item.currentstatus);

      const isInitialLoad = sessionStorage.getItem("application-initial-load");
      if (isTriggeredByWSU || isInitialLoad) {
        const newItems = findNewItems(alertsList, newData);

        // Sort in ascending order of created time, so that toasts will be arranged in descending order
        newItems.sort(function (a, b) {
          if (parseInt(b.createdDate) - parseInt(a.createdDate) === 0) {
            if (a.alertname.toLowerCase() < b.alertname.toLowerCase()) return 0;
            if (a.alertname.toLowerCase() > b.alertname.toLowerCase()) return 1;
            return -1;
          } else return parseInt(a.createdDate) - parseInt(b.createdDate);
        });
        const nodesMap = {};
        for (const item of nodeChildren) {
          nodesMap[item.id] = item.name;
        }
        notifyUserAboutAlert(newItems, nodesMap);
      }
      sessionStorage.removeItem("application-initial-load");
      isTriggeredByWSU = false;
      alertsList = [...newData];

      //Stopping existing Websocket API and calling new one
      if (alertWSMain) {
        alertWSMain.stop();
      }

      const payload = data.map((item) => ({
        parameterId: item.id,
        type: "AlertList",
        uniqueId: "AlertsWSU" + "Notify",
      }));
      getAlertWSUpdates(payload);
    }
  };

  const getAlertWSUpdates = function (payload) {
    const endpoint = "realtimedata/api/Parameters/AlertList/live";
    if (payload?.length) {
      invokeAPI(
        "POST",
        endpoint,
        getAlertWSUpdatesonSuccess,
        null,
        payload,
        screensToShow[$currentPage]
          ? `${screensToShow[$currentPage].screen}/R`
          : null
      );
    }
  };

  const getAlertWSUpdatesonSuccess = function (data) {
    if (alertWSMain) alertWSMain.stop();
    customWebSocketConnectionAlerts(data.webSocketMethod, data.webSocketUrl);
  };

  const customWebSocketConnectionAlerts = function (
    webSocketMethod,
    webSocketUrl
  ) {
    const applicationId = sessionStorage.getItem("appId");
    const companyId = localStorage.getItem("companyId");
    if (!webSocketMethod || !webSocketUrl) return false;
    alertWSMain = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    alertWSMain.on(webSocketMethod, function (_data) {
      let data = JSON.parse(_data);
      updateAlertList(data);
    });

    alertWSMain
      .start()
      .then(function () {
        alertWSMain
          .invoke(
            "JoinGroup",
            companyId +
              "_" +
              applicationId +
              "_" +
              "AlertsWSU" +
              "Notify" +
              "_Alert"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    alertWSMain.onreconnected(function () {
      alertWSMain
        .invoke(
          "JoinGroup",
          companyId +
            "_" +
            applicationId +
            "_" +
            "AlertsWSU" +
            "Notify" +
            "_Alert"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  };

  const updateAlertList = function (data) {
    console.log("Alert List Web Socket Update for Notification", data);
    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      console.error(
        "No access token in localstorage, you are logged out, closing websocket connection",
        accessToken
      );
      if (alertWSMain) {
        alertWSMain.stop();
      }
      return;
    }

    if (!validateWebSocketData(data)) {
      console.warn(
        "Web Socket Update failed, calling getAlerts API from delRailwayIoT Prefab!!!"
      );
      if (alertWSMain) {
        alertWSMain.stop();
      }
      invokeGetAlertsAPI();
      isTriggeredByWSU = true;
      return;
    }
    if (data && nodeChildren) {
      const nodesMap = {};
      for (const item of nodeChildren) {
        nodesMap[item.id] = item.name;
      }
      const _alertsList = data.filter(
        (item) =>
          item.currentstatus === "Active" &&
          Object.keys(nodesMap).includes(item.nodeId)
      );
      const newItems = findNewItems(alertsList, _alertsList);
      for (const item of newItems) {
        alertsList.push(item);
      }
      console.log("Nodes Map", nodesMap);
      notifyUserAboutAlert(newItems, nodesMap);
    }
  };

  const getAudioFile = function () {
    const endpoint = "alert/api/file/getaudiofile";
    invokeAPI(
      "GET",
      endpoint,
      getAudioFileonSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null
    );
  };

  const getAudioFileonSuccess = function (data) {
    console.log("getAudioFileonSuccess", data);
    if (data && data.audio) {
      updateAudioFile(data.audio);
    }
  };

  const updateAudioFile = function (base64String) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: "audio/mp3",
    });
    if (beat) {
      beat.src = URL.createObjectURL(blob);
    } else {
      beat = new Audio(URL.createObjectURL(blob));
    }
  };

  const checkIfUserHasEventScreenAccess = function () {
    if (localStorage.getItem("applicationList")) {
      const applicationList = JSON.parse(
        localStorage.getItem("applicationList")
      );
      const applicationId = sessionStorage.getItem("appId");
      let allocatedScreenNames = [];
      const match = applicationList.find(
        (item) => item.ApplicationId === applicationId
      );
      if (match && match.Screens) {
        allocatedScreenNames = match.Screens.map((item) => item.Name);
      }
      if (allocatedScreenNames.includes("Events")) {
        return true;
      }
    }
    return false;
  };

  // End of Alerts notification related code

  const invokeGetCompanyDetailsAPI = function () {
    const endpoint = `twin/api/Hierarchy/${$companyName}/${$applicationName}`;
    const companyDetailsAPIOnSuccess = function (data) {
      companyDetails.set(data);
      startTimeOffSet.set(data?.StartTimeOffset ?? 0);
      endTimeOffSet.set(data?.EndTimeOffset ?? 0);
      parentNodeType.set(data?.metadata?.length ? data.metadata[0].type : "");
      parentStationNodeType.set(
        data?.metadata?.length > 2 ? data.metadata[2].type : ""
      );
      childNodeType.set(
        data?.metadata?.length > 3 ? data.metadata[3].type : ""
      );
      localStorage.setItem("startTimeOffset", $startTimeOffSet);
      localStorage.setItem("endTimeOffset", $endTimeOffSet);
    };

    invokeAPI(
      "GET",
      endpoint,
      companyDetailsAPIOnSuccess,
      null,
      null,
      screensToShow[initialScreenPath]
        ? `${screensToShow[initialScreenPath].screen}/R`
        : null
    );
  };

  const onURLChanged = function () {
    console.log("URL changed:", window.location.pathname);
    const currentPath = window.location.pathname;

    let newPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    newPath = newPath || "/";
    handleLinkClick(newPath);
  };

  onMount(() => {
    const currentPath = window.location.pathname;

    const localStorageValues = getLocalStorageValues();
    console.log("Values from local storage", localStorageValues);

    applicationId.set(localStorageValues.applicationId);
    applicationName.set(localStorageValues.applicationName);
    applicationsList.set(localStorageValues.applicationsList);
    companyId.set(localStorageValues.companyId);
    companyName.set(localStorageValues.companyName);
    isPageTitleDelpheon.set(localStorageValues.isPageTitleDelpheon);
    realmName.set(localStorageValues.realmName);
    userId.set(localStorageValues.userId);
    userName.set(localStorageValues.userName);
    userType.set(localStorageValues.userType);

    window.addEventListener("popstate", onURLChanged);

    initialScreenPath =
      ENVIRONMENT === "preview"
        ? currentPath
        : currentPath.replace(pathNameSSPA, "");
    initialScreenPath = initialScreenPath || "/";

    currentPage.set(initialScreenPath);
    getAudioFile();
    invokeGetCompanyDetailsAPI();
    invokeAPIWithoutAncestors();

    if (initialScreenPath === "/") {
      const firstLinkToHome = document.querySelector(
        `a[href="${pathNameSSPA}/"]`
      );
      if (firstLinkToHome) {
        firstLinkToHome?.click();
      }
    } else if (initialScreenPath === "/Events") {
      const urlParams = new URLSearchParams(window.location.search);
      const nodeIdFromURL = urlParams.get("nodeId");

      if (nodeIdFromURL) {
        defaultNodeId.set(nodeIdFromURL);
        const linkToEvents = document.querySelector(
          `a[href="${pathNameSSPA}/Events"]`
        );
        if (linkToEvents) {
          linkToEvents.click();
        }
      }
    }

    if (initialScreenPath !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (initialScreenPath !== "/Events") {
      clearEventsRelatedSessionItems();
    }
    if (initialScreenPath !== "/") {
      clearDashboardRelatedSessionItems();
    }

    if (checkIfUserHasEventScreenAccess()) {
      if (!sessionStorage.getItem("application-initial-load")) {
        checkUserInteraction();
      }
      alertsList = [];
      isTriggeredByWSU = false;
    }

    // For the responsive bottom nav bar

    // Hide the bottom navigation bar on scroll down, and show it on scroll up
    let prevScrollPos = window.pageYOffset;

    window.onscroll = function (e) {
      let currentScrollPos = window.pageYOffset;
      const scrollElement = document.getElementsByClassName(
        "del-rail-iot-bottom-navigation-bar"
      );
      if (scrollElement?.length) {
        if (prevScrollPos > currentScrollPos) {
          scrollElement[0].style.visibility = "visible";
        } else {
          scrollElement[0].style.visibility = "hidden";
        }
      }
      prevScrollPos = currentScrollPos;
    };
  });

  onDestroy(() => {
    if (alertWSMain) {
      alertWSMain.stop();
    }
    window.removeEventListener("popstate", onURLChanged);
  });

  const dateRangesToShow = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Current Week",
    "Last Week",
    "Current Month",
    "Last Month",
    "Current Year",
  ];

  const onDateChange = function (newDate) {
    selectedDate.set(newDate);
    sessionStorage.setItem("saved-start-date", newDate?.start);
    sessionStorage.setItem("saved-end-date", newDate?.end);
  };

  const onNavTreeNodeSelected = function (node) {
    if (node && $selectedNode?.id !== node.id) {
      selectedNode.set(node);
      sessionStorage.setItem("saved-node-id", node?.id);
    }
  };

  const checkIfScreenIsNotAllocated = function (screen) {
    if ($allocatedScreenNames?.includes(screen)) return false;
    return true;
  };

  const clearReportRelatedSessionItems = function () {
    sessionStorage.removeItem("report-to-be-shown");
    sessionStorage.removeItem("report-selected-start-time");
    sessionStorage.removeItem("report-selected-end-time");
    sessionStorage.removeItem("report-selected-options");
    sessionStorage.removeItem("report-selected-summary-options");
    sessionStorage.removeItem("report-default-selected-options");
    sessionStorage.removeItem("show-feeder-descriptions");
    isReportGenerated.set(false);
  };

  const clearEventsRelatedSessionItems = function () {
    sessionStorage.removeItem("del-event-viewer-responsive-checkbox-value");
    sessionStorage.removeItem("del-event-viewer-responsive-saved-categories");
  };

  const clearDashboardRelatedSessionItems = function () {
    sessionStorage.removeItem("multiaxischart-selected-timeperiod");
    sessionStorage.removeItem("multiaxischart-saved-start-date");
    sessionStorage.removeItem("multiaxischart-saved-end-date");
  };

  const handleLinkClick = function (screenName) {
    currentPage.set(screenName);
    if (screenName !== "/") {
      clearDashboardRelatedSessionItems();
    }
    if (screenName !== "/Reports") {
      clearReportRelatedSessionItems();
    }
    if (screenName !== "/Events") {
      clearEventsRelatedSessionItems();
    }
  };

  const onNoNodesAssigned = function () {
    applicationError.set({
      error: true,
      message:
        "No user nodes assigned for this user. Please contact your admin.",
      disable: true,
    });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify($applicationError)
    );
    if ($currentPage !== "/Error") window.location.href = `${pathPrefix}/Error`;
  };

  const onNavTreeAPISucess = function (data) {
    applicationError.set({ error: false });
    sessionStorage.setItem(
      "application-error",
      JSON.stringify({ error: false })
    );
    if (data?.length) {
      rootNode.set(data[0]);
    }
    invokeGetAlertsAPI(data[0].id);
  };
  const onNavTreeAPIError = function () {
    onNoNodesAssigned();
  };
</script>

<svelte:head>
  <title>{$isPageTitleDelpheon === "true" ? "DelphEon" : $companyName}</title>
  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link
    href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
    rel="stylesheet"
  />
</svelte:head>

<div class="del-application-container" id="del-application-container">
  <div class="del-navigation-bar">
    <div class="del-nav-bar-left-container">
      <div class="del-application-name">{$applicationName ?? ""}</div>
    </div>
    <div class="del-nav-item-container del-navitems-container-large-screens">
      <Router>
        {#each Object.keys(screensToShow) as screenName (screenName)}
          {#if screenName !== "/Error"}
            <Link
              to={`${pathPrefix}${screenName}`}
              class="del-nav-item {checkIfScreenIsNotAllocated(
                screensToShow[screenName].screen
              ) || $applicationError?.error
                ? 'disabled'
                : ''} {$currentPage === screenName
                ? 'del-active-nav-item'
                : ''} {screenName === '/Reports'
                ? 'del-railiot-nav-bar-large-screen-item'
                : ''}"
              on:click={() => handleLinkClick(screenName)}
              >{screensToShow[screenName].displayName}</Link
            >
          {/if}
        {/each}
      </Router>
      <About />
    </div>
  </div>
  <div class="del-page-nav-bar">
    <div class="del-nav-tree-container">
      <div class="del-nav-tree">
        <DelNavigationTree
          onNodeSelected={onNavTreeNodeSelected}
          baseURL={domainURL}
          defaultNodeId={$defaultNodeId}
          currentScreen={screensToShow[initialScreenPath]?.screen ?? null}
          {onNoNodesAssigned}
          onAPISuccess={onNavTreeAPISucess}
          onAPIFailed={onNavTreeAPIError}
          withAncestors={true}
          userId={$userId}
          isDisabled={$isReportGenerated}
          disabledIfNotAssignedToUser={$currentPage === "/" ||
          $userType === "companyAdmin" ||
          $roleName === "Application Admin"
            ? false
            : true}
        />
      </div>
      {#if pagesWithDatePicker.includes(screensToShow[$currentPage]?.displayName)}
        <div class="del-date-picker-container">
          <DelDatePicker
            customRangesToShow={dateRangesToShow}
            onDateChanged={onDateChange}
            autoApply={true}
            zIndex={11}
            {defaultStartDate}
            {defaultEndDate}
            isDisabled={$isReportGenerated}
            startTimeOffSet={$startTimeOffSet}
            endTimeOffSet={$endTimeOffSet}
          />
        </div>
      {/if}
    </div>
    <div class="del-node-name">{$selectedNode?.path ?? ""}</div>
  </div>
  <div>
    {#if showAlertDialog}
      <div
        class="overlay-container"
        on:click={() => {
          showAlertDialog = false;
        }}
        on:keypress={() => {
          showAlertDialog = false;
        }}
      />
      <div class="del-rail-iot-alert-modal">
        <!-- <div class="del-rail-iot-alert-modal-header">
          <div class="del-rail-iot-alert-modal-title">
            User Interaction Required
          </div>
        </div> -->
        <div class="del-rail-iot-alert-modal-content-container">
          Need user interaction to play audio!
        </div>
        <div class="del-rail-iot-alert-modal-buttons-container">
          <button
            class="del-rail-iot-alert-modal-submit-button"
            id="del-rail-iot-alert-modal-submit-button"
            on:click={() => {
              showAlertDialog = false;
            }}>Ok</button
          >
        </div>
      </div>
    {/if}
  </div>

  <div class="del-rail-iot-bottom-navigation-bar">
    <div class="del-navitems-container del-railiot-nav-item-bottom-container">
      <Router>
        {#each Object.keys(screensToShow) as screenName (screenName)}
          {#if screenName !== "/Error" && screenName !== "/Reports"}
            <Link
              to={`${pathPrefix}${screenName}`}
              class="del-nav-item  {checkIfScreenIsNotAllocated(
                screensToShow[screenName].screen
              ) || $applicationError?.error
                ? 'disabled'
                : ''} {$currentPage === screenName
                ? 'del-active-nav-item'
                : ''}"
              on:click={() => handleLinkClick(screenName)}
              ><div>
                <div class="del-railiot-nav-item-bottom-icon">
                  {#if screenName === "/"}
                    <Icon icon="ion:home" />
                  {:else if screenName === "/Events"}
                    <Icon icon="fa6-solid:bell" />
                  {/if}
                </div>
                <div>{screensToShow[screenName].displayName}</div>
              </div></Link
            >
          {/if}
        {/each}
      </Router>
      <About showModal={true} />
    </div>
  </div>

  <Router basepath={IS_STANDALONE ? "/" : pathNameSSPA}>
    <Route path="/" component={Dashboard} />
    <Route path="/Reports" component={Reports} />
    <Route path="/Events" component={Events} />
    <Route path="/Error" component={Error} />
  </Router>
</div>

<style>
  :global(body) {
    margin: 0 auto;
    padding: 0;
    background-color: #f0eff7;
    font-family: "Roboto", sans-serif;
  }
  :global(.del-application-container) {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    background-color: #f0eff7;
    box-sizing: border-box;
  }
  .del-navigation-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 16px 28px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 8px #0000001f;
    opacity: 1;
    border-top: 1px solid #ccd3e7;
  }

  .del-application-name {
    color: #504f4f;
    font: normal normal 700 17px Roboto;
  }

  .del-nav-bar-left-container {
    display: flex;
    align-items: center;
    gap: 25px;
    flex-wrap: wrap;
  }

  .del-date-picker-container {
    align-self: center;
  }
  .del-nav-tree-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 1;
  }

  .del-node-name,
  .del-nav-tree {
    align-self: center;
  }

  .del-nav-tree {
    height: 40px;
  }

  :global(.tree-cascader-button:disabled) {
    opacity: 0.8;
  }

  .del-node-name {
    text-align: left;
    font: normal normal 700 18px Roboto;
    color: #fff;
    letter-spacing: 0px;
    opacity: 1;
  }

  .del-nav-item-container {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  :global(a.del-nav-item) {
    text-decoration: none;
    color: #585859;
  }

  :global(.del-nav-item:hover) {
    color: #1f419b;
  }
  :global(.del-nav-item) {
    text-align: left;
    font: normal normal 500 15px/20px Roboto;
    letter-spacing: 0px;
    color: #585859;
    opacity: 1;
    cursor: pointer;
    text-decoration: none;
  }

  :global(.del-nav-item.disabled) {
    cursor: not-allowed;
    pointer-events: none;
    color: #59585875;
  }

  :global(a.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #1f419b;
    opacity: 1;
  }

  :global(.del-active-nav-item) {
    text-align: left;
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #1f419b;
    opacity: 1;
  }

  :global(.del-button) {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  :global(.del-button:hover),
  :global(.del-button:focus),
  :global(.del-button:active) {
    background-color: transparent;
  }

  :global(.del-page-container) {
    padding-bottom: 16px;
  }

  :global(.del-container) {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 20px;
    box-shadow: 5px 1px 7px #00000014;
  }

  /* Styles for navigation bar */

  .del-page-nav-bar {
    background: transparent linear-gradient(90deg, #687fc3 0%, #f5b785 100%) 0%
      0% no-repeat padding-box;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-height: 50px;
    text-align: center;
    font: normal normal 400 18px Roboto;
    color: #fff;
    padding: 10px 16px;
  }

  .del-page-nav-bar:after {
    content: "";
    flex: 1;
  }

  .del-navigation-bar {
    background-color: #fff;
  }

  /* Style for top navigation bar */
  .del-navitems-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .del-navitems-container {
    gap: 10px;
  }

  /* Style for bottom navigation bar */

  .del-rail-iot-bottom-navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    display: none;
    justify-content: center;
    align-items: center;
    visibility: visible;
    border-radius: 15px 15px 0 0;
    transition: visibility 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(0 0 0 / 20%);
    z-index: 250;
  }

  .del-railiot-nav-item-bottom-icon {
    text-align: center;
  }

  .del-navitems-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .del-navitems-container {
    gap: 10px;
  }

  .del-railiot-nav-item-bottom-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }

  /* For responsiveness */

  @media screen and (max-width: 900px) {
    :global(a.del-railiot-nav-bar-large-screen-item) {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .del-rail-iot-bottom-navigation-bar {
      display: flex;
    }
    .del-navitems-container-large-screens {
      display: none;
    }
  }

  /* End of Navigation Bar Styles*/

  /* For Modal */

  .overlay-container {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  .del-rail-iot-alert-modal {
    position: fixed;
    display: block;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2147483648;
    max-width: calc(100% - 60px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 100px;
    top: 15%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  /* .del-rail-iot-alert-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px Roboto;
    color: #2c3049;
  } */

  .del-rail-iot-alert-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
    margin-top: 10px;
  }

  .del-rail-iot-alert-modal-content-container {
    font: normal normal bold 15px Roboto;
    color: #6f767f;
    padding: 15px 0;
    padding-top: 20px;
  }

  button.del-rail-iot-alert-modal-submit-button {
    color: #fff;
    background-color: #2c3049;
    border-radius: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-rail-iot-alert-modal-submit-button:enabled:hover {
    background: #222639;
  }

  @media screen and (min-width: 700px) {
    .del-rail-iot-alert-modal {
      width: 300px;
      left: calc(50% - 150px);
      top: 15%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-rail-iot-alert-modal {
      width: 50%;
      left: calc(50% - 25%);
      top: 15%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-rail-iot-alert-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-rail-iot-alert-modal {
      width: 56%;
      left: calc(50% - 28%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-rail-iot-alert-modal {
      top: 15%;
    }
  }

  /* End of modal styles */
</style>
