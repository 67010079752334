<script>
  import Loader from "./../Loader/Loader.svelte";
  import DelSelect from "./../DelSelect/DelSelect.svelte";
  import * as signalR from "@microsoft/signalr";
  import Toastify from "toastify-js";
  import "toastify-js/src/toastify.css";
  import moment from "moment";
  import { onDestroy } from "svelte";

  export let nodeDetails = null;
  export let currentScreen = "Events";
  export let baseURL = null;
  export let columnDetails = {};
  export let isWritePermission = false;
  export let categoriesList = [];
  export let columnsToShow = [
    "alertName",
    "nodeName",
    "description",
    "category",
    "alertValue",
    "currentStatus",
    "createdDate",
    "acknowledgedDate",
    "isAcknowledged",
    "acknowledgedBy",
    "action",
    "disable",
  ];
  export let disableButtonLabel = null;
  export let deployedURL = "";
  export let uniqueId = "del-event-viewer-railiot";
  export let entriesPerPage = 5;
  export let noDataMessage = "";

  const getCheckBoxValue = function () {
    const oldValue = sessionStorage.getItem(
      "del-event-viewer-responsive-checkbox-value"
    );
    if (oldValue) {
      return JSON.parse(oldValue);
    }
    return true;
  };

  const getSelectedCategories = function () {
    const oldValue = sessionStorage.getItem(
      "del-event-viewer-responsive-saved-categories"
    );
    if (oldValue) {
      return JSON.parse(oldValue);
    }
    return categoriesList
      ? categoriesList.map((item) => item.value)
      : ["Critical", "Warning", "Informational"];
  };

  let showOverLay = false;
  let isAPIAwaiting = false;
  let currentPage = 1;
  let drawerOpened = false;
  let modalOpened = false;
  let allEventsList = [];
  let eventsTableData = [];
  let checkBoxValue = getCheckBoxValue();
  let defaultSelectedCategories = getSelectedCategories();
  let selectedEventCategories = [];
  let paginatedData = [];
  let selectedAlertItem = null;
  let commentTextArea = null;
  let actionLength = 0;
  let alertWS = null;
  let alertsGroupedByAppId = {};
  let childNodeIds = [];
  let showNoDataMessage = false;
  let isAPIReceived = false;

  $: totalPages = Math.ceil(eventsTableData?.length / entriesPerPage);

  $: {
    if (
      nodeDetails &&
      currentScreen &&
      baseURL &&
      (checkBoxValue || !checkBoxValue)
    ) {
      handleNodeChange();
    }
  }

  $: selectedNodeId = nodeDetails?.id ?? null;

  onDestroy(() => {
    alertWS?.stop();
  });

  const debounce = function (func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
  };

  const onNodeChange = function () {
    isAPIReceived = false;
    invokeGetAlertsAPI(checkBoxValue);
  };
  const handleNodeChange = debounce(onNodeChange);

  const convertToTwoDigits = function (value) {
    return (value + "").indexOf(".") > -1
      ? parseFloat(value).toFixed(2)
      : value;
  };

  const getAlertValueWithUnit = function (value, unit) {
    if (isNaN(value) || value === null) {
      return "";
    }
    if (unit) {
      return `${convertToTwoDigits(value)} ${unit}`;
    } else {
      return convertToTwoDigits(value);
    }
  };

  const formatTimeStamp = function (timestamp) {
    if (!timestamp || isNaN(timestamp)) return null;

    const formattedTime = moment(new Date(timestamp * 1000)).format("HH:mm:ss");
    const formattedDate = moment(new Date(timestamp * 1000)).format(
      "DD-MMM-YYYY"
    );

    return `<span class="del-events-viewer-table-time">${formattedTime}</span><span class="del-events-viewer-table-date">${formattedDate}</span>`;
  };

  const groupByCategory = function (items, groupByItemName) {
    const groupByCategory = items.reduce((group, item) => {
      const categoryName = item[groupByItemName];
      group[categoryName] = group[categoryName] ? group[categoryName] : [];
      group[categoryName].push(item);
      return group;
    }, {});
    return groupByCategory;
  };

  const updatePagination = function () {
    if (!eventsTableData?.length) {
      currentPage = 1;
    }
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    paginatedData = eventsTableData.slice(startIndex, endIndex);
  };

  const handleFirstPageClick = function () {
    currentPage = 1;
    updatePagination();
  };
  const handleLastPageClick = function () {
    currentPage = totalPages;
    updatePagination();
  };
  const handleNextPageClick = function () {
    currentPage++;
    updatePagination();
  };
  const handlePreviousPageClick = function () {
    currentPage--;
    updatePagination();
  };

  const onEyeIconClicked = function (row) {
    selectedAlertItem = { ...row };
    showOverLay = true;
    drawerOpened = true;
  };

  const onModalClosed = function () {
    modalOpened = false;
    if (!drawerOpened) {
      showOverLay = false;
      selectedAlertItem = null;
    }
    actionLength = 0;
  };

  const onOverLayClicked = function () {
    modalOpened = false;
    drawerOpened = false;
    onModalClosed();
  };

  const updateCharCount = function () {
    actionLength = commentTextArea?.value?.trim().length ?? 0;
  };

  const filterEventList = function () {
    eventsTableData = allEventsList.filter(
      (item) =>
        item.currentstatus === "Active" &&
        selectedEventCategories.includes(item.category)
    );

    const totalPages = Math.ceil(eventsTableData?.length / entriesPerPage);
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    if (totalPages) {
      showNoDataMessage = false;
    } else if (isAPIReceived) {
      showNoDataMessage = true;
    }
    updatePagination();
  };

  const invokeAPI = function (
    method,
    endpoint,
    onsuccess,
    onerror,
    payload,
    accessOrigin,
    extraHeaders = {},
    type = "platform"
  ) {
    async function getResponse() {
      let headers = {};
      const companyId = localStorage.getItem("companyId");
      const applicationId = sessionStorage.getItem("appId");
      const accessToken = localStorage.getItem("access_token");
      if (!(accessToken && companyId && applicationId && accessOrigin)) return;
      switch (type) {
        case "platform":
          headers = {
            ...extraHeaders,
            companyid: companyId,
            applicationid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          break;
        case "analytics":
          headers = {
            ...extraHeaders,
            cid: companyId,
            aid: applicationId,
            "access-origin": accessOrigin,
            Authorization: `Bearer ${accessToken}`,
          };
          break;
        default:
          console.error("Wrong type of API");
          return;
      }
      let options = { method, headers };
      if (payload) {
        options.body = JSON.stringify(payload);
      }
      const response = await fetch(`${baseURL}/${endpoint}`, options);
      if (!response?.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const data = await response.json();
      return data;
    }

    getResponse()
      .then((data) => {
        onsuccess && onsuccess(data);
      })
      .catch((error) => {
        onerror && onerror(error);
        console.error("API call failed", error);
      });
  };

  const onGetAlertsOnSuccess = function (data) {
    isAPIAwaiting = false;
    isAPIReceived = true;
    //Stopping existing Websocket API and calling new one
    if (alertWS) alertWS.stop();

    const payloadForWSUpdates = data.map((item) => ({
      parameterId: item.id,
      type: "AlertList",
      uniqueId: uniqueId,
    }));
    getWSUForAlerts(payloadForWSUpdates);

    data = data.map((item) => {
      let newItem = {
        alertName: item.alertname,
        alertValue: getAlertValueWithUnit(item.alertvalue, item.unit),
        currentStatus: item.currentstatus,
        ...item,
      };
      return newItem;
    });

    //Group alerts on the basis of their application id
    alertsGroupedByAppId = groupByCategory(data, "triggerApplicationId");

    const allEventsFiltered = data.filter((item) => item.createdDate);

    //Sort in order of latest occured
    allEventsFiltered.sort(function (a, b) {
      if (parseInt(b.createdDate) - parseInt(a.createdDate) === 0) {
        if (a.alertname.toLowerCase() < b.alertname.toLowerCase()) return -1;
        if (a.alertname.toLowerCase() > b.alertname.toLowerCase()) return 1;
        return 0;
      } else return parseInt(b.createdDate) - parseInt(a.createdDate);
    });
    allEventsList = [...allEventsFiltered];

    eventsTableData = allEventsFiltered
      .filter((item) => item.currentstatus === "Active")
      .filter((item) => selectedEventCategories.includes(item.category));

    const totalPages = Math.ceil(eventsTableData?.length / entriesPerPage);
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    if (totalPages) {
      showNoDataMessage = false;
    } else {
      showNoDataMessage = true;
    }

    updatePagination();
    if (selectedAlertItem) {
      const match = eventsTableData.find(
        (item) => item.id === selectedAlertItem.id
      );
      selectedAlertItem = match ? { ...match } : null;
    }
  };

  const onGetAlertsOnError = function () {
    isAPIAwaiting = false;
    isAPIReceived = true;
    allEventsList = [];
    eventsTableData = [];
    currentPage = 1;
    updatePagination();
    showNoDataMessage = true;
  };

  const invokeGetAlertsAPI = function (value, isFromWSU) {
    const endpoint = `alert/api/Configuration/${nodeDetails?.id}/events/details?includeAllAlerts=true&includeDisabledAlerts=true&includeChildNodes=${value}`;
    if (!isFromWSU) {
      allEventsList = [];
      eventsTableData = [];
      currentPage = 1;
      updatePagination();
    }
    if (!isFromWSU) isAPIAwaiting = true;
    invokeAPI(
      "GET",
      endpoint,
      onGetAlertsOnSuccess,
      onGetAlertsOnError,
      null,
      `${currentScreen}/R`
    );
  };

  const getWSUForAlerts = function (payload) {
    const endpoint = "realtimedata/api/Parameters/AlertList/live";
    invokeAPI(
      "POST",
      endpoint,
      getWSUForAlertsonSuccess,
      null,
      payload,
      `${currentScreen}/R`
    );
  };

  const getWSUForAlertsonSuccess = function (data) {
    if (alertWS) alertWS.stop();
    customWebSocketConnectionAlerts(data.webSocketMethod, data.webSocketUrl);
  };

  const customWebSocketConnectionAlerts = function (
    webSocketMethod,
    webSocketUrl
  ) {
    const applicationId = sessionStorage.getItem("appId");
    const companyId = localStorage.getItem("companyId");

    if (!webSocketMethod || !webSocketUrl) return false;
    alertWS = new signalR.HubConnectionBuilder()
      .withUrl(webSocketUrl, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    alertWS.on(webSocketMethod, function (_data) {
      let data = JSON.parse(_data);
      updateAlertList(data);
    });

    alertWS
      .start()
      .then(function () {
        alertWS
          .invoke(
            "JoinGroup",
            companyId + "_" + applicationId + "_" + uniqueId + "_Alert"
          )
          .catch(function (err) {
            return console.error({
              signalrerr: err.toString(),
            });
          });
      })
      .catch(function (e) {
        console.error("ws start error", e);
      });

    alertWS.onreconnected(function () {
      alertWS
        .invoke(
          "JoinGroup",
          companyId + "_" + applicationId + "_" + uniqueId + "_Alert"
        )
        .catch(function (err) {
          return console.error({
            signalrerr: err.toString(),
          });
        });
    });
  };

  const getChildNodes = function (hierarchy) {
    hierarchy.forEach((node) => {
      childNodeIds.push(node.id);
      if (node.children.length) {
        getChildNodes(node.children);
      }
    });
  };

  const validateWebSocketData = function (data) {
    if (data.DataSizeExceeded || data.WebSocketSendFailed || data.InvokeAPI) {
      return false;
    } else if (data.InvokeSubscription) {
      return false;
    } else {
      return true;
    }
  };

  const compareAndUpdateEventList = function (oldData, newData) {
    // Update old items
    let updatedData = oldData.map((item) => {
      const match = newData.find((event) => event.id === item.id);
      if (match) {
        return match;
      } else {
        return item;
      }
    });

    // Add new items that were not present in old list
    for (let item of newData) {
      const match = oldData.find((event) => event.id === item.id);
      if (!match) {
        updatedData = updatedData.concat(item);
      }
    }

    return updatedData;
  };

  //Function to update the events list based on websocket response
  const updateAlertList = function (data) {
    console.log("Alert List Web Socket Update in Event Viewer", data);

    if (!validateWebSocketData(data)) {
      console.error(
        "Web Socket Update failed, calling getAlerts API from delEventViewerResponsive component!!!"
      );
      invokeGetAlertsAPI(checkBoxValue, true);
      return;
    }

    data = data.map((item) => {
      let newItem = {
        alertName: item.alertname,
        alertValue: getAlertValueWithUnit(item.alertvalue, item.unit),
        currentStatus: item.currentstatus,
        ...item,
      };
      return newItem;
    });

    //Group alerts on the basis of their application id
    let alertsGroupedByAppId = groupByCategory(data, "triggerApplicationId");

    for (let event of Object.keys(alertsGroupedByAppId)) {
      alertsGroupedByAppId[event] = alertsGroupedByAppId[event];
    }

    const updatedEventList = Object.values(alertsGroupedByAppId).flat();

    // Find all alerts belonging to the selected node id
    let _allEventsList = updatedEventList.filter(
      (item) => item.nodeId === selectedNodeId
    );

    if (checkBoxValue) {
      //Find all child node ids and filter the response to include their events
      childNodeIds = [];
      if (nodeDetails) {
        getChildNodes(nodeDetails.children);
      }
      _allEventsList = _allEventsList.concat(
        updatedEventList.filter((item) => childNodeIds.includes(item.nodeId))
      );
    }
    _allEventsList = _allEventsList.filter((item) => item.createdDate);

    //Compare the new alert list with old one and update it
    const comparedAndUpdatedEventList = compareAndUpdateEventList(
      allEventsList,
      _allEventsList
    );

    comparedAndUpdatedEventList.sort(function (a, b) {
      if (parseInt(b.createdDate) - parseInt(a.createdDate) === 0) {
        if (a.alertname.toLowerCase() < b.alertname.toLowerCase()) return -1;
        if (a.alertname.toLowerCase() > b.alertname.toLowerCase()) return 1;
        return 0;
      } else return parseInt(b.createdDate) - parseInt(a.createdDate);
    });

    console.log(
      "Final updated list of alerts in Event Viewer",
      comparedAndUpdatedEventList
    );

    allEventsList = [...comparedAndUpdatedEventList];

    eventsTableData = comparedAndUpdatedEventList
      .filter((item) => item.currentstatus === "Active")
      .filter((item) => selectedEventCategories.includes(item.category));

    const totalPages = Math.ceil(eventsTableData?.length / entriesPerPage);
    if (currentPage > totalPages) {
      currentPage = totalPages;
    }
    if (totalPages) {
      showNoDataMessage = false;
    } else {
      showNoDataMessage = true;
    }
    updatePagination();
    if (selectedAlertItem) {
      const match = eventsTableData.find(
        (item) => item.id === selectedAlertItem.id
      );
      selectedAlertItem = match ? { ...match } : null;
    }
  };

  const showToastMessage = function (message, color = "green") {
    Toastify({
      text: message,
      backgroundColor: color,
      duration: 5000,
      close: true,
    }).showToast();
  };

  const invokeAcknowlegeAlert = function (alertId, eventId, comment) {
    if (alertId && eventId && comment) {
      const endpoint = `alert/api/v2/Configuration/alerts/${alertId}/events/${eventId}`;
      const payload = {
        Comment: comment,
      };
      invokeAPI(
        "PATCH",
        endpoint,
        acknowledgeAlertonSuccess,
        acknowledgeAlertonError,
        payload,
        `${currentScreen}/W`
      );
    }
  };
  const acknowledgeAlertonSuccess = function () {
    showToastMessage("Event Acknowledged Successfully");
  };

  const acknowledgeAlertonError = function () {
    showToastMessage("Unable to acknowledge the event", "red");
  };

  const disableAlertonSuccess = function () {
    showToastMessage("Alert successfully disabled");
  };

  const disableAlertonError = function () {
    showToastMessage("Unable to disable the alert", "red");
  };

  const invokeDisableAlert = function (alertId, eventId) {
    const endpoint = "alert/api/Configuration/disablealert";
    const payload = {
      AlertId: alertId,
      EventId: eventId,
      IgnoredBy: localStorage.getItem("uname")
        ? localStorage.getItem("uname")
        : "System",
    };
    invokeAPI(
      "POST",
      endpoint,
      disableAlertonSuccess,
      disableAlertonError,
      payload,
      `${currentScreen}/W`
    );
  };

  const onModalSubmitClicked = function (type) {
    switch (type) {
      case "acknowledge":
        invokeAcknowlegeAlert(
          selectedAlertItem?.id,
          selectedAlertItem?.eventid,
          commentTextArea?.value
        );
        break;
      case "disable":
        invokeDisableAlert(selectedAlertItem?.id, selectedAlertItem?.eventid);
        break;
      default:
        break;
    }
    onModalClosed();
  };
</script>

<div class="del-container del-event-viewer-main-container">
  {#if isAPIAwaiting}
    <Loader></Loader>
  {/if}
  <div class="del-event-viewer-header-container">
    <div class="del-event-viewer-checkbox-container">
      <input
        type="checkbox"
        id="include-child-node-checkbox"
        name="include-child-node-checkbox"
        class="del-event-viewer-checkbox"
        value="true"
        bind:checked={checkBoxValue}
        on:change={() => {
          sessionStorage.setItem(
            "del-event-viewer-responsive-checkbox-value",
            JSON.stringify(checkBoxValue)
          );
        }}
      />
      <label
        for="include-child-node-checkbox"
        class="del-event-viewer-checkbox-label">Include Child Node Alerts</label
      >
    </div>
    <div class="del-event-viewer-category-selector-container">
      <DelSelect
        options={categoriesList}
        defaultSelection={defaultSelectedCategories}
        isMultipleSelect={true}
        maxValuesShown={3}
        onSelectionChange={(selected) => {
          selectedEventCategories = selected.map((item) => item.value);
          sessionStorage.setItem(
            "del-event-viewer-responsive-saved-categories",
            JSON.stringify(selectedEventCategories)
          );
          filterEventList();
        }}
        extraClassesForSelected={"del-select-event-viewer-selected-option"}
      ></DelSelect>
    </div>
  </div>
  <div class="del-events-viewer-table-container">
    <div class="del-events-viewer-table-inner-container">
      {#if eventsTableData?.length}
        <table class="del-events-viewer-table">
          <thead>
            <tr class="del-events-viewer-table-header-row">
              {#each columnsToShow as column}
                {#if column !== "disable" || isWritePermission}
                  <th
                    class="del-events-viewer-table-cell del-events-viewer-table-header-cell"
                    >{columnDetails[column] ?? ""}</th
                  >
                {/if}
              {/each}
            </tr>
          </thead>
          <tbody class="table-body">
            {#each paginatedData as row (row.id)}
              <tr class="del-events-viewer-table-row">
                {#each columnsToShow as column, index}
                  {#if column === "disable"}
                    {#if isWritePermission && row.currentStatus === "Active"}
                      <td
                        class={`del-events-viewer-table-cell del-events-viewer-hidden-col-small-screens`}
                        ><button
                          name="disable-button"
                          class="del-events-viewer-table-disable-btn"
                          on:click={() => {
                            modalOpened = "disable";
                            showOverLay = true;
                            selectedAlertItem = { ...row };
                          }}>{disableButtonLabel ?? "Disable"}</button
                        ></td
                      >
                    {/if}
                  {:else}
                    <td
                      class={`del-events-viewer-table-cell ${
                        index !== 0
                          ? "del-events-viewer-hidden-col-small-screens"
                          : ""
                      } ${
                        column === "category"
                          ? row[column] === "Critical"
                            ? "del-events-viewer-table-event-critical"
                            : row[column] === "Warning"
                              ? "del-events-viewer-table-event-warning"
                              : row[column] === "Informational"
                                ? "del-events-viewer-table-event-informational"
                                : ""
                          : ""
                      } ${
                        column === "currentStatus"
                          ? row[column] === "Active"
                            ? "del-events-viewer-table-event-active"
                            : "del-events-viewer-table-event-inactive"
                          : ""
                      }`}
                    >
                      {#if index === 0}
                        <div class="del-events-viewer-table-first-column">
                          <div class="del-events-viewer-event-name-label">
                            {row[column] || ""}
                          </div>
                          <div
                            class="del-events-viewer-table-eye-icon"
                            on:keypress={() => {
                              onEyeIconClicked(row);
                            }}
                            on:click={() => {
                              onEyeIconClicked(row);
                            }}
                          >
                            <img
                              src={`${deployedURL}/images/eye-icon-grey.svg`}
                              alt="eye-icon"
                            />
                          </div>
                        </div>
                      {:else if column === "createdDate"}
                        {@html formatTimeStamp(row[column]) ?? ""}
                      {:else if column === "acknowledgedDate"}
                        {@html formatTimeStamp(row[column]) ?? ""}
                      {:else if column === "isAcknowledged"}
                        {row[column] ? "Yes" : "No"}
                      {:else if column === "action"}
                        {#if !row.isAcknowledged}
                          <button
                            name="acknowledge-button"
                            class="del-events-viewer-table-ack-btn"
                            disabled={!isWritePermission}
                            on:click={() => {
                              modalOpened = "acknowledge";
                              showOverLay = true;
                              selectedAlertItem = { ...row };
                            }}>Acknowledge</button
                          >
                        {:else}
                          <button
                            name="view-action-button"
                            class="del-events-viewer-table-action-btn"
                            on:click={() => {
                              modalOpened = "view-action";
                              showOverLay = true;
                              selectedAlertItem = { ...row };
                            }}>View Comment</button
                          >{/if}
                      {:else}
                        {row[column] ?? ""}
                      {/if}
                    </td>
                  {/if}
                {/each}
              </tr>
            {/each}
          </tbody>
        </table>
      {:else if !isAPIAwaiting && showNoDataMessage}
        <div class="del-no-data-message">
          {noDataMessage || "No Data Available"}
        </div>
      {/if}
    </div>
    {#if eventsTableData.length > entriesPerPage}
      <div class="pagination-container">
        <div class="entries-display">
          {`Showing ${(currentPage - 1) * entriesPerPage + 1} to ${Math.min(
            currentPage * entriesPerPage,
            eventsTableData.length
          )} of ${eventsTableData.length} entries`}
        </div>
        <div class="pagination-buttons">
          <button
            class="first-page"
            disabled={currentPage === 1}
            on:click={handleFirstPageClick}>First</button
          >
          <button
            class="previous-page"
            disabled={currentPage === 1}
            on:click={handlePreviousPageClick}>Previous</button
          >
          <span class="current-page"
            >{`Page ${currentPage} of ${totalPages}`}</span
          >
          <button
            class="next-page"
            disabled={currentPage === totalPages}
            on:click={handleNextPageClick}>Next</button
          >
          <button
            class="last-page"
            disabled={currentPage === totalPages}
            on:click={handleLastPageClick}>Last</button
          >
        </div>
      </div>
    {/if}
  </div>
  {#if showOverLay}
    <div
      class="del-event-viewer-overlay-container"
      on:click={onOverLayClicked}
      on:keypress={onOverLayClicked}
    ></div>
  {/if}
  {#if modalOpened}
    <div class="del-event-viewer-modal" id="del-event-viewer-modal">
      <div class="del-event-viewer-modal-header">
        <div class="del-event-viewer-modal-title">
          {modalOpened === "acknowledge"
            ? "Add Comment"
            : modalOpened === "view-action"
              ? "Comment"
              : "Disable Event"}
        </div>
        {#if modalOpened !== "disable" && selectedAlertItem}
          <div
            class="del-event-viewer-modal-alert-name"
            title="${selectedAlertItem.category} - ${selectedAlertItem.alertname}"
          >
            {selectedAlertItem.category + " - " + selectedAlertItem.alertname}
          </div>
        {/if}
      </div>
      {#if selectedAlertItem}
        {#if modalOpened === "acknowledge"}
          {#if selectedAlertItem.isAcknowledged}
            <div class="del-event-viewer-modal-del-no-data-message">
              This event has been acknowledged
            </div>
          {:else}
            <div class="del-event-viewer-modal-action-container">
              <textarea
                id="del-event-viewer-modal-textarea"
                name="del-event-viewer-modal-action"
                class="del-event-viewer-modal-action"
                bind:this={commentTextArea}
                maxlength="100"
                rows="6"
                cols="300"
                on:input={updateCharCount}
              ></textarea>
            </div>
            <div
              class="del-event-viewer-modal-action-count"
              id="del-event-viewer-modal-action-count"
            >
              {`${100 - actionLength} characters left out of 100`}
            </div>
          {/if}
        {:else if modalOpened === "view-action"}
          <div class="del-event-viewer-modal-view-action-container">
            <div class="del-event-viewer-modal-view-top-action-container">
              <div>{selectedAlertItem.acknowledgedBy ?? ""}</div>
              <div>
                {@html formatTimeStamp(selectedAlertItem.acknowledgedDate)}
              </div>
            </div>
            <div class="del-event-viewer-modal-view-bottom-action-container">
              {selectedAlertItem.comment ?? ""}
            </div>
          </div>
        {:else}
          <div class="del-event-viewer-modal-disable-container">
            Are you sure you want to disable this event?
          </div>
        {/if}
      {:else}
        <div class="del-event-viewer-modal-del-no-data-message">
          This event is no longer active
        </div>
      {/if}
      <div class="del-event-viewer-modal-buttons-container">
        <button
          class="del-event-viewer-modal-cancel-button"
          id="del-event-viewer-modal-cancel-button"
          on:click={onModalClosed}>Cancel</button
        >
        {#if modalOpened === "acknowledge" || modalOpened === "disable"}
          <button
            class="del-event-viewer-modal-submit-button"
            id="del-event-viewer-modal-submit-button"
            on:click={() => {
              onModalSubmitClicked(modalOpened);
            }}
            disabled={(modalOpened === "acknowledge" && !actionLength) ||
              !selectedAlertItem}>Submit</button
          >
        {/if}
      </div>
    </div>
  {/if}
  <div
    id="del-events-viewer-slide-in-drawer"
    class:del-events-viewer-show={drawerOpened}
  >
    {#if selectedAlertItem}
      <div class="del-events-viewer-drawer-content">
        {#each columnsToShow as column}
          <div class="del-events-viewer-drawer-item">
            {#if !(column === "action" || column === "disable")}
              <div class="del-events-viewer-drawer-label">
                {columnDetails[column] ?? ""}
              </div>
            {/if}
            {#if column === "category"}
              <div
                class="del-events-viewer-drawer-value {selectedAlertItem[
                  column
                ] === 'Critical'
                  ? 'del-events-viewer-table-event-critical'
                  : selectedAlertItem[column] === 'Warning'
                    ? 'del-events-viewer-table-event-warning'
                    : selectedAlertItem[column] === 'Informational'
                      ? 'del-events-viewer-table-event-informational'
                      : ''}"
              >
                {selectedAlertItem[column] || "No Data"}
              </div>
            {:else if column === "createdDate"}
              <div class="del-events-viewer-drawer-value">
                {@html formatTimeStamp(selectedAlertItem[column]) || "No Data"}
              </div>
            {:else if column === "acknowledgedDate"}
              <div class="del-events-viewer-drawer-value">
                {@html formatTimeStamp(selectedAlertItem[column]) || "No Data"}
              </div>
            {:else if column === "isAcknowledged"}
              <div class="del-events-viewer-drawer-value">
                {selectedAlertItem[column] ? "Yes" : "No"}
              </div>
            {:else if !(column === "action" || column === "disable")}
              <div class="del-events-viewer-drawer-value">
                {selectedAlertItem[column] || "No Data"}
              </div>
            {/if}
          </div>
        {/each}
        {#if columnsToShow.includes("action") || (columnsToShow.includes("disable") && isWritePermission && selectedAlertItem?.currentStatus === "Active")}
          <div
            class="del-events-viewer-drawer-item del-events-viewer-drawer-buttons-container"
          >
            {#if columnsToShow.includes("action")}
              <div class="del-events-viewer-drawer-value">
                {#if selectedAlertItem.isAcknowledged}
                  <button
                    name="drawer-view-action-button"
                    class="del-events-viewer-table-action-btn"
                    on:click={() => {
                      modalOpened = "view-action";
                      showOverLay = true;
                    }}>View Comment</button
                  >
                {:else}
                  <button
                    name="drawer-acknowledge-button"
                    class={`del-events-viewer-table-ack-btn`}
                    disabled={!isWritePermission}
                    on:click={() => {
                      modalOpened = "acknowledge";
                      showOverLay = true;
                    }}>Acknowledge</button
                  >
                {/if}
              </div>
            {/if}
            {#if columnsToShow.includes("disable") && isWritePermission && selectedAlertItem?.currentStatus === "Active"}
              <div class="del-events-viewer-drawer-value">
                <button
                  name="drawer-disable-button"
                  class="del-events-viewer-table-disable-btn"
                  on:click={() => {
                    modalOpened = "disable";
                    showOverLay = true;
                  }}>{disableButtonLabel ?? "Disable"}</button
                >
              </div>
            {/if}
          </div>
        {/if}
      </div>
    {:else}
      <div
        class="del-events-viewer-drawer-content del-events-viewer-drawer-del-no-data-message"
      >
        This event is no longer active.
      </div>
    {/if}
  </div>
</div>

<style>
  .del-event-viewer-overlay-container {
    position: fixed;
    z-index: 108;
    left: 0;
    top: 0;
    background: #00000066;
    width: 100vw;
    height: 100vh;
  }

  .del-event-viewer-main-container {
    padding: 5px 10px 20px;
    position: relative;
  }

  .del-no-data-message {
    width: 100%;
    text-align: center;
    padding: 8% 0;
    font: italic normal normal 16px/21px Roboto;
  }

  .del-event-viewer-header-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 18px 0;
  }

  .del-event-viewer-category-selector-container,
  .del-event-viewer-checkbox-container {
    padding: 5px 0;
  }
  .del-event-viewer-category-selector-container {
    min-width: 280px;
  }

  .del-event-viewer-checkbox {
    width: 16px;
    height: 16px;
    margin: 0;
    vertical-align: middle;
    cursor: pointer;
  }

  .del-event-viewer-checkbox-label {
    margin: 0;
    vertical-align: middle;
    font: normal normal normal 14px Roboto;
    color: #61656c;
  }

  .del-event-viewer-category-selector {
    min-width: 250px;
  }

  .del-events-viewer-table-inner-container {
    overflow-x: auto;
    border: 1px solid #d3d3d3;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    min-height: 200px;
  }

  .del-events-viewer-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    position: relative;
  }

  th.del-events-viewer-table-header-cell:first-child,
  .del-events-viewer-table-first-column {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .del-events-viewer-event-name-label {
    flex-grow: 1;
  }

  .del-events-viewer-table-eye-icon {
    display: none;
    cursor: pointer;
    color: #798b91;
  }

  .del-events-viewer-table-eye-icon img {
    width: 24px;
    object-fit: contain;
  }

  .del-events-viewer-table td:first-child,
  .del-events-viewer-table th:first-child {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    opacity: 1;
    min-width: 150px;
  }

  .del-events-viewer-table-cell {
    padding: 10px;
    text-align: center;
    font: normal normal normal 14px Roboto;
    color: #000000;
    opacity: 0.77;
    min-width: 180px;
    border-bottom: 1px solid #d3d3d3;
    background-color: #ffffff;
    opacity: 1;
  }

  .del-events-viewer-table-no-data-cell {
    text-align: center;
  }

  .del-events-viewer-table-header-row {
    text-align: center;
    font: normal normal normal 16px Roboto;
    color: #ffffff;
    background-color: #79919a;
  }

  .del-events-viewer-table-filter-row {
    text-align: center;
    font: normal normal normal 14px Roboto;
    color: #ffffff;
    background-color: #beccd1;
  }

  .del-events-viewer-table-header-cell {
    text-align: center;
    font: normal normal normal 16px Roboto;
    color: #ffffff;
    background-color: #79919a;
    padding: 12px 10px;
  }

  /* For slim select */

  :global(
      .ss-option.ss-selected.del-select-option-selected.del-select-event-viewer-selected-option
    ) {
    background-color: #2c3049 !important;
  }

  :global(
      .del-event-viewer-category-selector-container
        .ss-main
        .ss-values
        .ss-value
    ) {
    background-color: #2c3049 !important;
  }

  /* End of slim select styles */

  /* For Pagination */
  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
  }

  .pagination-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
  }

  .pagination-buttons button {
    margin: 0;
    color: #fff;
    background-color: #2c3049;
    border: none;
    opacity: 1;
    padding: 2px 5px;
    border-radius: 2px;
    cursor: pointer;
  }

  .pagination-buttons button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .pagination-buttons button:enabled:hover {
    background: #222639;
  }

  .current-page {
    font-weight: bold;
    margin: 0 10px;
    font: normal normal normal 14px Roboto;
  }

  .entries-display {
    margin-right: 10px;
    margin-bottom: 10px;
    font: italic normal normal 14px Roboto;
  }

  /* End of Pagination styles */

  /* Table content styles */

  .del-events-viewer-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .del-events-viewer-table-event-critical {
    color: #e1464a;
    font-weight: bold;
  }

  .del-events-viewer-table-event-warning {
    color: #fcb900;
    font-weight: bold;
  }

  .del-events-viewer-table-event-informational {
    color: #4caf50;
    font-weight: bold;
  }

  .del-events-viewer-table-event-active {
    color: #ff5555;
    font-weight: bold;
  }

  .del-events-viewer-table-event-inactive {
    color: #4b72b9;
    font-weight: bold;
  }

  .del-events-viewer-table-action-btn,
  .del-events-viewer-table-ack-btn,
  .del-events-viewer-table-disable-btn {
    color: #fff;
    background-color: #2c3049;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
  }

  .del-events-viewer-table-action-btn:disabled,
  .del-events-viewer-table-ack-btn:disabled,
  .del-events-viewer-table-disable-btn:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .del-events-viewer-table-action-btn:enabled:hover,
  .del-events-viewer-table-ack-btn:enabled:hover,
  .del-events-viewer-table-disable-btn:enabled:hover {
    background: #222639;
  }

  /* For responsiveness */

  @media screen and (max-width: 600px) {
    .del-events-viewer-table-eye-icon {
      display: flex;
    }

    td.del-events-viewer-table-first-column {
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 500px) {
    th.del-events-viewer-table-header-cell:not(:first-child),
    td.del-events-viewer-hidden-col-small-screens {
      display: none;
    }

    .del-events-viewer-event-name-label {
      text-align: start;
    }
  }

  @media screen and (max-width: 400px) {
    .del-event-viewer-category-selector-container {
      min-width: 0;
    }
  }
  /* For Drawer */

  #del-events-viewer-slide-in-drawer {
    position: fixed;
    bottom: -300px;
    left: 0;
    width: 100%;
    min-height: 200px;
    background-color: #fff;
    z-index: 500;
    transition: bottom 0.3s ease-in-out;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  #del-events-viewer-slide-in-drawer.del-events-viewer-show {
    bottom: 0;
  }

  .del-events-viewer-drawer-content {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    gap: 20px;
    font: normal normal normal 14px Roboto;
    padding: 20px 20px 15px;
  }

  .del-events-viewer-drawer-item {
    flex-grow: 1;
  }

  .del-events-viewer-drawer-buttons-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .del-events-viewer-drawer-label {
    color: #000000;
    font-weight: bold;
  }

  .del-events-viewer-table-date {
    margin-left: 1ch;
    color: #798b91;
  }

  .del-events-viewer-table-event-critical {
    color: #e1464a;
    font-weight: bold;
  }

  .del-events-viewer-table-event-warning {
    color: #fcb900;
    font-weight: bold;
  }

  .del-events-viewer-table-event-informational {
    color: #4caf50;
    font-weight: bold;
  }

  .del-events-viewer-table-event-active {
    color: #ff5555;
    font-weight: bold;
  }

  .del-events-viewer-table-event-inactive {
    color: #4b72b9;
    font-weight: bold;
  }

  .del-events-viewer-table-action-btn,
  .del-events-viewer-table-ack-btn,
  .del-events-viewer-table-disable-btn {
    color: #fff !important;
    background-color: #2c3049;
    border: none;
    border-radius: 5px;
    padding: 5px 8px;
  }

  .del-events-viewer-drawer-buttons-container
    .del-events-viewer-table-action-btn,
  .del-events-viewer-drawer-buttons-container .del-events-viewer-table-ack-btn,
  .del-events-viewer-drawer-buttons-container
    .del-events-viewer-table-disable-btn {
    margin-bottom: 5px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  .del-events-viewer-table-action-btn:disabled,
  .del-events-viewer-table-ack-btn:disabled,
  .del-events-viewer-table-disable-btn:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  .del-events-viewer-table-action-btn:enabled:hover,
  .del-events-viewer-table-ack-btn:enabled:hover,
  .del-events-viewer-table-disable-btn:enabled:hover {
    background: #222639;
  }

  /* For Modal */

  .del-event-viewer-modal {
    position: fixed;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 600;
    max-width: calc(100% - 40px);
    max-height: calc(100% - 20px);
    resize: both;
    box-sizing: border-box;
    font-family: Roboto;
    min-height: 160px;
    top: 25%;
    border-radius: 11px;
    opacity: 1;
    padding: 10px 20px;
  }

  .del-event-viewer-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    gap: 10px;
    font: normal normal bold 16px/21px Roboto;
    color: #000000;
  }

  .del-event-viewer-modal-alert-name {
    font-size: 15px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .del-event-viewer-modal-del-no-data-message {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font: normal normal normal 15px Roboto;
    color: grey;
    padding: 30px 0;
  }

  .del-event-viewer-modal-action-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px 0;
  }

  .del-event-viewer-modal-action {
    background: #ffffff 0 0 no-repeat padding-box;
    border: 1px solid #bababa;
    border-radius: 7px;
    font-size: 14px !important;
  }

  .del-event-viewer-modal-action-count {
    font: normal normal normal 14px Roboto;
    color: #798b91;
    text-align: right;
  }

  .del-event-viewer-modal-buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;
    font: normal normal normal 14px Roboto;
    margin-top: 10px;
  }

  .del-events-viewer-table-date {
    margin-left: 1ch;
  }

  .del-event-viewer-modal-view-action-container {
    padding: 10px 0;
    margin: 10px 0;
    border-top: 1px solid #636c7b;
    border-bottom: 1px solid #636c7b;
  }

  .del-event-viewer-modal-view-top-action-container {
    font: normal normal bold 14px/19px Roboto;
    color: #636c7b;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .del-event-viewer-modal-view-bottom-action-container {
    font: normal normal normal 16px/24px Roboto;
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .del-event-viewer-modal-disable-container {
    font: normal normal bold 15px Roboto;
    color: #000000;
    padding: 30px 0;
  }

  button.del-event-viewer-modal-submit-button {
    color: #fff;
    background-color: #2c3049;
    border-color: #222639;
    border-radius: 5px;
    padding: 5px;
    border: none;
    padding: 5px 8px;
    font: normal normal normal 14px Roboto;
    cursor: pointer;
  }

  button.del-event-viewer-modal-cancel-button {
    color: #e51f1f;
    background: transparent;
    border: none;
    font: normal normal bold 14px Roboto;
    cursor: pointer;
  }

  button.del-event-viewer-modal-submit-button:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
  }

  button.del-event-viewer-modal-submit-button:enabled:hover {
    background: #222639;
  }

  @media screen and (min-width: 700px) {
    .del-event-viewer-modal {
      width: 450px;
      left: calc(50% - 225px);
      top: 25%;
    }
  }

  @media screen and (max-width: 699px) {
    .del-event-viewer-modal {
      width: 68%;
      left: calc(50% - 34%);
      top: 25%;
    }
  }

  @media screen and (min-width: 600px) {
    .del-event-viewer-modal {
      top: 5vh;
    }
  }

  @media screen and (max-width: 300px) {
    .del-event-viewer-modal {
      width: 80%;
      left: calc(50% - 40%);
    }
  }

  @media screen and (min-width: 900px) {
    .del-event-viewer-modal {
      top: 25%;
    }
  }
</style>
